var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var dX,$ka,fX,hX,iX,ala,kX,lX,cX,mX,bla,cla,dla,ela,pX,qX,sX,tX,uX,vX,wX,xX,eX,gla,hla,AX,ila,BX,jla,DX,kla,lla,mla,nla,ola,$W;$CLJS.aX=function(a,b){if("string"===typeof b)return $W(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.bX=function(a,b,c){var d=$CLJS.em(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
dX=function(a){var b=cX;return $CLJS.PA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};$ka=function(a){var b=eX;return $CLJS.PA(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};fX=function(a){if($CLJS.md(a))return a;throw $CLJS.ii("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Hi,a],null));};
$CLJS.gX=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Qk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};hX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
iX=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
ala=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Vk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.Vk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
kX=function(a,b){for(;;)switch(b=$CLJS.hl.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),jX.h?jX.h(a):jX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(iX,a),b))){var c=a;b=ala(a,b);a=c}else{c=$CLJS.hf.g(jX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};lX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.JA($CLJS.ge,$CLJS.Za)($CLJS.A(a)):b:b};
cX=function(a){return $CLJS.n($CLJS.JA($CLJS.ge,$CLJS.Za)(a))?$CLJS.zh.h($CLJS.NA(hX(a).toLowerCase(),/_/,"-")):a};mX=function(a,b){var c=lX(b);return $CLJS.n(c)?(b=cX($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};bla=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.di,$CLJS.Td,$CLJS.mj,cX,$CLJS.YN,cX],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=nX.g?nX.g(b,oX):nX.call(null,b,oX);return d.call(c,b,a)})};
cla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=cX(c);var d=bla(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.mj.h(a),$CLJS.PF)&&$CLJS.Va($CLJS.YN.h(a))?$CLJS.R.j(a,$CLJS.YN,$CLJS.PG):a};dla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=hX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(cla(b),$CLJS.T,c)],null)}),a)};
ela=function(a){a=nX.h?nX.h(a):nX.call(null,a);return eX.h?eX.h(a):eX.call(null,a)};pX=function(a){return $CLJS.bX($CLJS.bX($CLJS.fb(function(b,c){return $CLJS.bX(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.oD,$CLJS.Bea,$CLJS.LO,fla,$CLJS.jA,$CLJS.si],null)),$CLJS.oC,ela),$CLJS.$N,$CLJS.DV)};qX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,a,null],null):a};sX=function(a){return rX.h(qX(a))};
tX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return jX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(rX,b)))};uX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,sX(b)],null),$CLJS.hf.g(rX,c))};vX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,sX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
wX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,sX(a)],null)};xX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,rX.h(a)],null)};
eX=function(a){return yX(function(b){if($CLJS.xd(b))return $ka(b);if($CLJS.Va(lX(b)))return b;try{return rX.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.TA($CLJS.My);if($CLJS.n($CLJS.SA("metabase.mbql.normalize",d))){var e=$CLJS.XE("Invalid clause:");e instanceof Error?$CLJS.RA("metabase.mbql.normalize",d,$CLJS.Kw.l($CLJS.H([b])),e):$CLJS.RA("metabase.mbql.normalize",d,$CLJS.Kw.l($CLJS.H([e,b])),null)}throw $CLJS.hi($CLJS.uE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.AV(c)])),new $CLJS.h(null,
1,[$CLJS.hS,b],null),c);}throw f;}},a)};gla=function(a){return $CLJS.Df($CLJS.bm(eX,zX(a)))};hla=function(a){for(;;)if($CLJS.n(lX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Qk.g(qX,a))};AX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.PF);return $CLJS.n(b)?$CLJS.Rk.j(a,$CLJS.PF,rX):a};
ila=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,AX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,AX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};BX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.OQ);return $CLJS.n(b)?$CLJS.Rk.j(a,$CLJS.OQ,ila):a};jla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.rQ);b=$CLJS.Va(a)?CX.h?CX.h(b):CX.call(null,b):b;return $CLJS.n(a)?BX(b):b};DX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
kla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.SF);var c=$CLJS.J.g(b,$CLJS.CN),d=$CLJS.J.g(b,$CLJS.ZO),e=$CLJS.J.g(b,$CLJS.rQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.ZO);var l=$CLJS.dM($CLJS.Gk.g(f,$CLJS.ZO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SF,$CLJS.ZO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Rk.j(l,$CLJS.SF,CX):l,t=$CLJS.n(c)?$CLJS.Rk.j(m,$CLJS.CN,$CLJS.Ve($CLJS.Qk,eX)):m,u=$CLJS.n(e)?$CLJS.Rk.j(t,$CLJS.rQ,BX):t;return eX(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.hi($CLJS.uE("Error canonicalizing query: {0}",
$CLJS.H([$CLJS.AV(m)])),new $CLJS.h(null,1,[$CLJS.SF,a],null),m);throw v;}};
lla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.SF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.jG);c=$CLJS.J.g(c,$CLJS.eF);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.AC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.vG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,m,$CLJS.Gk.g(t,$CLJS.BG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.IV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.EX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SF,$CLJS.eF],null),$CLJS.Ok.g($CLJS.Df,$CLJS.Ve($CLJS.Pk,b)))):a};
mla=function(a){try{return lla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.hi($CLJS.XE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.SF,a],null),b);}throw c;}};
nla=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=FX.g?FX.g(C,K):FX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return FX.g?FX.g(z,C):FX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};ola=function(a,b){a=$CLJS.Qk.g(function(c){var d=$CLJS.be.g(b,GX);return FX.g?FX.g(c,d):FX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
$W=function $W(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),$W.g?$W.g(a,k):$W.call(null,a,k)):null},null,null))};
$CLJS.EX=function EX(a){switch(arguments.length){case 3:return EX.j(arguments[0],arguments[1],arguments[2]);case 4:return EX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return EX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return EX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return EX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.EX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.EX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.EX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.EX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.EX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.EX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.EX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.EX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.EX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.EX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.EX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.EX.A=6;
var yX=function yX(a,b){return $CLJS.BV($CLJS.Ve(yX,a),$CLJS.Td,a.h?a.h(b):a.call(null,b))},jX=function jX(a){for(;;){if($CLJS.xd(a))return $CLJS.Kt(a,jX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Ll(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return kX($CLJS.As,
$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return kX($CLJS.Hs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return kX($CLJS.Hs,t);case "or":return kX($CLJS.As,t);default:return $CLJS.Qk.g(jX,a)}}else return a}},pla=new $CLJS.M(null,"value_field","value_field",
-980977878),HX=new $CLJS.M(null,"ascending","ascending",-988350486),IX=new $CLJS.M(null,"named","named",-422393479),JX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),KX=new $CLJS.M(null,"descending","descending",-24766135),LX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),qla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),MX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),NX=new $CLJS.M(null,"viz-settings","viz-settings",
256055379),rla=new $CLJS.M(null,"rows","rows",850049680),sla=new $CLJS.M(null,"special-fn","special-fn",1290649344),GX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),oX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),tla=new $CLJS.M(null,"label_field","label_field",-1573182765),ula=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),vla=new $CLJS.M(null,"joined-field","joined-field",-2048778268),fla=new $CLJS.M(null,"visibility_type","visibility_type",
-508434247);var OX,wla=$CLJS.Xe($CLJS.N),xla=$CLJS.Xe($CLJS.N),yla=$CLJS.Xe($CLJS.N),zla=$CLJS.Xe($CLJS.N),Ala=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));OX=new $CLJS.ei($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Ok.g(cX,$CLJS.A),Ala,wla,xla,yla,zla);OX.m(null,$CLJS.kG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,b],null);return null!=a?$CLJS.be.g(b,a):b});
OX.m(null,$CLJS.yB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,a instanceof $CLJS.M?hX(a):a],null)});OX.m(null,$CLJS.KV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(OX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KV,nX.g?nX.g(b,oX):nX.call(null,b,oX),cX(c)],null)});
OX.m(null,$CLJS.vG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=nX.g?nX.g(a,oX):nX.call(null,a,oX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,b,function(){var d=$CLJS.n($CLJS.BB.h(c))?$CLJS.Rk.j(c,$CLJS.BB,$CLJS.zh):c;d=$CLJS.n($CLJS.BG.h(c))?$CLJS.Rk.j(d,$CLJS.BG,$CLJS.zh):d;return $CLJS.n($CLJS.CR.h(c))?$CLJS.Rk.j(d,$CLJS.CR,function(e){return $CLJS.n($CLJS.QE.h(e))?$CLJS.Rk.j(e,$CLJS.QE,$CLJS.zh):e}):d}()],null)});
OX.m(null,MX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[MX,b instanceof $CLJS.M?hX(b):b,$CLJS.zh.h(a)],null)});OX.m(null,LX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[LX,nX.g?nX.g(b,oX):nX.call(null,b,oX),$CLJS.rt,cX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[LX,nX.g?nX.g(b,oX):nX.call(null,b,oX),cX(c)],null)});
OX.m(null,$CLJS.HG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(OX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.HG,b,c,d],null)),nX.g?nX.g(a,oX):nX.call(null,a,oX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.HG,nX.g?nX.g(b,oX):nX.call(null,b,oX),$CLJS.Fd(c)?c:cX(c),cX(d)],null)});
OX.m(null,$CLJS.kJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kJ,b,cX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kJ,$CLJS.cw],null)});OX.m(null,$CLJS.iJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iJ,b,cX(a)],null)});
OX.m(null,$CLJS.sJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),c,cX(a)],null)});OX.m(null,$CLJS.yJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.yJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),c,cX(a)],null)});
OX.m(null,$CLJS.qJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),cX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qJ,nX.g?nX.g(b,oX):nX.call(null,b,oX)],null)});
OX.m(null,$CLJS.wJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),cX(c),cX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),cX(c)],null)});
OX.m(null,$CLJS.hJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hJ,nX.g?nX.g(b,oX):nX.call(null,b,oX),nX.g?nX.g(c,oX):nX.call(null,c,oX),cX(a)],null)});OX.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,b,a],null)});
OX.m(null,$CLJS.di,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[cX(a)],null),$CLJS.hf.h(function(c){return nX.g?nX.g(c,oX):nX.call(null,c,oX)}),b)});
var PX=function PX(a){if($CLJS.n($CLJS.JA($CLJS.ge,$CLJS.Za)(a))){var c=cX(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.Yn,null,$CLJS.fs,null,$CLJS.LG,null,$CLJS.EG,null,$CLJS.tt,null,$CLJS.wG,null,$CLJS.QG,null,$CLJS.RG,null,$CLJS.aG,null,$CLJS.gG,null,$CLJS.es,null,$CLJS.bG,null,$CLJS.JG,null,$CLJS.CG,null,$CLJS.ik,null,$CLJS.ay,null,$CLJS.pG,null,$CLJS.IG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(lX(a))?(a=$CLJS.A(a),PX.h?PX.h(a):PX.call(null,
a)):null},Bla=new $CLJS.h(null,8,[$CLJS.mj,cX,$CLJS.rQ,function(a){a=dX(a);return $CLJS.y($CLJS.OQ.h(a))?$CLJS.Rk.j(a,$CLJS.OQ,dla):a},$CLJS.SF,new $CLJS.h(null,6,[$CLJS.kG,function QX(a){if($CLJS.n($CLJS.JA($CLJS.ge,$CLJS.Za)(a)))return cX(a);if($CLJS.n(mX(IX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[IX,QX.h?QX.h(a):QX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(lX(a))?PX($CLJS.hd(a)):null)?$CLJS.Qk.g(QX,a):nX.g?nX.g(a,
oX):nX.call(null,a,oX)},$CLJS.gQ,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[hX(u),nX.g?nX.g(t,oX):nX.call(null,t,oX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[hX(l),nX.g?nX.g(f,oX):nX.call(null,f,oX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.WR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(lX(t))?OX.h(t):$CLJS.ce(OX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(lX(l))?OX.h(l):$CLJS.ce(OX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.QQ,function(a){a=dX(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.rQ);if($CLJS.n(b))return a=$CLJS.HV(a,new $CLJS.h(null,1,[$CLJS.rQ,$CLJS.SF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rQ],null),a=nX.g?nX.g(a,b):nX.call(null,a,b),$CLJS.HV(a,new $CLJS.h(null,1,[$CLJS.SF,$CLJS.rQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.SF],null);return nX.g?nX.g(a,b):nX.call(null,a,b)},$CLJS.ZO,new $CLJS.h(null,1,[GX,pX],null),$CLJS.LN,new $CLJS.h(null,1,[GX,function(a){a=nX.g?nX.g(a,$CLJS.SF):nX.call(null,a,$CLJS.SF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.QE),d=$CLJS.J.g(b,$CLJS.eF);a=$CLJS.J.g(b,$CLJS.iF);b=$CLJS.n(c)?$CLJS.Rk.j(b,$CLJS.QE,cX):b;d=$CLJS.n($CLJS.JA($CLJS.ge,$CLJS.Za)(d))?$CLJS.Rk.j(b,$CLJS.eF,cX):b;return $CLJS.n(a)?$CLJS.Rk.j(d,$CLJS.iF,hX):d}],null)],null),$CLJS.Qy,
new $CLJS.h(null,1,[$CLJS.wO,$CLJS.Td],null),$CLJS.CN,new $CLJS.h(null,1,[GX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.mj),d=$CLJS.J.g(b,$CLJS.DJ),e=$CLJS.J.g(b,$CLJS.Zi);a=$CLJS.J.g(b,JX);b=$CLJS.n(e)?$CLJS.Rk.j(b,$CLJS.Zi,hX):b;c=$CLJS.n(c)?$CLJS.Rk.j(b,$CLJS.mj,cX):b;d=$CLJS.n(d)?$CLJS.Rk.j(c,$CLJS.DJ,function(f){return nX.g?nX.g(f,oX):nX.call(null,f,oX)}):c;d=$CLJS.n(a)?$CLJS.EX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[JX,tla],null),function(f){return nX.g?nX.g(f,oX):nX.call(null,f,oX)}):d;return $CLJS.n(a)?
$CLJS.EX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[JX,pla],null),function(f){return nX.g?nX.g(f,oX):nX.call(null,f,oX)}):d}],null),$CLJS.xN,function(a){return null==a?null:cX(a)},$CLJS.ZO,new $CLJS.h(null,1,[GX,pX],null),NX,cX],null),nX=function nX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return nX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
nX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.KA(Bla,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=cX(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,nX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=cX(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,nX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(lX(a))?OX.h(a):$CLJS.wd(a)?$CLJS.Qk.g(function(e){return nX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),GX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.hi($CLJS.uE("Error normalizing form: {0}",$CLJS.H([$CLJS.AV(d)])),new $CLJS.h(null,3,[$CLJS.hA,a,$CLJS.ol,c,sla,b],null),d);}throw e;}};nX.A=1;nX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var rX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(lX(f))?$CLJS.A(f):null},e,a,b,c,d)}();rX.m(null,$CLJS.di,function(a){return a});
rX.m(null,$CLJS.vG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(mX($CLJS.vG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return rX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,c,$CLJS.Fe($CLJS.fl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,b,$CLJS.Fe(a)],null)});
rX.m(null,$CLJS.kG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG,b,a],null)});rX.m(null,$CLJS.eL,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(lX(a))?rX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,a,null],null)});
rX.m(null,MX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,b,new $CLJS.h(null,1,[$CLJS.BB,a],null)],null)});rX.m(null,ula,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=sX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=sX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,a,$CLJS.R.j(c,$CLJS.MN,b)],null)});
rX.m(null,vla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=sX(a);return $CLJS.Se.v($CLJS.PV,a,$CLJS.R,$CLJS.H([$CLJS.BP,b]))});
rX.m(null,LX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=sX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.BB);$CLJS.Va(d)||$CLJS.TS.g(d,b)?a=$CLJS.Se.v($CLJS.PV,a,$CLJS.R,$CLJS.H([$CLJS.BG,b])):(c=$CLJS.TA($CLJS.Oy),$CLJS.n($CLJS.SA("metabase.mbql.util",c))&&(b=$CLJS.uE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.RA("metabase.mbql.util",c,$CLJS.Kw(),b):$CLJS.RA("metabase.mbql.util",c,$CLJS.Kw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),rX.h(new $CLJS.P(null,3,5,$CLJS.Q,[LX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
rX.m(null,$CLJS.KV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=sX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,b,$CLJS.R.j(e,$CLJS.CR,$CLJS.fl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.QE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var RX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.As,$CLJS.Cs],null)),SX=null,TX=0,UX=0;;)if(UX<TX){var VX=SX.X(null,UX);rX.m(null,VX,function(){return function(a){return tX(a)}}(RX,SX,TX,UX,VX));UX+=1}else{var WX=$CLJS.y(RX);if(WX){var XX=WX;if($CLJS.Ad(XX)){var YX=$CLJS.lc(XX),Cla=$CLJS.mc(XX),Dla=YX,Ela=$CLJS.D(YX);RX=Cla;SX=Dla;TX=Ela}else{var ZX=$CLJS.A(XX);rX.m(null,ZX,function(){return function(a){return tX(a)}}(RX,SX,TX,UX,ZX,XX,WX));RX=$CLJS.B(XX);SX=null;TX=0}UX=0}else break}
rX.m(null,$CLJS.dG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,sX(a),sX(b)],null),c)});rX.m(null,$CLJS.HG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=sX(a);a=$CLJS.n(iX($CLJS.vG,a))?$CLJS.PV.l(c,$CLJS.Gk,$CLJS.H([$CLJS.BG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HG,a],null),b)});
for(var $X=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.mG,$CLJS.GG,$CLJS.uG,$CLJS.nG,$CLJS.cs,$CLJS.NG,$CLJS.$r,$CLJS.bs,$CLJS.Wr,$CLJS.Yr,$CLJS.ZF,$CLJS.fG,$CLJS.XF,$CLJS.hG,$CLJS.YF],null)),aY=null,bY=0,cY=0;;)if(cY<bY){var dY=aY.X(null,cY);rX.m(null,dY,function(){return function(a){return uX(a)}}($X,aY,bY,cY,dY));cY+=1}else{var eY=$CLJS.y($X);if(eY){var fY=eY;if($CLJS.Ad(fY)){var gY=$CLJS.lc(fY),Fla=$CLJS.mc(fY),Gla=gY,Hla=$CLJS.D(gY);$X=Fla;aY=Gla;bY=Hla}else{var hY=$CLJS.A(fY);rX.m(null,hY,
function(){return function(a){return uX(a)}}($X,aY,bY,cY,hY,fY,eY));$X=$CLJS.B(fY);aY=null;bY=0}cY=0}else break}rX.m(null,rla,function(){return null});rX.m(null,$CLJS.CM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,rX.h(b),a],null)});
rX.m(null,IX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=rX.h;var e=$CLJS.Q;b=rX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,qla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.zE,a],null);return c.call(rX,new $CLJS.P(null,3,5,e,[$CLJS.CM,b,a],null))});
for(var iY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ay,$CLJS.wG],null)),jY=null,kY=0,lY=0;;)if(lY<kY){var mY=jY.X(null,lY);rX.m(null,mY,function(){return function(a){return vX(a)}}(iY,jY,kY,lY,mY));lY+=1}else{var nY=$CLJS.y(iY);if(nY){var oY=nY;if($CLJS.Ad(oY)){var pY=$CLJS.lc(oY),Ila=$CLJS.mc(oY),Jla=pY,Kla=$CLJS.D(pY);iY=Ila;jY=Jla;kY=Kla}else{var qY=$CLJS.A(oY);rX.m(null,qY,function(){return function(a){return vX(a)}}(iY,jY,kY,lY,qY,oY,nY));iY=$CLJS.B(oY);jY=null;kY=0}lY=0}else break}
for(var rY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.pG,$CLJS.SG,$CLJS.aG,$CLJS.LG,$CLJS.IG,$CLJS.Yn,$CLJS.ik,$CLJS.JG,$CLJS.bG],null)),sY=null,tY=0,uY=0;;)if(uY<tY){var vY=sY.X(null,uY);rX.m(null,vY,function(){return function(a){return wX(a)}}(rY,sY,tY,uY,vY));uY+=1}else{var wY=$CLJS.y(rY);if(wY){var xY=wY;if($CLJS.Ad(xY)){var yY=$CLJS.lc(xY),Lla=$CLJS.mc(xY),Mla=yY,Nla=$CLJS.D(yY);rY=Lla;sY=Mla;tY=Nla}else{var zY=$CLJS.A(xY);rX.m(null,zY,function(){return function(a){return wX(a)}}(rY,sY,tY,uY,
zY,xY,wY));rY=$CLJS.B(xY);sY=null;tY=0}uY=0}else break}rX.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,sX(b),a],null)});
for(var AY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CG,$CLJS.EG],null)),BY=null,CY=0,DY=0;;)if(DY<CY){var EY=BY.X(null,DY);rX.m(null,EY,function(){return function(a){return xX(a)}}(AY,BY,CY,DY,EY));DY+=1}else{var FY=$CLJS.y(AY);if(FY){var GY=FY;if($CLJS.Ad(GY)){var HY=$CLJS.lc(GY),Ola=$CLJS.mc(GY),Pla=HY,Qla=$CLJS.D(HY);AY=Ola;BY=Pla;CY=Qla}else{var IY=$CLJS.A(GY);rX.m(null,IY,function(){return function(a){return xX(a)}}(AY,BY,CY,DY,IY,GY,FY));AY=$CLJS.B(GY);BY=null;CY=0}DY=0}else break}
rX.m(null,$CLJS.QG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QG,rX.h(b),rX.h(a)],null)});
rX.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(rX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,b],null)),nX.l(a,$CLJS.H([oX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[rX.h(v),rX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[rX.h(m),rX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
rX.m(null,$CLJS.OF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,rX.h(a),$CLJS.E.g(0,b)?1:rX.h(b)],null),$CLJS.hf.g(rX,c))});
var zX=function zX(a){return function f(d,e){try{if($CLJS.n(function(){var x=fX($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=fX($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Vk.j(e,
0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.sE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.fs,null,$CLJS.tt,null,$CLJS.RG,null,$CLJS.es,null,IX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(PX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.tE(zX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;
}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&1<=$CLJS.D(e))try{var u=$CLJS.Vk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=
G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;}else throw d;else throw x;}}($CLJS.Cf,a)},JY=function JY(a){return function f(d,e){try{var k=fX($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.sG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.lG))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lG,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,HX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=
S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,KX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lG,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,HX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,KX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lG,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof
Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.sG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sG,sX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.lG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lG,sX(t)],null);throw $CLJS.Y;}catch(Ma){if(Ma instanceof Error){t=Ma;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Ma;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;
}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.Vk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.Vk.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.hl.h($CLJS.hf.g(JY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof
Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.gX(f,d,e);throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.Cf,a)},CX=$CLJS.Ok.g(eX,function(a){var b=DX($CLJS.kG.h(a))?$CLJS.Rk.j(a,$CLJS.kG,gla):a;b=DX($CLJS.jG.h(a))?$CLJS.Rk.j(b,$CLJS.jG,hla):b;b=DX($CLJS.eF.h(a))?$CLJS.Rk.j(b,$CLJS.eF,$CLJS.Ve($CLJS.Qk,qX)):b;b=DX($CLJS.WR.h(a))?$CLJS.Rk.j(b,$CLJS.WR,JY):b;return DX($CLJS.QQ.h(a))?
$CLJS.Rk.j(b,$CLJS.QQ,jla):b}),Rla=new $CLJS.h(null,3,[$CLJS.rQ,$CLJS.Td,$CLJS.SF,new $CLJS.h(null,2,[$CLJS.QQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.rQ);if($CLJS.n(b))return a=$CLJS.HV(a,new $CLJS.h(null,1,[$CLJS.rQ,$CLJS.SF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rQ],null),a=FX.g?FX.g(a,b):FX.call(null,a,b),$CLJS.HV(a,new $CLJS.h(null,1,[$CLJS.SF,$CLJS.rQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.SF],null);return FX.g?FX.g(a,b):FX.call(null,a,b)},$CLJS.LN,new $CLJS.h(null,1,
[GX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.SF],null);return FX.g?FX.g(a,b):FX.call(null,a,b)}],null)],null),NX,$CLJS.Td],null),FX=function FX(a){switch(arguments.length){case 1:return FX.h(arguments[0]);case 2:return FX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};FX.h=function(a){return FX.g(a,$CLJS.Cf)};
FX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.KA(Rla,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?nla(a,b):$CLJS.wd(a)?ola(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.hi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.hA,a,$CLJS.ol,b],null),d);throw d;}};FX.A=2;
$CLJS.KY=function(){var a=$CLJS.Ok.l(FX,mla,kla,$CLJS.H([nX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.hi($CLJS.uE("Error normalizing query: {0}",$CLJS.H([$CLJS.AV(c)])),new $CLJS.h(null,1,[$CLJS.SF,b],null),c);}throw d;}}}();$CLJS.LY=function LY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.KY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.It(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=LY.g?LY.g(f,b):LY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};