var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var DZ,Ema,Fma,Gma,Hma,Ima,JZ,Jma,Kma,Lma,MZ,Mma,Nma,Oma,RZ,SZ,Pma,Qma,Rma,$Z,Sma,Tma,Uma,c_,Vma,Wma,Xma,Yma;DZ=function(){};$CLJS.EZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(DZ,a):$CLJS.$a(DZ,a)};Ema=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.FZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Fma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Gma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.GZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.HZ=new $CLJS.M(null,"database-id","database-id",1883826326);Hma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.IZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Ima=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);JZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Jma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Kma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Lma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.KZ=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.LZ=new $CLJS.M(null,"dataset","dataset",1159262238);MZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Mma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.NZ=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.OZ=new $CLJS.M(null,"definition","definition",-1198729982);Nma=new $CLJS.M(null,"state","state",-1988618099);$CLJS.PZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Oma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.QZ=new $CLJS.M("source","joins","source/joins",1225821486);RZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
SZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.TZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);Pma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.UZ=new $CLJS.M(null,"selected?","selected?",-742502788);Qma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Rma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.VZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.WZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.XZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.YZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.ZZ=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);
$Z=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Sma=new $CLJS.M(null,"details","details",1956795411);$CLJS.a_=new $CLJS.M("source","fields","source/fields",-649667981);Tma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.b_=new $CLJS.M("source","native","source/native",-1444604147);
Uma=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);c_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.d_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Vma=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);Wma=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
Xma=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);Yma=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.e_=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.f_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(RZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.xs,$CLJS.WZ,$CLJS.b_,$CLJS.GZ,$CLJS.IZ,$CLJS.a_,$CLJS.qL,$CLJS.lL,$CLJS.QZ,$CLJS.NZ,$CLJS.PZ],null));$CLJS.X($Z,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,Yma],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.dL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eL,$CLJS.NE],null)],null));
$CLJS.X(JZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,Vma],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.dL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Rs],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ima,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Rs],null)],null)],null));
$CLJS.X($CLJS.KZ,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.At,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.WK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Yj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BB,$CLJS.OE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.NE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.zs,$CLJS.Yj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.OE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iL,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.NE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hL,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.NE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.e_,
new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DE,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YK,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.d_,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.fL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FZ,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TZ,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yj,new $CLJS.h(null,2,[$CLJS.Yn,1,$CLJS.ik,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UZ,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.Qs],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Pma,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$Z],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Xma,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JZ],null)],null)],null)],null));
$CLJS.X(c_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VZ,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Mma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[Lma,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BB,$CLJS.OE],null)],null)],null)],null)],null)],null));
$CLJS.X(MZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XZ,$CLJS.Qs],null),new $CLJS.P(null,2,5,$CLJS.Q,[Nma,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VZ,$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,c_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VK,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,
$CLJS.EE],null)],null)],null));
$CLJS.X(Tma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.At,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.pL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.fL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HZ,$CLJS.gL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YZ,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.hk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZZ,
new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.hk],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.Qs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jL,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.aL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Gma,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,MZ],null)],null)],null)],null));
$CLJS.X(Qma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.At,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.$K],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.ZK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jL,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.hk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Ut,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.EE],null)],null)],null));
$CLJS.X(Rma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.At,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.bL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.XK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jL,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.hk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Ut,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.EE],null)],null)],null));
$CLJS.X(Uma,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.At,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.cL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pl,new $CLJS.h(null,
1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.EE],null)],null)],null));
$CLJS.X(Fma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.At,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.nL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.gL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Kma,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.hk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sma,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.hk],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.f_,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.Xi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pA,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.Xi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Oma,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.Qs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kL,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.hk],null)],null)],null));
$CLJS.X(SZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.At,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.EZ},Hma,$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.xk,$CLJS.ui,$CLJS.jk,$CLJS.nk],[Ema,Wma,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$q],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.EZ)?$CLJS.EZ.H:null]))],null));
$CLJS.X(Jma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.At,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null)],null)],null)],null));