var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var tna,una,vna,wna,xna,d1;
$CLJS.a1=function(){function a(e,f){if($CLJS.Va(f))return"";e=$CLJS.IA(e);var k=$CLJS.zh.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.GV("Default period","Default periods",e);case "millisecond":return $CLJS.GV("Millisecond","Milliseconds",e);case "second":return $CLJS.GV("Second","Seconds",e);case "minute":return $CLJS.GV("Minute","Minutes",e);case "hour":return $CLJS.GV("Hour","Hours",e);case "day":return $CLJS.GV("Day","Days",e);case "week":return $CLJS.GV("Week","Weeks",
e);case "month":return $CLJS.GV("Month","Months",e);case "quarter":return $CLJS.GV("Quarter","Quarters",e);case "year":return $CLJS.GV("Year","Years",e);case "minute-of-hour":return $CLJS.GV("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.GV("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.GV("Day of week","Days of week",e);case "day-of-month":return $CLJS.GV("Day of month","Days of month",e);case "day-of-year":return $CLJS.GV("Day of year","Days of year",e);case "week-of-year":return $CLJS.GV("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.GV("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.GV("Quarter of year","Quarters of year",e);default:return f=$CLJS.$t($CLJS.gh(f),/-/),e=$CLJS.y(f),f=$CLJS.A(e),e=$CLJS.B(e),$CLJS.Lt(" ",$CLJS.ee($CLJS.FA(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.b1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.pC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.nC(f)},e,a,b,c,d)}();tna=$CLJS.Xe($CLJS.N);una=$CLJS.Xe($CLJS.N);
vna=$CLJS.Xe($CLJS.N);wna=$CLJS.Xe($CLJS.N);xna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.pC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.c1=new $CLJS.ei($CLJS.Fh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.nC,xna,tna,una,vna,wna);$CLJS.c1.m(null,$CLJS.di,function(){return null});$CLJS.c1.m(null,$CLJS.pH,function(a){return $CLJS.si.h(a)});d1=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.iH,null,$CLJS.lH,null,$CLJS.mH,null,$CLJS.jH,null],null),null);
$CLJS.yna=$CLJS.eg.j($CLJS.Cf,$CLJS.Ok.g($CLJS.Pk.h(d1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.hu,$CLJS.pH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.pk)?$CLJS.R.j(b,$CLJS.di,!0):b})),$CLJS.AH);$CLJS.zna=$CLJS.Qk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.hu,$CLJS.pH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.iu)?$CLJS.R.j(b,$CLJS.di,!0):b},$CLJS.vH);
$CLJS.Ana=$CLJS.eg.j($CLJS.Cf,$CLJS.Ok.g($CLJS.Pk.h(d1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.hu,$CLJS.pH,$CLJS.si,a],null);return $CLJS.E.g(a,$CLJS.iu)?$CLJS.R.j(b,$CLJS.di,!0):b})),$CLJS.BH);$CLJS.I0.m(null,$CLJS.pH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.si);return $CLJS.a1.h(a)});
$CLJS.P0.m(null,$CLJS.pH,function(a,b,c){return $CLJS.fl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.zE,$CLJS.i0.j(a,b,c),$CLJS.G0,$CLJS.gB($CLJS.c1.h(c))],null),$CLJS.dm(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,$CLJS.q0],null))]))});
$CLJS.e1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.pC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.nC(l)},e,a,b,c,d)}();$CLJS.e1.m(null,$CLJS.di,function(){return $CLJS.bh});
$CLJS.f1=function(){function a(d,e,f){return $CLJS.e1.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();