var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.lib.cache.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.memoize.js");
'use strict';var owa=function(a,b){const c={};for(const d in a)b.call(void 0,a[d],d,a)&&(c[d]=a[d]);return c},pwa=function(a,b){const c={};for(const d in a)c[d]=b.call(void 0,a[d],d,a);return c},qwa=function(a){return $CLJS.gc($CLJS.fb(function(b,c){return $CLJS.hg.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.ec($CLJS.N),a))},rwa=function(a,b,c,d){var e=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(f,k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),d);return function l(k){return new $CLJS.ne(null,function(){for(;;){var m=
$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.qe(u);a:for(var x=0;;)if(x<u){var z=$CLJS.kd(t,x);z=$CLJS.u2.v(a,b,z,d);z=$CLJS.J.j(e,z,-1);v.add(z);x+=1}else{t=!0;break a}return t?$CLJS.te($CLJS.ve(v),l($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}v=$CLJS.A(m);v=$CLJS.u2.v(a,b,v,d);return $CLJS.ee($CLJS.J.j(e,v,-1),l($CLJS.Lc(m)))}return null}},null,null)}(c)},A9=function(a,b){return new $CLJS.Jh(function(){try{return $CLJS.w9.g(a,b)}catch(e){if(e instanceof Error){var c=e,d=
$CLJS.TA($CLJS.My);$CLJS.n($CLJS.SA("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.RA("metabase.lib.js.metadata",d,$CLJS.CW("Error parsing %s objects: %s",$CLJS.H([a,$CLJS.AV(c)])),c):$CLJS.RA("metabase.lib.js.metadata",d,$CLJS.CW(c,$CLJS.H(["Error parsing %s objects: %s",a,$CLJS.AV(c)])),null));return null}throw e;}})},swa=function(a,b){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);return function(){for(var x=
0;;)if(x<l){var z=$CLJS.kd(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.jL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.jL.h(v),b):x}()))return $CLJS.ee(v,
e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.eF.h(a);return null==d?null:$CLJS.q(d)}())},twa=function(a,b){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.kd(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?
$CLJS.E.g($CLJS.jL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.jL.h(v),b):x}()))return $CLJS.ee(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.r9.h(a);return null==d?null:$CLJS.q(d)}())},uwa=function(a,b){return function e(d){return new $CLJS.ne(null,
function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.kd(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.jL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=
u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.jL.h(v),b):x}()))return $CLJS.ee(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.s9.h(a);return null==d?null:$CLJS.q(d)}())},B9=function(a,b,c,d){this.fg=a;this.Ii=b;this.tc=c;this.ji=d;this.C=393216;this.I=0},vwa=function(a){return $CLJS.Xa(a)?owa(a,function(b){return void 0!==b}):a},C9=function(a){return $CLJS.Kt($CLJS.Xz(pwa(a,function(b){return vwa(b)})),
function(b){return $CLJS.Rk.j($CLJS.LA(b,$CLJS.zh),$CLJS.mj,$CLJS.zh)})},D9=function(a,b){if($CLJS.EZ(b))a=b;else{var c=new $CLJS.h(null,6,[$CLJS.v9,A9($CLJS.bQ,b),$CLJS.t9,A9($CLJS.WW,b),$CLJS.eF,A9($CLJS.vG,b),$CLJS.q9,A9($CLJS.DN,b),$CLJS.r9,A9($CLJS.qG,b),$CLJS.s9,A9($CLJS.UF,b)],null),d=$CLJS.TA($CLJS.Ly);$CLJS.n($CLJS.SA("metabase.lib.js.metadata",d))&&$CLJS.RA("metabase.lib.js.metadata",d,$CLJS.Kw.l($CLJS.H(["Created metadata provider for metadata"])),null);a=new B9(a,b,c,$CLJS.N)}return a},
G9=function(a){return $CLJS.xd(a)?E9.h?E9.h(a):E9.call(null,a):$CLJS.wd(a)?F9.h?F9.h(a):F9.call(null,a):a instanceof $CLJS.M?$CLJS.gB(a):a},H9=function(a,b){return $CLJS.bX($CLJS.bX((0,$CLJS.KY)($CLJS.z9($CLJS.Xz(a))),$CLJS.rQ,function(c){return $CLJS.fl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.OQ,$CLJS.N],null),c]))}),$CLJS.SF,function(c){var d=function(){var e=$CLJS.eF.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.ne(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),
u=$CLJS.D(t),v=$CLJS.qe(u);a:for(var x=0;;)if(x<u){var z=$CLJS.kd(t,x);v.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,z,null],null));x+=1}else{t=!0;break a}return t?$CLJS.te($CLJS.ve(v),l($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}v=$CLJS.A(m);return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,v,null],null),l($CLJS.Lc(m)))}return null}},null,null)}(b)}();return $CLJS.R.j(c,$CLJS.eF,qwa(d))})},I9=function(a){return $CLJS.gZ.h($CLJS.Rk.j($CLJS.Wz(a,$CLJS.H([$CLJS.zi,!0])),0,$CLJS.zh))},wwa=function(a){var b=
$CLJS.Xa(a)?a:null;return $CLJS.n(b)?(a=$CLJS.y9.h?$CLJS.y9.h(b):$CLJS.y9.call(null,b),$CLJS.E.g($CLJS.YK.h(a),$CLJS.qL)?(b=I9(b.field_ref),$CLJS.R.j(a,$CLJS.l1,$CLJS.id(b))):a):a},J9=function(a,b){var c=null==a?null:$CLJS.A(a);c=null==c?null:$CLJS.E.g(c,$CLJS.kG);return $CLJS.n(c)?$CLJS.R.j(b,$CLJS.l1,$CLJS.id(a)):b},K9=function(a){return function(b){var c=function(){var e=a.h?a.h(b):a.call(null,b);return $CLJS.y9.h?$CLJS.y9.h(e):$CLJS.y9.call(null,e)}(),d=function(){var e=$CLJS.U1.h(c);return $CLJS.n(e)?
I9(e):null}();return new $CLJS.h(null,3,[$CLJS.Ti,J9(d,c),$CLJS.i6,d,$CLJS.Dj,b.value],null)}};$CLJS.g=B9.prototype;$CLJS.g.P=function(a,b){return new B9(this.fg,this.Ii,this.tc,b)};$CLJS.g.O=function(){return this.ji};$CLJS.g.ki=$CLJS.Bc;$CLJS.g.vg=function(a,b){a=this.tc;a=null==a?null:$CLJS.r9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.wg=function(a,b){return twa(this.tc,b)};
$CLJS.g.zg=function(a,b){a=this.tc;a=null==a?null:$CLJS.t9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.ug=function(a,b){return swa(this.tc,b)};$CLJS.g.tg=function(a,b){a=this.tc;a=null==a?null:$CLJS.eF.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.yg=function(a,b){return uwa(this.tc,b)};
$CLJS.g.rg=function(a,b){a=this.tc;a=null==a?null:$CLJS.q9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.xg=function(a,b){a=this.tc;a=null==a?null:$CLJS.s9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.sg=function(){var a=this.tc,b=this.fg;a=null==a?null:$CLJS.v9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};var L9=function L9(a){switch(arguments.length){case 1:return L9.h(arguments[0]);case 2:return L9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.extract_template_tags",L9);L9.h=function(a){return L9.g(a,$CLJS.N)};L9.g=function(a,b){return $CLJS.il($CLJS.mva.l($CLJS.H([a,C9(b)])))};L9.A=2;
var M9=function M9(a){return $CLJS.me(a)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.xd(a)?$CLJS.Kt(a,M9):$CLJS.wd(a)?$CLJS.hf.g(M9,a):a},E9=$CLJS.fB(function(a){return $CLJS.fb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);d=$CLJS.gB(d);const e=d.length-1;d=0<=e&&d.indexOf("?",e)==e?["is-",$CLJS.NA(d,/\?$/,"")].join(""):d;d=$CLJS.PB.h?$CLJS.PB.h(d):$CLJS.PB.call(null,d);c=G9.h?G9.h(c):G9.call(null,c);b[d]=c;return b},{},a)}),F9=$CLJS.fB(function(a){return $CLJS.we($CLJS.hf.g(G9,a))}),
N9=function N9(a){switch(arguments.length){case 1:return N9.h(arguments[0]);case 2:return N9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.order_by_clause",N9);N9.h=function(a){return N9.g(a,$CLJS.sG)};N9.g=function(a,b){return $CLJS.pva.l($CLJS.H([$CLJS.d9.l($CLJS.H([$CLJS.Wz(a,$CLJS.H([$CLJS.zi,!0]))])),$CLJS.zh.h(b)]))};N9.A=2;
var O9=function O9(a){switch(arguments.length){case 2:return O9.g(arguments[0],arguments[1]);case 3:return O9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.available_binning_strategies",O9);O9.g=function(a,b){return $CLJS.we($CLJS.c9.l($CLJS.H([a,b])))};O9.j=function(a,b,c){return $CLJS.we($CLJS.c9.l($CLJS.H([a,b,c])))};O9.A=3;
var P9=function P9(a){switch(arguments.length){case 2:return P9.g(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.available_temporal_buckets",P9);P9.g=function(a,b){return $CLJS.we($CLJS.f9.l($CLJS.H([a,b])))};P9.j=function(a,b,c){return $CLJS.we($CLJS.f9.l($CLJS.H([a,b,c])))};P9.A=3;
var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.g(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.query_EQ_",Q9);Q9.g=function(a,b){return Q9.j(a,b,null)};Q9.j=function(a,b,c){a=H9(a,c);b=H9(b,c);return $CLJS.E.g(a,b)};Q9.A=3;
var R9=function R9(a){switch(arguments.length){case 1:return R9.h(arguments[0]);case 2:return R9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.aggregation_clause",R9);R9.h=function(a){return $CLJS.a9.l($CLJS.H([a]))};R9.g=function(a,b){return $CLJS.a9.l($CLJS.H([a,b]))};R9.A=2;
var S9=function S9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return S9.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};$CLJS.za("metabase.lib.js.filter_clause",S9);S9.l=function(a,b,c){return $CLJS.Se.v($CLJS.Eua,a,b,c)};S9.A=2;S9.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};
var T9=function T9(a){switch(arguments.length){case 2:return T9.g(arguments[0],arguments[1]);case 3:return T9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.expressionable_columns",T9);T9.g=function(a,b){return T9.g(a,b)};T9.j=function(a,b,c){return $CLJS.we($CLJS.nua.l($CLJS.H([a,b,c])))};T9.A=3;
var U9=function U9(a){switch(arguments.length){case 3:return U9.j(arguments[0],arguments[1],arguments[2]);case 4:return U9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.with_different_database",U9);U9.j=function(a,b,c){return U9.v(a,b,c,null)};U9.v=function(a,b,c,d){return $CLJS.kva.l($CLJS.H([a,D9(b,c),$CLJS.Wz(d,$CLJS.H([$CLJS.zi,!0]))]))};U9.A=4;
var xwa=K9(function(a){return a.col}),ywa=K9(function(a){return a.column}),V9=function V9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return V9.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.w(c.slice(3),0,null):null)};$CLJS.za("metabase.lib.js.drill_thru",V9);V9.l=function(a,b,c,d){return $CLJS.Se.N($CLJS.fua,a,b,c,d)};V9.A=3;
V9.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return this.l(b,a,c,d)};
module.exports={returned_columns:function(a,b){var c=$CLJS.NW(a,b);return $CLJS.we($CLJS.hf.g(function(d){return $CLJS.R.j(d,$CLJS.UZ,!0)},$CLJS.R0.j(a,b,c)))},legacy_expression_for_expression_clause:function(a,b,c){b=$CLJS.b9.l($CLJS.H([a,b]));a=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=
$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.fZ,e=$CLJS.eZ;$CLJS.fZ=a;$CLJS.eZ=b;try{return $CLJS.il($CLJS.XY.h(c))}finally{$CLJS.eZ=e,$CLJS.fZ=d}},extract_template_tags:L9,engine:function(a){return $CLJS.gh($CLJS.fva.l($CLJS.H([a])))},field_id:function(a){return $CLJS.tua.l($CLJS.H([a]))},filter_operator:function(a,b,c){return $CLJS.Fua.l($CLJS.H([a,b,c]))},binning:function(a){return $CLJS.Wta.l($CLJS.H([a]))},query:function(a,b,c){c=$CLJS.gZ.h((0,$CLJS.KY)($CLJS.h2($CLJS.R7(c),
$CLJS.mj,$CLJS.SF)));var d=$CLJS.TA($CLJS.Ly);$CLJS.n($CLJS.SA("metabase.lib.js",d))&&$CLJS.RA("metabase.lib.js",d,$CLJS.CW("query map: %s",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),null);return $CLJS.rva.l($CLJS.H([D9(a,b),c]))},columns_group_columns:function(a){return $CLJS.we($CLJS.aua.l($CLJS.H([a])))},join_fields:function(a){a=$CLJS.Qua.l($CLJS.H([a]));return a instanceof $CLJS.M?$CLJS.gB(a):$CLJS.we(a)},aggregate:function(a,b,c){return $CLJS.Sta.l($CLJS.H([a,b,$CLJS.Wz(c,$CLJS.H([$CLJS.zi,!0]))]))},
legacy_card_or_table_id:function(a){return $CLJS.uua.l($CLJS.H([a]))},join_conditions:function(a){return $CLJS.we($CLJS.Pua.l($CLJS.H([a])))},query_EQ_:Q9,temporal_bucket:function(a){return $CLJS.Eva.l($CLJS.H([a]))},available_drill_thrus:function(a,b,c,d,e,f){var k=$CLJS.b9.l($CLJS.H([a,b])),l=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),k);k=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(v,
x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),k);var m=$CLJS.fZ,t=$CLJS.eZ;$CLJS.fZ=l;$CLJS.eZ=k;try{var u=function(){var v=c.field_ref;return $CLJS.n(v)?I9(v):null}();return $CLJS.we($CLJS.eua.l($CLJS.H([a,b,$CLJS.fl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Ti,J9(u,$CLJS.y9.h?$CLJS.y9.h(c):$CLJS.y9.call(null,c)),$CLJS.i6,u,$CLJS.Dj,void 0===d?null:null==d?$CLJS.Y2:d],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.h6,$CLJS.Qk.g(xwa,
e)],null):null,$CLJS.n($CLJS.Fe(f))?new $CLJS.h(null,1,[$CLJS.OL,$CLJS.Qk.g(ywa,f)],null):null]))])))}finally{$CLJS.eZ=t,$CLJS.fZ=m}},remove_clause:function(a,b,c){return $CLJS.uva.l($CLJS.H([a,b,$CLJS.d9.l($CLJS.H([$CLJS.Wz(c,$CLJS.H([$CLJS.zi,!0]))]))]))},replace_clause:function(a,b,c,d){return $CLJS.xva.l($CLJS.H([a,b,$CLJS.d9.l($CLJS.H([$CLJS.Wz(c,$CLJS.H([$CLJS.zi,!0]))])),$CLJS.d9.l($CLJS.H([$CLJS.Wz(d,$CLJS.H([$CLJS.zi,!0]))]))]))},drop_stage:function(a){return $CLJS.Ava.l($CLJS.H([a]))},join_lhs_display_name:function(a,
b,c,d){return $CLJS.Rua.l($CLJS.H([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.sua.l($CLJS.H([a,b,c]))},pivot_types:function(a){return $CLJS.we($CLJS.hua.l($CLJS.H([a])))},native_extras:function(a){return $CLJS.il($CLJS.iva.l($CLJS.H([a])))},available_temporal_buckets:P9,with_binning:function(a,b){return $CLJS.Xta.l($CLJS.H([a,b]))},append_stage:function(a){return $CLJS.zva.l($CLJS.H([a]))},join:function(a,b,c){return $CLJS.Jua.l($CLJS.H([a,b,c]))},aggregations:function(a,b){return $CLJS.we($CLJS.b9.l($CLJS.H([a,
b])))},drill_thru:V9,raw_native_query:function(a){return $CLJS.cva.l($CLJS.H([a]))},filterable_column_operators:function(a){return $CLJS.we($CLJS.Dua.l($CLJS.H([a])))},with_temporal_bucket:function(a,b){return $CLJS.Fva.l($CLJS.H([a,b]))},with_join_conditions:function(a,b){return $CLJS.Yua.l($CLJS.H([a,$CLJS.Wz(b,$CLJS.H([$CLJS.zi,!0]))]))},available_metrics:function(a){return $CLJS.we($CLJS.ava.l($CLJS.H([a])))},with_different_database:U9,with_fields:function(a,b,c){return $CLJS.zua.l($CLJS.H([a,
b,c]))},is_column_metadata:function(a){return $CLJS.xd(a)&&$CLJS.E.g($CLJS.WK,$CLJS.hu.h(a))},filter_args_display_name:function(a,b,c){return $CLJS.rua.l($CLJS.H([a,b,c]))},join_clause:function(a,b){return $CLJS.Kua.l($CLJS.H([a,b]))},aggregation_clause:R9,external_op:function(a){a=$CLJS.cua.l($CLJS.H([a]));var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.HE);var c=$CLJS.J.g(b,$CLJS.fj);b=$CLJS.J.g(b,$CLJS.ft);return{operator:a,options:$CLJS.il(c),args:$CLJS.we(b)}},with_join_fields:function(a,b){return $CLJS.Wua.l($CLJS.H([a,
"string"===typeof b?$CLJS.zh.h(b):b]))},find_visible_column_for_legacy_ref:function(a,b,c){return $CLJS.xua.l($CLJS.H([a,b,c]))},visible_columns:function(a,b){var c=$CLJS.NW(a,b),d=$CLJS.T0.j(a,b,c);c=$CLJS.R0.j(a,b,c);return $CLJS.we($CLJS.I3(a,b,d,c))},available_binning_strategies:O9,find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=$CLJS.kG.h($CLJS.NW(a,b)),f=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.LE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,v],null)}),e);e=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null)}),e);var k=$CLJS.fZ,l=$CLJS.eZ;$CLJS.fZ=f;$CLJS.eZ=e;try{var m=$CLJS.Qk.g(wwa,c),t=$CLJS.hf.g(I9,d);return $CLJS.we(rwa(a,b,t,m))}finally{$CLJS.eZ=l,$CLJS.fZ=k}},expression:function(a,b,c,d){return $CLJS.kua.l($CLJS.H([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.zh.h(a):
a;b="string"===typeof b?$CLJS.zh.h(b):b;return $CLJS.Dva.l($CLJS.H([a,b]))},order_bys:function(a,b){return $CLJS.we($CLJS.qva.l($CLJS.H([a,b])))},display_info:function(a,b,c){return $CLJS.g0($CLJS.zh.g("display-info-outer",["stage-",$CLJS.p.h(b)].join("")),c,function(d){return G9($CLJS.Zua.l($CLJS.H([$CLJS.a4(a,b),b,d])))})},fieldable_columns:function(a,b){return $CLJS.we($CLJS.vua.l($CLJS.H([a,b])))},template_tags:function(a){return $CLJS.il($CLJS.eva.l($CLJS.H([a])))},selected_aggregation_operators:function(a,
b){return $CLJS.we($CLJS.Vta.l($CLJS.H([$CLJS.y(a),b])))},order_by_clause:N9,filterable_columns:function(a,b){return $CLJS.we($CLJS.Cua.l($CLJS.H([a,b])))},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.we($CLJS.Nua.l($CLJS.H([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.Oua.l($CLJS.H([a,b,c,d]))},filters:function(a,b){return $CLJS.we($CLJS.Bua.l($CLJS.H([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.we($CLJS.Lua.l($CLJS.H([a,
b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.L1(a,b)},with_expression_name:function(a,b){return $CLJS.oua.l($CLJS.H([a,b]))},orderable_columns:function(a,b){return $CLJS.we($CLJS.N7.g(a,b))},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.Gua.l($CLJS.H([a,b,$CLJS.Wz(c,$CLJS.H([$CLJS.zi,!0]))]))},suggestedName:function(a){return $CLJS.$ua.l($CLJS.H([a]))},find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.iua.l($CLJS.H([a,b,c,d]))},has_write_permission:function(a){return $CLJS.lva.l($CLJS.H([a]))},
metadataProvider:D9,filter_clause:S9,fields:function(a,b){return $CLJS.we($CLJS.wua.l($CLJS.H([a,b])))},available_aggregation_operators:function(a,b){return $CLJS.we($CLJS.Uta.l($CLJS.H([a,b])))},expression_name:function(a){return $CLJS.lua.l($CLJS.H([a]))},breakouts:function(a,b){return $CLJS.we($CLJS.$ta.l($CLJS.H([a,b])))},joinable_columns:function(a,b,c){return $CLJS.we($CLJS.Tua.l($CLJS.H([a,b,c])))},group_columns:function(a){return $CLJS.we($CLJS.bua.l($CLJS.H([a])))},describe_temporal_unit:function(a,
b){b="string"===typeof b?$CLJS.zh.h(b):b;return $CLJS.Bva.l($CLJS.H([a,b]))},joins:function(a,b){return $CLJS.we($CLJS.Uua.l($CLJS.H([a,b])))},native_query:function(a,b,c){return $CLJS.bva.l($CLJS.H([D9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.we($CLJS.Iua.l($CLJS.H([a,b])))},legacy_query:function(a){return $CLJS.il.l(M9($CLJS.XY.h(a)),$CLJS.H([$CLJS.uk,$CLJS.gB]))},stage_count:function(a){return $CLJS.sva.l($CLJS.H([a]))},expression_clause:function(a,b,c){return $CLJS.pua.l($CLJS.H([$CLJS.zh.h(a),
b,$CLJS.Wz(c,$CLJS.H([$CLJS.zi,!0]))]))},find_matching_column:function(a,b,c,d){return $CLJS.jua.l($CLJS.H([a,b,c,d]))},with_native_extras:function(a,b){return $CLJS.jva.l($CLJS.H([a,$CLJS.Wz(b,$CLJS.H([$CLJS.zi,!0]))]))},join_strategy:function(a){return $CLJS.Sua.l($CLJS.H([a]))},picker_info:function(a,b){var c=$CLJS.hu.h(b);switch(c instanceof $CLJS.M?c.T:null){case "metadata/table":return{databaseId:$CLJS.bQ.h(a),tableId:$CLJS.Zi.h(b)};case "metadata/card":return{databaseId:$CLJS.bQ.h(a),tableId:["card__",
$CLJS.p.h($CLJS.Zi.h(b))].join(""),cardId:$CLJS.Zi.h(b),isModel:$CLJS.LZ.h(b)};default:return a=$CLJS.TA($CLJS.Oy),$CLJS.n($CLJS.SA("metabase.lib.js",a))&&$CLJS.RA("metabase.lib.js",a,$CLJS.Kw.l($CLJS.H(["Cannot provide picker-info for",$CLJS.hu.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.qua.l($CLJS.H([a,b,c]));return $CLJS.CV(function(d){if($CLJS.xd(d)&&$CLJS.E.g($CLJS.D8,$CLJS.hu.h(d))){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.HE);var f=$CLJS.J.g(e,$CLJS.fj);e=$CLJS.J.g(e,$CLJS.ft);
return{operator:$CLJS.gh(d),options:$CLJS.il($CLJS.dm(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DG,$CLJS.fk],null))),args:$CLJS.we($CLJS.hf.g(function(k){return k instanceof $CLJS.M?$CLJS.gB(k):k},e))}}return d},a)},expression_clause_for_legacy_expression:function(a,b,c){b=$CLJS.b9.l($CLJS.H([a,b]));a=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(f,
k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.fZ,e=$CLJS.eZ;$CLJS.fZ=a;$CLJS.eZ=b;try{return $CLJS.gZ.h($CLJS.d9.l($CLJS.H([$CLJS.Wz(c,$CLJS.H([$CLJS.zi,!0]))])))}finally{$CLJS.eZ=e,$CLJS.fZ=d}},format_relative_date_range:function(a,b,c,d,e){b=$CLJS.zh.h(b);d=null==d?null:$CLJS.zh.h(d);e=$CLJS.Wz(e,$CLJS.H([$CLJS.zi,!0]));return $CLJS.L2((0,$CLJS.WI)(),a,b,c,d,e)},aggregation_operator_columns:function(a){return $CLJS.we($CLJS.Tta.l($CLJS.H([a])))},
find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.Hua.l($CLJS.H([a,b,c]))},available_segments:function(a,b){return $CLJS.we($CLJS.yva.l($CLJS.H([a,b])))},join_condition_operators:function(a,b,c,d){return $CLJS.we($CLJS.Mua.l($CLJS.H([a,b,c,d])))},expressionable_columns:T9,filter:function(a,b,c){return $CLJS.Aua.l($CLJS.H([a,b,$CLJS.Wz(c,$CLJS.H([$CLJS.zi,!0]))]))},change_direction:function(a,b){return $CLJS.nva.l($CLJS.H([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.gua.l($CLJS.H([a,
b]))},filter_drill_details:function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.SF);var c=$CLJS.J.g(b,$CLJS.Ti),d=$CLJS.J.g(b,$CLJS.LL);b=$CLJS.J.g(b,$CLJS.Dj);return{column:c,query:a,stageNumber:d,value:$CLJS.E.g(b,$CLJS.Y2)?null:b}},legacy_field_ref:function(a){return $CLJS.il($CLJS.Rk.v($CLJS.XY.h($CLJS.e9.l($CLJS.H([a]))),2,$CLJS.Kt,function(b){return $CLJS.me(b)?$CLJS.gB(b):b}))},remove_field:function(a,b,c){return $CLJS.yua.l($CLJS.H([a,b,c]))},with_template_tags:function(a,b){return $CLJS.gva.l($CLJS.H([a,
C9(b)]))},expressions:function(a,b){return $CLJS.we($CLJS.mua.l($CLJS.H([a,b])))},suggested_join_conditions:function(a,b,c){return $CLJS.we($CLJS.Vua.l($CLJS.H([a,b,c])))},with_join_strategy:function(a,b){return $CLJS.Xua.l($CLJS.H([a,b]))},order_by:function(a,b,c,d){return $CLJS.ova.l($CLJS.H([a,b,c,$CLJS.zh.h(d)]))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.zh.h(a):a;b="string"===typeof b?$CLJS.zh.h(b):b;return $CLJS.Cva.l($CLJS.H([a,b]))},breakout:function(a,b,c){return $CLJS.Yta.l($CLJS.H([a,
b,$CLJS.e9.l($CLJS.H([c]))]))},database_id:function(a){return $CLJS.dua.l($CLJS.H([a]))},required_native_extras:function(a,b){return $CLJS.we($CLJS.hf.g($CLJS.gB,$CLJS.hva.l($CLJS.H([D9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.vva.l($CLJS.H([a,b,c]))},joined_thing:function(a,b){return $CLJS.V3(a,b)},with_native_query:function(a,b){return $CLJS.dva.l($CLJS.H([a,b]))},breakoutable_columns:function(a,b){return $CLJS.we($CLJS.Zta.l($CLJS.H([a,b])))},rename_join:function(a,b,c,d){return $CLJS.wva.l($CLJS.H([a,
b,c,d]))},with_different_table:function(a,b){return $CLJS.tva.l($CLJS.H([a,b]))}};