var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var uu,wu,xu,yu,zu,Au,Bu,Iu,Ju,Ku,Lu,Mu,xba,Pu,yba,Su,Tu,Vu,Wu,Xu,Yu,Zu,$u,ev,hv,jv,mv,zba,Aba,rv,Bba,tv,uv,vv,Bv,Cv,Kv,Ov,Uv,Wv,aw,ew,jw,lw,mw,gw,ow,pw,rw,qw,sw,Fba,xw,Gw,Hw,Aw,Hba,Lw,Ow,Qw,Rw,Jba,Kba,Lba,Vw,Zw,$w,ax,bx,Mba,gx,jx,Nba,Oba,Pba,Qba,Rba,Sba,Tba,Uba,Vba,Xba,ox,Wba,sx,Yba,tx,Zba,$ba,ux,aca,vx,bca,cca,dca,fca,gca,ica,jca,lca,nca,Ax,Dx,pca,oca,qca,fw,iw,Kx,Lx,Mx,Nx,Qx,Rx,tca,uca,vca,Ux,wca,Vx,Tx,Wx,cv,Xx,qv,ix,xca,Yv,yca,zca,fv,ov,Ox,Aca,Mv,ww,wv,Bca,Cca,Jv,Dca,Bx,Av,vw,Hv,
kx,Eca,pv,Fca,rx,yx,Yx,Gca,Sw,$x,Uw,Lv,uw,bv,xx,Eba,Xv,Fv,iv,Hca,nw,Ica,Dw,Tw,Fx,Jca,Hx,Dba,Kca,Lca,Mca,bw,Nca,px,$v,Oca,Ew,Cw,Pca,kv,Qca,sca,Ix,xv,by,Xw,cy,dy,Iw,Rca,zv,Sca,rca,Tca,dw,nx,tw,Uca,Yw,Px,Vca,mx,Wca,Bw,nv,Xca,Nv,fy,Ww,Yca,gv,wx,Zv,Cba,zx,lv,cx,Cx,hx,dx,fx,qx,Jw,gy,hy,Zca,Uu,$ca,Ru,av,ada,yv,bda;uu=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.vu=function(){};
wu=function(a){if(null!=a&&null!=a.rc)a=a.rc(a);else{var b=wu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=wu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IWriter.-flush",a);}return a};xu=function(){};yu=function(a){if(null!=a&&null!=a.Lc)a=a.Lc(a);else{var b=yu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=yu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPending.-realized?",a);}return a};
zu=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};Au=function(a,b){return a-b*$CLJS.Vd(a,b)};Bu=function(a,b,c){if(null!=a&&null!=a.m)a.m(a,b,c);else{var d=Bu[$CLJS.wa(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=Bu._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.bb("IMultiFn.-add-method",a);}};
$CLJS.Cu=function(a,b){var c=$CLJS.ec($CLJS.N);a=$CLJS.y(a);for(b=$CLJS.y(b);;)if(a&&b)c=$CLJS.hg.j(c,$CLJS.A(a),$CLJS.A(b)),a=$CLJS.B(a),b=$CLJS.B(b);else return $CLJS.gc(c)};$CLJS.Du=function(a,b){return $CLJS.lh(a,b)};$CLJS.Eu=function(a,b){return new $CLJS.ne(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.A(c);d=a.h?a.h(d):a.call(null,d);c=$CLJS.n(d)?$CLJS.ee($CLJS.A(c),$CLJS.Eu(a,$CLJS.Lc(c))):null}else c=null;return c},null,null)};
$CLJS.Fu=function(a,b){return $CLJS.hf.j(function(c){return c},b,$CLJS.bf(a,b))};Iu=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.Se.g(Gu,0<b.length?new $CLJS.w(b.slice(0),0,null):null);$CLJS.ac($CLJS.Hu,"\n")};Ju=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
Ku=function(a,b,c){var d=c;for(c=$CLJS.Cf;;){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null);var e=$CLJS.A(d);d=$CLJS.B(d);e=$CLJS.Se.g(a,new $CLJS.P(null,2,5,$CLJS.Q,[e,b],null));b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);c=$CLJS.be.g(c,b);b=e}};Lu=function(a,b){var c=b;for(b=$CLJS.Cf;;){var d=$CLJS.Se.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[c],null));c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.Va(c))return new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null);b=$CLJS.be.g(b,c);c=d}};
Mu=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);var v=$CLJS.I(t,0,null);$CLJS.I(t,1,null);l.add(new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null));m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);k=$CLJS.I(f,0,null);$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()),$CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);l.add(new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null));m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())],null)};
xba=function(a,b){return $CLJS.eg.g($CLJS.N,function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,new $CLJS.P(null,2,5,$CLJS.Q,[u,b],null)],null));t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);
k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,new $CLJS.P(null,2,5,$CLJS.Q,[k,b],null)],null),e($CLJS.Lc(f)))}return null}},null,null)}(a)}())};$CLJS.Nu=function(){var a=$CLJS.Hu;if(null!=a&&null!=a.uf)a.uf(a);else{var b=$CLJS.Nu[$CLJS.wa(null==a?null:a)];if(null!=b)b.h?b.h(a):b.call(null,a);else if(b=$CLJS.Nu._,null!=b)b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPrettyFlush.-ppflush",a);}};$CLJS.Ou=function(a,b){a=$CLJS.q($CLJS.q(a));return b.h?b.h(a):b.call(null,a)};
Pu=function(a,b,c){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,b,c)};yba=function(a,b){$CLJS.E.g(b,"\n")?(Pu(a,$CLJS.Qu,0),Pu(a,$CLJS.nj,$CLJS.Ou(a,$CLJS.nj)+1)):Pu(a,$CLJS.Qu,$CLJS.Ou(a,$CLJS.Qu)+1);return $CLJS.ac($CLJS.Ou(a,Ru),b)};Su=function(a,b,c,d){this.Ca=a;this.Nf=b;this.Yc=c;this.bi=d;this.C=1074167808;this.I=0};
Tu=function(a,b,c,d,e,f,k,l,m,t,u,v,x){this.parent=a;this.Cb=b;this.tb=c;this.sb=d;this.vb=e;this.wb=f;this.prefix=k;this.Ab=l;this.Db=m;this.xb=t;this.S=u;this.G=v;this.J=x;this.C=2230716170;this.I=139264};Vu=function(a,b){for(b=Uu.h(b);;){if(null==b)return!1;if(a===b)return!0;b=Uu.h(b)}};Wu=function(a,b,c,d,e,f,k,l){this.ca=a;this.data=b;this.ac=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};
Xu=function(a,b,c,d,e,f,k,l){this.ca=a;this.type=b;this.ea=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};Yu=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};Zu=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
$u=function(a,b,c,d,e,f,k,l,m){this.ca=a;this.ea=b;this.Wb=c;this.offset=d;this.ba=e;this.aa=f;this.S=k;this.G=l;this.J=m;this.C=2230716170;this.I=139264};
ev=function(a,b,c){b=$CLJS.y(b);for(var d=null,e=0,f=0;;)if(f<e){var k=d.X(null,f);if(!$CLJS.E.g(av.h(k),bv)){var l=cv.h($CLJS.q($CLJS.q(a)));$CLJS.n(l)&&$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),l)}dv.g(a,k);$CLJS.Oh.v($CLJS.q(a),$CLJS.R,cv,cv.h(k));k=cv.h($CLJS.q($CLJS.q(a)));$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,cv,null));f+=1}else if(b=$CLJS.y(b))$CLJS.Ad(b)?(d=$CLJS.lc(b),b=$CLJS.mc(b),k=d,e=$CLJS.D(d),d=k):(k=$CLJS.A(b),$CLJS.E.g(av.h(k),bv)||
(d=cv.h($CLJS.q($CLJS.q(a))),$CLJS.n(d)&&$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),d)),dv.g(a,k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,cv,cv.h(k)),k=cv.h($CLJS.q($CLJS.q(a))),$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,cv,null)),b=$CLJS.B(b),d=null,e=0),f=0;else break};hv=function(a,b){var c=$CLJS.Ou(Ru.h($CLJS.q($CLJS.q(a))),$CLJS.ik),d;(d=null==c)||(a=$CLJS.Ou(Ru.h($CLJS.q($CLJS.q(a))),$CLJS.Qu),b=(b=$CLJS.y(b))?fv.h($CLJS.id(b))-gv.h($CLJS.A(b)):0,d=a+b<c);return d};
jv=function(a,b,c){b=$CLJS.q(iv.h(b));return $CLJS.n(b)?b:!hv(a,c)};mv=function(a,b,c){var d=kv.h($CLJS.q($CLJS.q(a))),e=$CLJS.Ou(Ru.h($CLJS.q($CLJS.q(a))),$CLJS.ik);return $CLJS.n(d)?$CLJS.n(e)?(d=$CLJS.q(lv.h(b))>=e-d)?jv(a,b,c):d:e:d};zba=function(a){var b=$CLJS.A(a),c=nv.h(b);b=$CLJS.y($CLJS.Eu(function(d){return!($CLJS.E.g(av.h(d),bv)&&Vu(nv.h(d),c))},$CLJS.B(a)));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.bf($CLJS.D(b)+1,a))],null)};
Aba=function(a){var b=$CLJS.A(a),c=nv.h(b);return $CLJS.y($CLJS.Eu(function(d){var e=nv.h(d);return!($CLJS.E.g(av.h(d),bv)&&($CLJS.E.g(e,c)||Vu(e,c)))},$CLJS.B(a)))};
rv=function(a,b){$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.Oh.v($CLJS.q(a),$CLJS.R,cv,null);b=nv.h(b);var c=ov.h(b);$CLJS.n(c)&&$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),c);c=$CLJS.Se.g($CLJS.p,$CLJS.df($CLJS.q(pv.h(b))-$CLJS.D(c)," "));$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),c);a:for($CLJS.Ye(qv.h(b),!0),$CLJS.Ye(iv.h(b),!0),a=Uu.h(b);;)if($CLJS.n(a))$CLJS.Ye(iv.h(a),!0),$CLJS.Ye(qv.h(a),!0),a=Uu.h(a);else break a;return null};
Bba=function(a){var b=$CLJS.y($CLJS.Eu(function(c){return!$CLJS.E.g(av.h(c),bv)},a));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.bf($CLJS.D(b),a))],null)};tv=function(a){for(var b=$CLJS.Ot.h($CLJS.q($CLJS.q(a)));;){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Ot,$CLJS.eg.g($CLJS.Cf,b));if(hv(a,b))return null;var c=sv(a,b);if(b!==c)b=c;else return null}};
uv=function(a,b){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Ot,$CLJS.be.g($CLJS.Ot.h($CLJS.q($CLJS.q(a))),b));return hv(a,$CLJS.Ot.h($CLJS.q($CLJS.q(a))))?null:tv(a)};vv=function(a){var b=cv.h($CLJS.q($CLJS.q(a)));return $CLJS.n(b)?($CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),b),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,cv,null)):null};
Bv=function(a,b){var c=$CLJS.Mt(b,"\n",-1);if($CLJS.E.g($CLJS.D(c),1))return b;b=ov.h($CLJS.A(wv.h($CLJS.q($CLJS.q(a)))));var d=$CLJS.A(c);if($CLJS.E.g(xv,$CLJS.yi.h($CLJS.q($CLJS.q(a))))){var e=yv.h($CLJS.q($CLJS.q(a))),f=e+$CLJS.D(d);$CLJS.Oh.v($CLJS.q(a),$CLJS.R,yv,f);uv(a,new Wu(zv,d,null,e,f,null,null,null));tv(a);d=$CLJS.Ot.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(ev(a,d,!0),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Ot,$CLJS.Cf))}else vv(a),$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),d);$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),
"\n");d=$CLJS.y($CLJS.B($CLJS.It(c)));e=null;for(var k=f=0;;)if(k<f){var l=e.X(null,k);$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),l);$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.n(b)&&$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),b);k+=1}else if(d=$CLJS.y(d))e=d,$CLJS.Ad(e)?(d=$CLJS.lc(e),k=$CLJS.mc(e),e=d,f=$CLJS.D(d),d=k):(d=$CLJS.A(e),$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),d),$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),"\n"),$CLJS.n(b)&&$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),b),d=$CLJS.B(e),e=null,f=0),k=0;else break;$CLJS.Oh.v($CLJS.q(a),
$CLJS.R,xv,Av);return $CLJS.id(c)};Cv=function(a,b,c,d,e,f){this.Ca=a;this.Nf=b;this.li=c;this.mh=d;this.Yc=e;this.ci=f;this.C=1074167808;this.I=0};
$CLJS.Gv=function(a){var b=$CLJS.Dv,c=Ev,d=new Tu(null,null,$CLJS.Xe(0),$CLJS.Xe(0),$CLJS.Xe(!1),$CLJS.Xe(!1),null,null,null,null,null,null,null),e=$CLJS.Xe($CLJS.Ig([wv,kv,Cba,Fv,Dba,$CLJS.yi,yv,cv,Ru,Eba,$CLJS.Ot],[d,c,d,!0,null,Av,0,null,new Su(a,b,$CLJS.Xe(new $CLJS.h(null,4,[$CLJS.ik,b,$CLJS.Qu,0,$CLJS.nj,0,Ru,a],null)),$CLJS.N),1,$CLJS.Cf]));return new Cv(a,b,c,d,e,$CLJS.N)};
Kv=function(a,b){var c=$CLJS.Hu;b=new Tu(wv.h($CLJS.q($CLJS.q(c))),null,$CLJS.Xe(0),$CLJS.Xe(0),$CLJS.Xe(!1),$CLJS.Xe(!1),a,null,b,null,null,null,null);$CLJS.Oh.v($CLJS.q(c),$CLJS.R,wv,b);if($CLJS.E.g($CLJS.yi.h($CLJS.q($CLJS.q(c))),Av)){vv(c);var d=Hv.h($CLJS.q($CLJS.q(c)));$CLJS.n(d)&&(d.h?d.h($CLJS.Iv):d.call(null,$CLJS.Iv));$CLJS.n(a)&&$CLJS.ac(Ru.h($CLJS.q($CLJS.q(c))),a);c=$CLJS.Ou(Ru.h($CLJS.q($CLJS.q(c))),$CLJS.Qu);$CLJS.Ye(b.tb,c);$CLJS.Ye(b.sb,c)}else d=yv.h($CLJS.q($CLJS.q(c))),a=d+($CLJS.n(a)?
$CLJS.D(a):0),$CLJS.Oh.v($CLJS.q(c),$CLJS.R,yv,a),uv(c,new Yu(Jv,b,d,a,null,null,null))};
Ov=function(){var a=$CLJS.Hu,b=wv.h($CLJS.q($CLJS.q(a))),c=Lv.h(b);if($CLJS.E.g($CLJS.yi.h($CLJS.q($CLJS.q(a))),Av)){vv(a);$CLJS.n(c)&&$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),c);var d=Hv.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(d.h?d.h(Mv):d.call(null,Mv))}else d=yv.h($CLJS.q($CLJS.q(a))),c=d+($CLJS.n(c)?$CLJS.D(c):0),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,yv,c),uv(a,new Zu(Nv,b,d,c,null,null,null));$CLJS.Oh.v($CLJS.q(a),$CLJS.R,wv,Uu.h(b))};
$CLJS.Pv=function(a){var b=null!=a?a.C&32768||$CLJS.Bc===a.ag?!0:a.C?!1:$CLJS.$a($CLJS.vu,a):$CLJS.$a($CLJS.vu,a);return b?Fv.h($CLJS.q($CLJS.q(a))):b};$CLJS.Tv=function(a){var b=Qv;$CLJS.n(b)&&(b=$CLJS.Na,b=$CLJS.n(b)?Qv>=$CLJS.Na:b);$CLJS.Va($CLJS.Rv)?Gu.call(null,a):$CLJS.n(b)?$CLJS.ac($CLJS.Hu,"..."):($CLJS.n(Qv)&&(Qv+=1),Sv.call(null,a));return b};
Uv=function(a,b){if($CLJS.Va(b.h?b.h(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.h(a),". It must be one of ",$CLJS.p.h(b)].join(""));};Wv=function(){var a=$CLJS.qh;return $CLJS.n(a)?Vv>=$CLJS.qh:a};aw=function(a){Uv(a,new $CLJS.ah(null,new $CLJS.h(null,4,[Xv,null,Yv,null,Zv,null,$v,null],null),null));var b=$CLJS.Hu;$CLJS.Oh.v($CLJS.q(b),$CLJS.R,$CLJS.yi,xv);var c=yv.h($CLJS.q($CLJS.q(b))),d=wv.h($CLJS.q($CLJS.q(b)));uv(b,new Xu(bv,a,d,c,c,null,null,null))};
ew=function(a,b){Uv(a,new $CLJS.ah(null,new $CLJS.h(null,2,[bw,null,$CLJS.cw,null],null),null));var c=$CLJS.Hu,d=wv.h($CLJS.q($CLJS.q(c)));if($CLJS.E.g($CLJS.yi.h($CLJS.q($CLJS.q(c))),Av)){vv(c);var e=pv.h(d);if($CLJS.n($CLJS.E.g?$CLJS.E.g(bw,a):$CLJS.E.call(null,bw,a)))a=$CLJS.q(lv.h(d));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.cw,a):$CLJS.E.call(null,$CLJS.cw,a)))a=$CLJS.Ou(Ru.h($CLJS.q($CLJS.q(c))),$CLJS.Qu);else throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));$CLJS.Ye(e,b+a)}else e=
yv.h($CLJS.q($CLJS.q(c))),uv(c,new $u(dw,d,a,b,e,e,null,null,null))};jw=function(a,b,c){b="string"===typeof b?fw(b):b;c=gw(c);a:{var d=new $CLJS.Ea,e=$CLJS.Va(a)||!0===a?new $CLJS.sc(d):a,f=hw(b)&&$CLJS.Va($CLJS.Pv(e))?$CLJS.n($CLJS.Pv(e))?e:$CLJS.Gv(e):e,k=$CLJS.Hu;$CLJS.Hu=f;try{try{iw(b,c)}finally{e!==f&&wu(f)}var l=$CLJS.Va(a)?$CLJS.p.h(d):!0===a?$CLJS.uh($CLJS.p.h(d)):null;break a}finally{$CLJS.Hu=k}l=void 0}return l};
lw=function(a,b){a=[$CLJS.p.h(a),"\n",$CLJS.p.h(kw),"\n",$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(b," "))),"^\n"].join("");throw Error(a);};mw=function(a,b,c,d,e,f){this.uc=a;this.pb=b;this.$b=c;this.S=d;this.G=e;this.J=f;this.C=2230716170;this.I=139264};gw=function(a){a=$CLJS.y(a);return new mw(a,a,0,null,null,null)};
ow=function(a){var b=$CLJS.Pi.h(a);if($CLJS.n(b))return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),new mw(nw.h(a),$CLJS.B(b),yv.h(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};pw=function(a){var b=ow(a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);a="string"===typeof a?fw(a):a;return new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null)};rw=function(a,b){return b>=yv.h(a)?(b=yv.h(a)-b,qw.g?qw.g(a,b):qw.call(null,a,b)):new mw(nw.h(a),$CLJS.bf(b,nw.h(a)),b,null,null,null)};
qw=function(a,b){var c=yv.h(a)+b;return 0>b?rw(a,c):new mw(nw.h(a),$CLJS.bf(b,$CLJS.Pi.h(a)),c,null,null,null)};sw=function(a,b,c,d,e,f,k){this.dc=a;this.cc=b;this.kc=c;this.offset=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Fba=function(a,b){var c=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);d=$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,2,[tw,null,uw,null],null),null),c)?new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null):$CLJS.E.g(d,vw)?ow(b):$CLJS.E.g(d,ww)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.D($CLJS.Pi.h(b)),b],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.P(null,2,5,$CLJS.Q,
[b,a],null)],null),d],null)};xw=function(a,b){b=Ku(Fba,b,a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eg.g($CLJS.N,a),b],null)};
Gw=function(a,b,c){c=ow(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d))if($CLJS.E.g(yw,10))var e=[$CLJS.p.h(d),$CLJS.n(zw)?".":null].join("");else{e=$CLJS.p;var f=e.h;if($CLJS.n(zw)){var k=$CLJS.J.g(Gba,yw);k=$CLJS.n(k)?k:["#",$CLJS.p.h(yw),"r"].join("")}else k=null;e=[f.call(e,k),$CLJS.p.h(Aw(yw,d))].join("")}else e=null;a=$CLJS.n(e)?e:a.h?a.h(d):a.call(null,d);d=a.length;e=d+Bw.h(b);e=e>=Cw.h(b)?e:e+($CLJS.Vd(Cw.h(b)-e-1,Dw.h(b))+1)*Dw.h(b);d=$CLJS.Se.g($CLJS.p,$CLJS.df(e-d,Ew.h(b)));
$CLJS.n(uw.h(b))?Fw.l($CLJS.H([[$CLJS.p.h(d),$CLJS.p.h(a)].join("")])):Fw.l($CLJS.H([[$CLJS.p.h(a),$CLJS.p.h(d)].join("")]));return c};Hw=function(a,b){return $CLJS.ce($CLJS.A(Lu(function(c){return 0<c?new $CLJS.P(null,2,5,$CLJS.Q,[Au(c,a),$CLJS.Vd(c,a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null)},b)))};Aw=function(a,b){return 0===b?"0":$CLJS.Se.g($CLJS.p,$CLJS.hf.g(function(c){return 10>c?zu(Ju("0")+c):zu(Ju("a")+(c-10))},Hw(a,b)))};
Hba=function(a,b){return $CLJS.ce($CLJS.A(Lu(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.y($CLJS.ce($CLJS.af(a,c))),$CLJS.y($CLJS.bf(a,c))],null)},$CLJS.ce(b))))};
Lw=function(a,b,c){c=ow(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.E.g(d,Math.floor(d))){var e=0>d,f=Aw(a,e?-d:d);a=$CLJS.n(tw.h(b))?function(){var k=$CLJS.hf.g(function(m){return $CLJS.Se.g($CLJS.p,m)},Hba(Iw.h(b),f)),l=$CLJS.df($CLJS.D(k),Jw.h(b));return $CLJS.Se.g($CLJS.p,$CLJS.B($CLJS.ef.g(l,k)))}():f;a=e?["-",$CLJS.p.h(a)].join(""):$CLJS.n(uw.h(b))?["+",$CLJS.p.h(a)].join(""):a;a=a.length<
Cw.h(b)?[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(Cw.h(b)-a.length,Ew.h(b)))),$CLJS.p.h(a)].join(""):a;Fw.l($CLJS.H([a]))}else Gw($CLJS.Kw,new $CLJS.h(null,5,[Cw,Cw.h(b),Dw,1,Bw,0,Ew,Ew.h(b),uw,!0],null),gw(new $CLJS.P(null,1,5,$CLJS.Q,[d],null)));return c};
Ow=function(a){var b=$CLJS.Vd(a,100);a=Au(a,100);var c=0<b?[$CLJS.p.h($CLJS.F(Mw,b))," hundred"].join(""):null,d=$CLJS.p,e=d.h;if(0<a)if(20>a)var f=$CLJS.F(Mw,a);else{f=$CLJS.Vd(a,10);var k=Au(a,10);f=[$CLJS.p.h(0<f?$CLJS.F(Nw,f):null),0<f&&0<k?"-":null,$CLJS.p.h(0<k?$CLJS.F(Mw,k):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
Qw=function(a,b){var c=$CLJS.D(a),d=$CLJS.Cf;--c;var e=$CLJS.A(a);for(a=$CLJS.B(a);;){if(null==a)return[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.ff(", ",d))),$CLJS.td(e)||$CLJS.td(d)?null:", ",$CLJS.p.h(e),!$CLJS.td(e)&&0<c+b?[" ",$CLJS.p.h($CLJS.F(Pw,c+b))].join(""):null].join("");d=$CLJS.td(e)?d:$CLJS.be.g(d,[$CLJS.p.h(e)," ",$CLJS.p.h($CLJS.F(Pw,c+b))].join(""));--c;e=$CLJS.A(a);a=$CLJS.B(a)}};
Rw=function(a,b){b=ow(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=Hw(10,c);c=$CLJS.Cf;for(var e=$CLJS.D(d)-1;;)if($CLJS.td(d)){Fw.l($CLJS.H([$CLJS.Se.g($CLJS.p,c)]));break}else{var f=$CLJS.A(d);c=$CLJS.E.g(0,f)?c:$CLJS.be.g(c,$CLJS.F($CLJS.F(a,e),f-1));--e;d=$CLJS.B(d)}}else Lw(10,new $CLJS.h(null,5,[Cw,0,Ew," ",Jw,",",Iw,3,tw,!0],null),gw(new $CLJS.P(null,1,5,$CLJS.Q,[c],null)));return b};
Jba=function(a,b){a=ow(b);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var c=Ju(b);b=c&127;c&=128;var d=$CLJS.J.g(Iba,b);0<c&&Fw.l($CLJS.H(["Meta-"]));Fw.l($CLJS.H([$CLJS.n(d)?d:32>b?["Control-",$CLJS.p.h(zu(b+64))].join(""):$CLJS.E.g(b,127)?"Control-?":zu(b)]));return a};
Kba=function(a,b){var c=ow(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);a=Sw.h(a);if($CLJS.n($CLJS.E.g?$CLJS.E.g("o",a):$CLJS.E.call(null,"o",a)))jw(!0,"\\o~3,'0o",$CLJS.H([Ju(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g("u",a):$CLJS.E.call(null,"u",a)))jw(!0,"\\u~4,'0x",$CLJS.H([Ju(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g(null,a):$CLJS.E.call(null,null,a)))$CLJS.ac($CLJS.Hu,$CLJS.n($CLJS.E.g?$CLJS.E.g("\b",b):$CLJS.E.call(null,"\b",b))?"\\backspace":$CLJS.n($CLJS.E.g?$CLJS.E.g(" ",b):$CLJS.E.call(null,
" ",b))?"\\space":$CLJS.n($CLJS.E.g?$CLJS.E.g("\t",b):$CLJS.E.call(null,"\t",b))?"\\tab":$CLJS.n($CLJS.E.g?$CLJS.E.g("\n",b):$CLJS.E.call(null,"\n",b))?"\\newline":$CLJS.n($CLJS.E.g?$CLJS.E.g("\f",b):$CLJS.E.call(null,"\f",b))?"\\formfeed":$CLJS.n($CLJS.E.g?$CLJS.E.g("\r",b):$CLJS.E.call(null,"\r",b))?"\\return":$CLJS.n($CLJS.E.g?$CLJS.E.g('"',b):$CLJS.E.call(null,'"',b))?'\\"':$CLJS.n($CLJS.E.g?$CLJS.E.g("\\",b):$CLJS.E.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.h(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.h(a)].join(""));return c};Lba=function(a,b){b=ow(b);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);Fw.l($CLJS.H([a]));return b};Vw=function(a){a=$CLJS.A(a);return $CLJS.E.g(Tw,a)||$CLJS.E.g(Uw,a)};
Zw=function(a,b,c){return $CLJS.hd(Ku(function(d,e){if(Vw(e))return new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=xw(Ww.h(d),e);var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=Mu(f);f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);f=$CLJS.R.j(f,Xw,c);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Se.g(Yw.h(d),new $CLJS.P(null,3,5,$CLJS.Q,[f,e,k],null))],null)},b,a))};
$w=function(a){a=$CLJS.p.h(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.p.h(a.length-1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.h(c-1)],null):0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);a:if(c=$CLJS.D(b),0<c&&$CLJS.E.g($CLJS.F(b,
$CLJS.D(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.E.g($CLJS.F(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.D(c);if(0<d&&$CLJS.E.g($CLJS.F(c,0),"0"))for(var e=0;;){if($CLJS.E.g(e,d)||!$CLJS.E.g($CLJS.F(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.D(b)-$CLJS.D(c);a=0<$CLJS.D(a)&&$CLJS.E.g($CLJS.F(a,0),"+")?a.substring(1):a;return $CLJS.td(c)?new $CLJS.P(null,2,5,$CLJS.Q,["0",0],null):new $CLJS.P(null,2,5,$CLJS.Q,[c,parseInt(a,10)-b],null)};
ax=function(a,b,c,d){if($CLJS.n($CLJS.n(c)?c:d)){var e=$CLJS.D(a);d=$CLJS.n(d)?2>d?2:d:0;$CLJS.n(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.E.g(c,0)?new $CLJS.P(null,4,5,$CLJS.Q,[["0",$CLJS.p.h(a)].join(""),b+1,1,e+1],null):new $CLJS.P(null,4,5,$CLJS.Q,[a,b,c,e],null);c=$CLJS.I(f,0,null);e=$CLJS.I(f,1,null);d=$CLJS.I(f,2,null);f=$CLJS.I(f,3,null);if($CLJS.n(d)){if(0>d)return new $CLJS.P(null,3,5,$CLJS.Q,["0",0,!1],null);if(f>d){b=$CLJS.F(c,d);a=c.substring(0,d);if(Ju(b)>=Ju("5")){a:for(b=
$CLJS.D(a)-1,c=b|0;;){if(0>c){b=$CLJS.Se.j($CLJS.p,"1",$CLJS.df(b+1,"0"));break a}if($CLJS.E.g("9",a.charAt(c)))--c;else{b=$CLJS.Se.v($CLJS.p,a.substring(0,c),zu(Ju(a.charAt(c))+1),$CLJS.df(b-c,"0"));break a}}a=$CLJS.D(b)>a.length;c=$CLJS.Q;a&&(d=$CLJS.D(b)-1,b=b.substring(0,d));return new $CLJS.P(null,3,5,c,[b,e,a],null)}return new $CLJS.P(null,3,5,$CLJS.Q,[a,e,!1],null)}}}return new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null)};
bx=function(a,b,c){var d=0>b?new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(-b-1,"0"))),$CLJS.p.h(a)].join(""),-1],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);a=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);d=$CLJS.D(a);c=$CLJS.n(c)?e+c+1:e+1;c=d<c?[$CLJS.p.h(a),$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.h(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
Mba=function(a,b){return 0>b?[".",$CLJS.p.h(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
gx=function(a,b){var c=cx.h(a),d=dx.h(a);b=ow(b);var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var f=0>e?new $CLJS.P(null,2,5,$CLJS.Q,["-",-e],null):new $CLJS.P(null,2,5,$CLJS.Q,["+",e],null),k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);f=$w(f);var l=$CLJS.I(f,0,null),m=$CLJS.I(f,1,null)+$CLJS.ex.h(a);f=function(){var x=uw.h(a);return $CLJS.n(x)?x:0>e}();var t=$CLJS.Va(d)&&$CLJS.D(l)-1<=m,u=ax(l,m,d,$CLJS.n(c)?c-($CLJS.n(f)?1:0):null);l=$CLJS.I(u,0,null);m=$CLJS.I(u,1,null);u=$CLJS.I(u,2,null);l=bx(l,$CLJS.n(u)?
m+1:m,d);d=$CLJS.n($CLJS.n(c)?$CLJS.n(d)?1<=d&&$CLJS.E.g(l.charAt(0),"0")&&$CLJS.E.g(l.charAt(1),".")&&l.length>c-($CLJS.n(f)?1:0):d:c)?l.substring(1):l;m=$CLJS.E.g($CLJS.A(d),".");if($CLJS.n(c)){l=d.length;l=$CLJS.n(f)?l+1:l;m=m&&!(l>=c);t=t&&!(l>=c);var v=m||t?l+1:l;$CLJS.n(function(){var x=v>c;return x?fx.h(a):x}())?Fw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(c,fx.h(a)))])):Fw.l($CLJS.H([[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(c-v,Ew.h(a)))),$CLJS.p.h($CLJS.n(f)?k:null),m?"0":null,d,t?"0":null].join("")]))}else Fw.l($CLJS.H([[$CLJS.p.h($CLJS.n(f)?
k:null),m?"0":null,d,t?"0":null].join("")]));return b};
jx=function(a,b){b=ow(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$w(0>c?-c:c);$CLJS.I(d,0,null);for($CLJS.I(d,1,null);;){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=cx.h(a),l=dx.h(a),m=hx.h(a),t=$CLJS.ex.h(a),u=function(){var K=ix.h(a);return $CLJS.n(K)?K:"E"}();d=function(){var K=uw.h(a);return $CLJS.n(K)?K:0>c}();var v=0>=t,x=f-(t-1),z=$CLJS.p.h(Math.abs(x));u=[$CLJS.p.h(u),0>x?"-":"+",$CLJS.p.h($CLJS.n(m)?$CLJS.Se.g($CLJS.p,$CLJS.df(m-z.length,"0")):null),z].join("");var C=u.length;
x=$CLJS.D(e);e=[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(-t,"0"))),$CLJS.p.h(e),$CLJS.p.h($CLJS.n(l)?$CLJS.Se.g($CLJS.p,$CLJS.df(l-(x-1)-(0>t?-t:0),"0")):null)].join("");x=$CLJS.n(k)?k-C:null;e=ax(e,0,$CLJS.E.g(t,0)?l-1:0<t?l:0>t?l-1:null,$CLJS.n(x)?x-($CLJS.n(d)?1:0):null);x=$CLJS.I(e,0,null);$CLJS.I(e,1,null);z=$CLJS.I(e,2,null);e=Mba(x,t);l=$CLJS.E.g(t,$CLJS.D(x))&&null==l;if($CLJS.Va(z)){if($CLJS.n(k)){f=e.length+C;f=$CLJS.n(d)?f+1:f;var G=(v=v&&!$CLJS.E.g(f,k))?f+1:f;f=l&&G<k;$CLJS.n(function(){var K=
G>k;K||(K=m,K=$CLJS.n(K)?C-2>m:K);return $CLJS.n(K)?fx.h(a):K}())?Fw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(k,fx.h(a)))])):Fw.l($CLJS.H([[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(k-G-(f?1:0),Ew.h(a)))),$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else Fw.l($CLJS.H([[$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.P(null,2,5,$CLJS.Q,[x,f+1],null)}return b};
Nba=function(a,b,c){var d=ow(b),e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=$w(0>e?-e:e);var f=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var k=cx.h(a),l=dx.h(a),m=hx.h(a);d=$CLJS.E.g(e,0)?0:d+1;e=$CLJS.n(m)?m+2:4;k=$CLJS.n(k)?k-e:null;$CLJS.n(l)?f=l:(f=$CLJS.D(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=gx(new $CLJS.h(null,6,[cx,k,dx,d,$CLJS.ex,0,fx,fx.h(a),Ew,Ew.h(a),uw,uw.h(a)],null),b,c),Fw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(e," "))])),a):jx(a,b,c)};
Oba=function(a,b){b=ow(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$w(Math.abs(c)),e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=dx.h(a),l=$CLJS.qk.h(a);d=cx.h(a);var m=function(){var u=uw.h(a);return $CLJS.n(u)?u:0>c}(),t=ax(e,f,k,null);e=$CLJS.I(t,0,null);f=$CLJS.I(t,1,null);t=$CLJS.I(t,2,null);k=bx(e,$CLJS.n(t)?f+1:f,k);l=[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(l-k.indexOf("."),"0"))),k].join("");k=l.length+($CLJS.n(m)?1:0);Fw.l($CLJS.H([[$CLJS.n(function(){var u=tw.h(a);return $CLJS.n(u)?
m:u}())?0>c?"-":"+":null,$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(d-k,Ew.h(a)))),$CLJS.n(function(){var u=$CLJS.Va(tw.h(a));return u?m:u}())?0>c?"-":"+":null,l].join("")]));return b};Pba=function(a,b){var c=kx.h(a);c=$CLJS.n(c)?new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null):ow(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.lx.h(a);b=0>b||b>=$CLJS.D(d)?$CLJS.A($CLJS.Pt.h(a)):$CLJS.F(d,b);return $CLJS.n(b)?Zw(b,c,Xw.h(a)):c};
Qba=function(a,b){var c=ow(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.lx.h(a);b=$CLJS.n(b)?$CLJS.hd(d):$CLJS.A(d);return $CLJS.n(b)?Zw(b,c,Xw.h(a)):c};Rba=function(a,b){var c=ow(b),d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.lx.h(a);e=$CLJS.n(d)?$CLJS.A(e):null;return $CLJS.n(d)?$CLJS.n(e)?Zw(e,b,Xw.h(a)):b:c};
Sba=function(a,b){var c=mx.h(a),d=$CLJS.A($CLJS.lx.h(a));d=$CLJS.td(d)?pw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=ow(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=0;e=gw(e);for(var k=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(yv.h(e),k)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");k=$CLJS.td($CLJS.Pi.h(e))&&($CLJS.Va(tw.h(nx.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Zw(b,e,Xw.h(a));if($CLJS.E.g(Tw,$CLJS.A(k)))return d;
f+=1;var l=yv.h(e);e=k;k=l}};Tba=function(a,b){var c=mx.h(a),d=$CLJS.A($CLJS.lx.h(a));d=$CLJS.td(d)?pw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=ow(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);for(var f=0;;){var k=$CLJS.td(e)&&($CLJS.Va(tw.h(nx.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Zw(b,gw($CLJS.A(e)),gw($CLJS.B(e)));if($CLJS.E.g(Uw,$CLJS.A(k)))return d;f+=1;e=$CLJS.B(e)}};
Uba=function(a,b){var c=mx.h(a),d=$CLJS.A($CLJS.lx.h(a)),e=$CLJS.td(d)?pw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;e=$CLJS.I(e,1,null);for(var f=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(yv.h(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.td($CLJS.Pi.h(e))&&($CLJS.Va(tw.h(nx.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=Zw(b,e,Xw.h(a));if($CLJS.E.g(Tw,$CLJS.A(f)))return $CLJS.hd(f);d+=1;var k=yv.h(e);e=f;f=k}};
Vba=function(a,b){var c=mx.h(a),d=$CLJS.A($CLJS.lx.h(a)),e=$CLJS.td(d)?pw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;for(e=$CLJS.I(e,1,null);;){var f=$CLJS.td($CLJS.Pi.h(e))&&($CLJS.Va(tw.h(nx.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=$CLJS.Pi.h(e);f=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(f),new mw(nw.h(e),$CLJS.B(f),yv.h(e)+1,null,null,null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=Zw(b,gw(e),f);
if($CLJS.E.g(Uw,$CLJS.A(e)))return f;e=f;d+=1}};Xba=function(a,b){if($CLJS.n(tw.h(nx.h(a)))){var c=$CLJS.lx.h(a),d=$CLJS.D(c),e=1<d?$CLJS.Yj.h(Ww.h($CLJS.A($CLJS.A(c)))):$CLJS.n(tw.h(a))?"(":null,f=$CLJS.F(c,1<d?1:0);c=2<d?$CLJS.Yj.h(Ww.h($CLJS.A($CLJS.F(c,2)))):$CLJS.n(tw.h(a))?")":null;d=ow(b);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{var k=Vv,l=Qv;Vv+=1;Qv=0;try{Kv(e,c),Zw(f,gw(b),Xw.h(a)),Ov()}finally{Qv=l,Vv=k}}a=d}else a=Wba(a,b);return a};
ox=function(a,b,c){for(var d=$CLJS.Cf;;){if($CLJS.td(a))return new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);var e=$CLJS.A(a);a:{var f=new $CLJS.Ea,k=$CLJS.Hu;$CLJS.Hu=new $CLJS.sc(f);try{var l=new $CLJS.P(null,2,5,$CLJS.Q,[Zw(e,b,c),$CLJS.p.h(f)],null);break a}finally{$CLJS.Hu=k}l=void 0}b=$CLJS.I(l,0,null);e=$CLJS.I(l,1,null);if($CLJS.E.g(Tw,$CLJS.A(b)))return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.hd(b)],null);a=$CLJS.B(a);d=$CLJS.be.g(d,e)}};
Wba=function(a,b){var c=function(){var V=$CLJS.Pt.h(a);return $CLJS.n(V)?ox(V,b,Xw.h(a)):null}(),d=$CLJS.I(c,0,null);d=$CLJS.I(d,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.n(c)?c:b;c=function(){var V=px.h(a);return $CLJS.n(V)?xw(V,e):null}();var f=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var k=$CLJS.n(c)?c:e;c=function(){var V=$CLJS.A(qx.h(f));return $CLJS.n(V)?V:0}();var l=function(){var V=$CLJS.A(rx.h(f));return $CLJS.n(V)?V:$CLJS.Ou($CLJS.Hu,$CLJS.ik)}(),m=$CLJS.lx.h(a);k=ox(m,k,Xw.h(a));var t=$CLJS.I(k,
0,null);k=$CLJS.I(k,1,null);var u=function(){var V=$CLJS.D(t)-1+($CLJS.n(tw.h(a))?1:0)+($CLJS.n(uw.h(a))?1:0);return 1>V?1:V}();m=$CLJS.Qd($CLJS.Ik,$CLJS.hf.g($CLJS.D,t));var v=Cw.h(a),x=Bw.h(a),z=Dw.h(a),C=m+u*x;v=C<=v?v:v+z*(1+$CLJS.Vd(C-v-1,z));var G=v-m;m=function(){var V=$CLJS.Vd(G,u);return x>V?x:V}();z=G-m*u;m=$CLJS.Se.g($CLJS.p,$CLJS.df(m,Ew.h(a)));$CLJS.n($CLJS.n(d)?$CLJS.Ou(Ru.h($CLJS.q($CLJS.q($CLJS.Hu))),$CLJS.Qu)+c+v>l:d)&&Fw.l($CLJS.H([d]));c=z;for(var K=t,S=function(){var V=tw.h(a);
return $CLJS.n(V)?V:$CLJS.E.g($CLJS.D(K),1)&&$CLJS.Va(uw.h(a))}();;)if($CLJS.y(K))Fw.l($CLJS.H([[$CLJS.p.h($CLJS.Va(S)?$CLJS.A(K):null),$CLJS.p.h($CLJS.n(function(){var V=S;return $CLJS.n(V)?V:(V=$CLJS.B(K))?V:uw.h(a)}())?m:null),$CLJS.p.h(0<c?Ew.h(a):null)].join("")])),--c,K=d=$CLJS.n(S)?K:$CLJS.B(K),S=!1;else break;return k};sx=function(a,b){this.Ca=a;this.di=b;this.C=1074135040;this.I=0};Yba=function(a){return new sx(a,$CLJS.N)};tx=function(a,b){this.Ca=a;this.ei=b;this.C=1074135040;this.I=0};
Zba=function(a){return new tx(a,$CLJS.N)};
$ba=function(a,b){var c=$CLJS.A(a);return $CLJS.Se.g($CLJS.p,$CLJS.A(Lu(function(d){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.n(e)?e.index+1:e;return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[[d.substring(0,e),$CLJS.F(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,null],null)},$CLJS.n($CLJS.n(b)?$CLJS.n(c)?uu(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
ux=function(a,b,c){this.Ca=a;this.Jd=b;this.fi=c;this.C=1074135040;this.I=0};aca=function(a){return new ux(a,$CLJS.Xe(!0),$CLJS.N)};vx=function(a,b,c){this.Ca=a;this.vd=b;this.gi=c;this.C=1074135040;this.I=0};bca=function(a){return new vx(a,$CLJS.Xe(!1),$CLJS.N)};cca=function(a,b){var c=$CLJS.n(tw.h(a))?$CLJS.cw:bw;ew(c,$CLJS.qk.h(a));return b};dca=function(a,b){a=$CLJS.n(tw.h(a))?$CLJS.n(uw.h(a))?Xv:Zv:$CLJS.n(uw.h(a))?Yv:$v;aw(a);return b};
fca=function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null);a=new RegExp(eca.source,"g");var e=a.exec(b);return $CLJS.n(e)?(d=$CLJS.A(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.E.g(",",$CLJS.F(b,0))?new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b.substring(1),a+1,!0],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b,a,!1],null)],null)):$CLJS.n(d)?lw("Badly formed parameters in format directive",
c):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null)],null)};gca=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E.g(b.length,0)?null:$CLJS.E.g(b.length,1)&&$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,2,["V",null,"v",null],null),null),$CLJS.F(b,0))?vw:$CLJS.E.g(b.length,1)&&$CLJS.E.g("#",$CLJS.F(b,0))?ww:$CLJS.E.g(b.length,2)&&$CLJS.E.g("'",$CLJS.F(b,0))?$CLJS.F(b,1):parseInt(b,10),a],null)};
ica=function(a,b){return Lu(function(c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null);var f=$CLJS.J.g(hca,$CLJS.A(d));return $CLJS.n(f)?$CLJS.Hd(c,f)?lw(['Flag "',$CLJS.p.h($CLJS.A(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[d.substring(1),e+1,$CLJS.R.j(c,f,new $CLJS.P(null,2,5,$CLJS.Q,[!0,e],null))],
null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null)},new $CLJS.P(null,3,5,$CLJS.Q,[a,b,$CLJS.N],null))};
jca=function(a,b){var c=wx.h(a);$CLJS.n(function(){var d=$CLJS.Va(uw.h(c));return d?uw.h(b):d}())&&lw(['"@" is an illegal flag for format directive "',$CLJS.p.h(xx.h(a)),'"'].join(""),$CLJS.F(uw.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(tw.h(c));return d?tw.h(b):d}())&&lw(['":" is an illegal flag for format directive "',$CLJS.p.h(xx.h(a)),'"'].join(""),$CLJS.F(tw.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(yx.h(c));return d?(d=uw.h(b),$CLJS.n(d)?tw.h(b):d):d}())&&lw(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.h(xx.h(a)),'"'].join(""),function(){var d=$CLJS.F(tw.h(b),1),e=$CLJS.F(uw.h(b),1);return d<e?d:e}())};
lca=function(a,b,c,d){jca(a,c);$CLJS.D(b)>$CLJS.D(Ww.h(a))&&lw(jw(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.H([xx.h(a),$CLJS.D(b),$CLJS.D(Ww.h(a))])),$CLJS.hd($CLJS.A(b)));$CLJS.mh($CLJS.hf.j(function(e,f){var k=$CLJS.A(e);return null==k||$CLJS.Hd(kca,k)||$CLJS.E.g($CLJS.hd($CLJS.hd(f)),$CLJS.ab(k))?null:lw(["Parameter ",$CLJS.gh($CLJS.A(f)),' has bad type in directive "',$CLJS.p.h(xx.h(a)),'": ',$CLJS.p.h($CLJS.ab(k))].join(""),
$CLJS.hd(e))},b,Ww.h(a)));return $CLJS.fl.l($CLJS.H([$CLJS.eg.g($CLJS.N,$CLJS.ce(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.I(x,0,null);u.add(new $CLJS.P(null,2,5,$CLJS.Q,[z,new $CLJS.P(null,2,5,$CLJS.Q,[x,d],null)],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):
$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.I(m,0,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,new $CLJS.P(null,2,5,$CLJS.Q,[m,d],null)],null),k($CLJS.Lc(l)))}return null}},null,null)}(Ww.h(a))}())),$CLJS.fb(function(e,f){return $CLJS.Se.j($CLJS.R,e,f)},$CLJS.N,$CLJS.lf(function(e){return $CLJS.A($CLJS.F(e,1))},$CLJS.Cu($CLJS.Xg(Ww.h(a)),b))),c]))};
nca=function(a,b){b=Lu(fca,new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null));a=$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);b=ica(b,c);$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);c=$CLJS.A(d);var f=$CLJS.J.g(mca,c.toUpperCase()),k=$CLJS.n(f)?lca(f,$CLJS.hf.g(gca,a),b,e):null;$CLJS.Va(c)&&lw("Format string ended in the middle of a directive",e);$CLJS.Va(f)&&lw(['Directive "',$CLJS.p.h(c),'" is undefined'].join(""),
e);return new $CLJS.P(null,2,5,$CLJS.Q,[new sw(function(){var l=zx.h(f);return l.g?l.g(k,e):l.call(null,k,e)}(),f,k,e,null,null,null),function(){var l=d.substring(1),m=e+1;if($CLJS.E.g("\n",xx.h(f))&&$CLJS.Va(tw.h(k)))a:{var t=new $CLJS.P(null,2,5,$CLJS.Q,[" ","\t"],null);t=$CLJS.ud(t)?$CLJS.fh(t):$CLJS.ch([t]);for(var u=0;;){var v;(v=$CLJS.E.g(u,$CLJS.D(l)))||(v=$CLJS.F(l,u),v=t.h?t.h(v):t.call(null,v),v=$CLJS.Va(v));if(v){t=u;break a}u+=1}}else t=0;return new $CLJS.P(null,2,5,$CLJS.Q,[l.substring(t),
m+t],null)}()],null)};Ax=function(a,b){return new sw(function(c,d){Fw.l($CLJS.H([a]));return d},null,new $CLJS.h(null,1,[$CLJS.Yj,a],null),b,null,null,null)};Dx=function(a,b){var c=oca(Bx.h(Cx.h(a)),$CLJS.Qt.h(a),b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new sw(Yw.h(a),Cx.h(a),$CLJS.fl.l($CLJS.H([Ww.h(a),xba(b,$CLJS.Qt.h(a))])),$CLJS.Qt.h(a),null,null,null),c],null)};
pca=function(a,b,c){return Lu(function(d){if($CLJS.td(d))return lw("No closing bracket found.",b);var e=$CLJS.A(d);d=$CLJS.B(d);if($CLJS.n($CLJS.Ex.h(Bx.h(Cx.h(e)))))e=Dx(e,d);else if($CLJS.E.g($CLJS.Ex.h(a),xx.h(Cx.h(e))))e=new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[Fx,Ww.h(e),null,d],null)],null);else{var f=$CLJS.Rt.h(Bx.h(Cx.h(e)));f=$CLJS.n(f)?tw.h(Ww.h(e)):f;e=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pt,null,Ww.h(e),d],null)],null):
$CLJS.n($CLJS.Rt.h(Bx.h(Cx.h(e))))?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Rt,null,null,d],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[e,d],null)}return e},c)};
oca=function(a,b,c){return $CLJS.hd(Lu(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);var k=pca(a,b,d);d=$CLJS.I(k,0,null);var l=$CLJS.I(k,1,null);k=$CLJS.I(l,0,null);var m=$CLJS.I(l,1,null),t=$CLJS.I(l,2,null);l=$CLJS.I(l,3,null);return $CLJS.E.g(k,Fx)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gx.l($CLJS.gf,$CLJS.H([e,$CLJS.Ee([$CLJS.n(f)?$CLJS.Pt:$CLJS.lx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null),nx,m])])),l],null)],null):$CLJS.E.g(k,$CLJS.Pt)?
$CLJS.n($CLJS.Pt.h(e))?lw('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Va($CLJS.Pt.h(a))?lw('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.E.g(Hx,$CLJS.Pt.h(a))&&$CLJS.y($CLJS.lx.h(e))?lw('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.E.g(Hx,$CLJS.Pt.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,2,[$CLJS.Pt,new $CLJS.P(null,1,5,
$CLJS.Q,[d],null),px,t],null)])),!1,l],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.lx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!0,l],null)],null):$CLJS.E.g(k,$CLJS.Rt)?$CLJS.n(f)?lw('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Va(Ix.h(a))?lw('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.Gx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.lx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.h(null,1,[$CLJS.lx,$CLJS.Cf],null),!1,c],null)))};qca=function(a){return $CLJS.A(Lu(function(b){var c=$CLJS.A(b);b=$CLJS.B(b);var d=Bx.h(Cx.h(c));return $CLJS.n($CLJS.Ex.h(d))?Dx(c,b):new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)},a))};
fw=function(a){var b=kw;kw=a;try{return qca($CLJS.A(Lu(function(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.P(null,2,5,$CLJS.Q,[Ax(d,c),new $CLJS.P(null,2,5,$CLJS.Q,["",c+d.length],null)],null):0===e?nca(d.substring(1),c+1):new $CLJS.P(null,2,5,$CLJS.Q,[Ax(d.substring(0,e),c),new $CLJS.P(null,2,5,$CLJS.Q,[d.substring(e),e+c],null)],null)},new $CLJS.P(null,2,5,$CLJS.Q,[a,0],null))))}finally{kw=
b}};iw=function(a,b){Ku(function(c,d){if(Vw(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=xw(Ww.h(c),d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=Mu(e);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=$CLJS.R.j(e,Xw,d);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Se.g(Yw.h(c),new $CLJS.P(null,3,5,$CLJS.Q,[e,d,f],null))],null)},b,a);return null};
Kx=function(a){var b=$CLJS.A(a);b=Jx.h?Jx.h(b):Jx.call(null,b);return $CLJS.n($CLJS.n(b)?$CLJS.E.g(2,$CLJS.D(a)):b)?($CLJS.ac($CLJS.Hu,b),$CLJS.Tv($CLJS.hd(a)),!0):null};
Lx=function(a){if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{var b=Vv,c=Qv;Vv+=1;Qv=0;try{Kv("[","]");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if(e&&($CLJS.Tv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Hu," ");aw($v);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Hu,"...");break}Ov()}finally{Qv=c,Vv=b}}return null};
Mx=function(a){var b=$CLJS.yd(a)?null:function(){var m=new $CLJS.Gc(function(){return $CLJS.Hh},rca,$CLJS.Ig([$CLJS.vk,$CLJS.Wi,$CLJS.T,$CLJS.ok,$CLJS.vi,$CLJS.Ti,$CLJS.nj,$CLJS.xk,$CLJS.ui,$CLJS.jk,$CLJS.nk],[!0,$CLJS.mi,sca,"cljs/core.cljs",15,1,10543,10543,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ej],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.n($CLJS.Hh)?$CLJS.Hh.H:null]));return m.h?m.h(a):m.call(null,a)}(),c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.n(b)?
b:a,e=$CLJS.n(c)?["#:",$CLJS.p.h(c),"{"].join(""):"{";if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{c=Vv;b=Qv;Vv+=1;Qv=0;try{Kv(e,"}");e=0;for(var f=$CLJS.y(d);;){if($CLJS.Va($CLJS.Na)||e<$CLJS.Na){if(f){if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{d=Vv;var k=Qv;Vv+=1;Qv=0;try{Kv(null,null),$CLJS.Tv($CLJS.A($CLJS.A(f))),$CLJS.ac($CLJS.Hu," "),aw($v),Qv=0,$CLJS.Tv($CLJS.A($CLJS.B($CLJS.A(f)))),Ov()}finally{Qv=k,Vv=d}}if($CLJS.B(f)){$CLJS.ac($CLJS.Hu,", ");aw($v);d=e+1;var l=$CLJS.B(f);e=d;f=l;continue}}}else $CLJS.ac($CLJS.Hu,
"...");break}Ov()}finally{Qv=b,Vv=c}}return null};Nx=function(a){return $CLJS.ac($CLJS.Hu,$CLJS.Mh.l($CLJS.H([a])))};Qx=function(a){return a instanceof $CLJS.Rf?Ox:(null!=a?a.C&32768||$CLJS.Bc===a.ag||(a.C?0:$CLJS.$a($CLJS.vu,a)):$CLJS.$a($CLJS.vu,a))?Px:a instanceof $CLJS.r?$CLJS.Hi:$CLJS.Dd(a)?$CLJS.Cj:$CLJS.xd(a)?$CLJS.hk:$CLJS.zd(a)?$CLJS.Jj:$CLJS.vd(a)?$CLJS.$i:null==a?null:$CLJS.di};
Rx=function(a){return $CLJS.zd(a)?new $CLJS.P(null,2,5,$CLJS.Q,["[","]"],null):new $CLJS.P(null,2,5,$CLJS.Q,["(",")"],null)};
tca=function(a){if($CLJS.wd(a)){var b=Rx(a),c=$CLJS.I(b,0,null),d=$CLJS.I(b,1,null),e=$CLJS.y(a),f=$CLJS.A(e),k=$CLJS.B(e);if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{var l=Vv,m=Qv,t=Vv+1;Vv=t;Qv=0;try{Kv(c,d);(function(){var Ma=Sx("~w~:i");return function(){function zb(Ya){var eb=null;if(0<arguments.length){eb=0;for(var Sa=Array(arguments.length-0);eb<Sa.length;)Sa[eb]=arguments[eb+0],++eb;eb=new $CLJS.w(Sa,0,null)}return Oa.call(this,eb)}function Oa(Ya){Ya=gw(Ya);return iw(Ma,Ya)}zb.A=0;zb.B=function(Ya){Ya=
$CLJS.y(Ya);return Oa(Ya)};zb.l=Oa;return zb}()})()(f);for(var u=k;;)if($CLJS.y(u)){(function(){var Ma=Sx(" ");return function(zb,Oa,Ya){return function(){function eb(mb){var Ia=null;if(0<arguments.length){Ia=0;for(var bc=Array(arguments.length-0);Ia<bc.length;)bc[Ia]=arguments[Ia+0],++Ia;Ia=new $CLJS.w(bc,0,null)}return Sa.call(this,Ia)}function Sa(mb){mb=gw(mb);return iw(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u," ",Ma,l,m,t,0,b,c,d,a,e,f,k,f,k)})()();
var v=$CLJS.A(u);if($CLJS.wd(v)){var x=Rx(v),z=$CLJS.I(x,0,null),C=$CLJS.I(x,1,null);if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{var G=Vv,K=Qv,S=Vv+1;Vv=S;Qv=0;try{Kv(z,C);if($CLJS.E.g($CLJS.D(v),3)&&$CLJS.hd(v)instanceof $CLJS.M){var V=v,Z=$CLJS.I(V,0,null),ha=$CLJS.I(V,1,null),ra=$CLJS.I(V,2,null);(function(){var Ma=Sx("~w ~w ");return function(zb,Oa,Ya){return function(){function eb(mb){var Ia=null;if(0<arguments.length){Ia=0;for(var bc=Array(arguments.length-0);Ia<bc.length;)bc[Ia]=arguments[Ia+
0],++Ia;Ia=new $CLJS.w(bc,0,null)}return Sa.call(this,Ia)}function Sa(mb){mb=gw(mb);return iw(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~w ~w ",Ma,V,Z,ha,ra,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)})()(Z,ha);$CLJS.wd(ra)?function(){var Ma=$CLJS.zd(ra)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",zb="string"===typeof Ma?Sx(Ma):Ma;return function(Oa,Ya,eb){return function(){function Sa(Ia){var bc=null;if(0<arguments.length){bc=0;for(var Zd=
Array(arguments.length-0);bc<Zd.length;)Zd[bc]=arguments[bc+0],++bc;bc=new $CLJS.w(Zd,0,null)}return mb.call(this,bc)}function mb(Ia){Ia=gw(Ia);return iw(eb,Ia)}Sa.A=0;Sa.B=function(Ia){Ia=$CLJS.y(Ia);return mb(Ia)};Sa.l=mb;return Sa}()}(u,Ma,zb,V,Z,ha,ra,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()(ra):$CLJS.Tv(ra)}else $CLJS.Se.g(function(){var Ma=Sx("~w ~:i~@{~w~^ ~:_~}");return function(zb,Oa,Ya){return function(){function eb(mb){var Ia=null;if(0<arguments.length){Ia=0;for(var bc=Array(arguments.length-
0);Ia<bc.length;)bc[Ia]=arguments[Ia+0],++Ia;Ia=new $CLJS.w(bc,0,null)}return Sa.call(this,Ia)}function Sa(mb){mb=gw(mb);return iw(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~w ~:i~@{~w~^ ~:_~}",Ma,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}(),v);Ov()}finally{Qv=K,Vv=G}}$CLJS.B(u)&&function(){var Ma=Sx("~_");return function(zb,Oa,Ya){return function(){function eb(mb){var Ia=null;if(0<arguments.length){Ia=0;for(var bc=Array(arguments.length-0);Ia<bc.length;)bc[Ia]=
arguments[Ia+0],++Ia;Ia=new $CLJS.w(bc,0,null)}return Sa.call(this,Ia)}function Sa(mb){mb=gw(mb);return iw(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~_",Ma,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()()}else $CLJS.Tv(v),$CLJS.B(u)&&function(){var Ma=Sx("~:_");return function(zb,Oa,Ya){return function(){function eb(mb){var Ia=null;if(0<arguments.length){Ia=0;for(var bc=Array(arguments.length-0);Ia<bc.length;)bc[Ia]=arguments[Ia+0],++Ia;Ia=new $CLJS.w(bc,0,null)}return Sa.call(this,
Ia)}function Sa(mb){mb=gw(mb);return iw(Ya,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~:_",Ma,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()();u=$CLJS.B(u)}else break;Ov()}finally{Qv=m,Vv=l}}}else $CLJS.Tv(a)};
uca=function(a,b){$CLJS.y(a)&&($CLJS.n(b)?function(){var c=Sx(" ~_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=gw(f);return iw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()():function(){var c=Sx(" ~@_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<
l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=gw(f);return iw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()(),function(){var c=Sx("~{~w~^ ~_~}");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=gw(f);return iw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;
return d}()}()(a))};vca=function(a){$CLJS.y(a)&&function(){var b=Sx(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var k=Array(arguments.length-0);f<k.length;)k[f]=arguments[f+0],++f;f=new $CLJS.w(k,0,null)}return d.call(this,f)}function d(e){e=gw(e);return iw(b,e)}c.A=0;c.B=function(e){e=$CLJS.y(e);return d(e)};c.l=d;return c}()}()(a)};
Ux=function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d="string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=$CLJS.xd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{var f=
Vv,k=Qv;Vv+=1;Qv=0;try{Kv("(",")"),function(){var l=Sx("~w ~1I~@_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=gw(u);return iw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(a,b),$CLJS.n(c)&&function(){var l=Sx(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=gw(u);return iw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(c),$CLJS.n(d)&&function(){var l=Sx(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=gw(u);return iw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};
m.l=t;return m}()}()(d),$CLJS.zd($CLJS.A(e))?uca(e,$CLJS.n(c)?c:d):vca(e),Ov()}finally{Qv=k,Vv=f}}return null}return Tx(a)};
wca=function(a){if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{var b=Vv,c=Qv;Vv+=1;Qv=0;try{Kv("[","]");for(var d=0;;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if($CLJS.y(a)){if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{var e=Vv,f=Qv;Vv+=1;Qv=0;try{Kv(null,null),$CLJS.Tv($CLJS.A(a)),$CLJS.B(a)&&($CLJS.ac($CLJS.Hu," "),aw(Yv),$CLJS.Tv($CLJS.hd(a))),Ov()}finally{Qv=f,Vv=e}}if($CLJS.B($CLJS.Lc(a))){$CLJS.ac($CLJS.Hu," ");aw($v);e=d+1;var k=$CLJS.B($CLJS.Lc(a));d=e;a=k;continue}}}else $CLJS.ac($CLJS.Hu,"...");
break}Ov()}finally{Qv=c,Vv=b}}};
Vx=function(a){var b=$CLJS.A(a);if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{var c=Vv,d=Qv;Vv+=1;Qv=0;try{Kv("(",")"),$CLJS.B(a)&&$CLJS.zd($CLJS.hd(a))?(function(){var e=Sx("~w ~1I~@_");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=gw(l);return iw(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()(b),wca($CLJS.hd(a)),function(){var e=
Sx(" ~_~{~w~^ ~_~}");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=gw(l);return iw(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()($CLJS.B($CLJS.Lc(a)))):Tx(a),Ov()}finally{Qv=d,Vv=c}}return null};
Tx=function(a){if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{var b=Vv,c=Qv;Vv+=1;Qv=0;try{Kv("(",")");ew(bw,1);for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if(e&&($CLJS.Tv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Hu," ");aw($v);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Hu,"...");break}Ov()}finally{Qv=c,Vv=b}}return null};$CLJS.ne.prototype.Lc=$CLJS.va(2,function(){return $CLJS.Va(this.Rb)});$CLJS.cf.prototype.Lc=$CLJS.va(1,function(){return!1});
$CLJS.Jh.prototype.Lc=$CLJS.va(0,function(){return $CLJS.Va(this.f)});$CLJS.Hu=null;Wx=function Wx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Wx.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};Wx.l=function(a){a=$CLJS.y(a);for(var b=$CLJS.ec($CLJS.gg);;)if(a){var c=$CLJS.B(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.h($CLJS.A(a))].join(""));var d=$CLJS.B(c);b=$CLJS.hg.j(b,$CLJS.A(a),$CLJS.A(c));a=d}else return $CLJS.gc(b)};
Wx.A=0;Wx.B=function(a){return this.l($CLJS.y(a))};$CLJS.Gx=function Gx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Gx.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$CLJS.Gx.l=function(a,b){if($CLJS.n($CLJS.Re($CLJS.Td,b))){var c=function(d,e){var f=$CLJS.Eb(e),k=$CLJS.Fb(e);if($CLJS.Hd(d,f)){e=$CLJS.R.j;var l=$CLJS.J.g(d,f);k=a.g?a.g(l,k):a.call(null,l,k);d=e.call($CLJS.R,d,f,k)}else d=$CLJS.R.j(d,f,k);return d};return $CLJS.Qd(function(d,e){return $CLJS.fb(c,$CLJS.n(d)?d:$CLJS.N,$CLJS.y(e))},b)}return null};$CLJS.Gx.A=1;$CLJS.Gx.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.Kw=function Kw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Kw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};$CLJS.Kw.l=function(a){return $CLJS.Eh(a,$CLJS.R.j($CLJS.Pa(),$CLJS.Ga,!1))};$CLJS.Kw.A=0;$CLJS.Kw.B=function(a){return this.l($CLJS.y(a))};cv=new $CLJS.M(null,"trailing-white-space","trailing-white-space",1496006996);Xx=new $CLJS.M(null,"relative-to","relative-to",-470100051);qv=new $CLJS.M(null,"intra-block-nl","intra-block-nl",1808826875);
ix=new $CLJS.M(null,"exponentchar","exponentchar",1986664222);xca=new $CLJS.r(null,"when-let","when-let",-1383043480,null);Yv=new $CLJS.M(null,"miser","miser",-556060186);$CLJS.cw=new $CLJS.M(null,"current","current",-1088038603);yca=new $CLJS.r(null,"-\x3e","-\x3e",-2139605430,null);zca=new $CLJS.r(null,"..","..",-300507420,null);fv=new $CLJS.M(null,"end-pos","end-pos",-1643883926);ov=new $CLJS.M(null,"per-line-prefix","per-line-prefix",846941813);Ox=new $CLJS.M(null,"queue","queue",1455835879);
Aca=new $CLJS.r(null,"if-let","if-let",1803593690,null);Mv=new $CLJS.M(null,"end","end",-268185958);ww=new $CLJS.M(null,"remaining-arg-count","remaining-arg-count",-1216589335);wv=new $CLJS.M(null,"logical-blocks","logical-blocks",-1466339776);Bca=new $CLJS.r(null,"defmacro","defmacro",2054157304,null);Cca=new $CLJS.r(null,"when-first","when-first",821699168,null);Jv=new $CLJS.M(null,"start-block-t","start-block-t",-373430594);Dca=new $CLJS.r(null,"binding","binding",-2114503176,null);
Bx=new $CLJS.M(null,"bracket-info","bracket-info",-1600092774);Av=new $CLJS.M(null,"writing","writing",-1486865108);vw=new $CLJS.M(null,"parameter-from-args","parameter-from-args",-758446196);Hv=new $CLJS.M(null,"logical-block-callback","logical-block-callback",1612691194);kx=new $CLJS.M(null,"selector","selector",762528866);Eca=new $CLJS.r(null,"struct","struct",325972931,null);pv=new $CLJS.M(null,"indent","indent",-148200125);Fca=new $CLJS.r(null,"loop","loop",1244978678,null);
rx=new $CLJS.M(null,"max-columns","max-columns",1742323262);yx=new $CLJS.M(null,"both","both",-393648840);Yx=new $CLJS.M(null,"colnum","colnum",2023796854);Gca=new $CLJS.r(null,"doseq","doseq",221164135,null);$CLJS.Zx=new $CLJS.M(null,"length","length",588987862);Sw=new $CLJS.M(null,"char-format","char-format",-1016499218);$x=new $CLJS.M(null,"prefix","prefix",-265908465);Uw=new $CLJS.M(null,"colon-up-arrow","colon-up-arrow",244853007);Lv=new $CLJS.M(null,"suffix","suffix",367373057);
uw=new $CLJS.M(null,"at","at",1476951349);bv=new $CLJS.M(null,"nl-t","nl-t",-1608382114);xx=new $CLJS.M(null,"directive","directive",793559132);Eba=new $CLJS.M(null,"buffer-level","buffer-level",928864731);Xv=new $CLJS.M(null,"mandatory","mandatory",542802336);Fv=new $CLJS.M(null,"pretty-writer","pretty-writer",-1222834267);iv=new $CLJS.M(null,"done-nl","done-nl",-381024340);Hca=new $CLJS.r(null,"condp","condp",1054325175,null);nw=new $CLJS.M(null,"seq","seq",-1817803783);
Ica=new $CLJS.r(null,"defn","defn",-126010802,null);Dw=new $CLJS.M(null,"colinc","colinc",-584873385);Tw=new $CLJS.M(null,"up-arrow","up-arrow",1705310333);Fx=new $CLJS.M(null,"right-bracket","right-bracket",951856080);Jca=new $CLJS.M(null,"radix","radix",857016463);Hx=new $CLJS.M(null,"first","first",-644103046);Dba=new $CLJS.M(null,"sections","sections",-886710106);$CLJS.Iv=new $CLJS.M(null,"start","start",-355208981);Kca=new $CLJS.r("cljs.core","unquote","cljs.core/unquote",1013085760,null);
Lca=new $CLJS.r(null,"defn-","defn-",1097765044,null);Mca=new $CLJS.r("cljs.core","deref","cljs.core/deref",1901963335,null);bw=new $CLJS.M(null,"block","block",664686210);Nca=new $CLJS.r(null,"when","when",1064114221,null);px=new $CLJS.M(null,"else-params","else-params",-832171646);$CLJS.ay=new $CLJS.M(null,"count","count",2139924085);$CLJS.Ex=new $CLJS.M(null,"right","right",-452581833);$v=new $CLJS.M(null,"linear","linear",872268697);Oca=new $CLJS.r(null,"when-not","when-not",-1223136340,null);
Ew=new $CLJS.M(null,"padchar","padchar",2018584530);Cw=new $CLJS.M(null,"mincol","mincol",1230695445);Pca=new $CLJS.M(null,"not-delivered","not-delivered",1599158697);kv=new $CLJS.M(null,"miser-width","miser-width",-1310049437);Qca=new $CLJS.r(null,"with-local-vars","with-local-vars",837642072,null);sca=new $CLJS.r(null,"lift-ns","lift-ns",602311926,null);Ix=new $CLJS.M(null,"allows-separator","allows-separator",-818967742);xv=new $CLJS.M(null,"buffering","buffering",-876713613);
by=new $CLJS.M(null,"arg1","arg1",951899358);Xw=new $CLJS.M(null,"base-args","base-args",-1268706822);cy=new $CLJS.M(null,"arg3","arg3",-1486822496);dy=new $CLJS.M(null,"arg2","arg2",1729550917);Iw=new $CLJS.M(null,"commainterval","commainterval",-1980061083);Rca=new $CLJS.M(null,"right-margin","right-margin",-810413306);zv=new $CLJS.M(null,"buffer-blob","buffer-blob",-1830112173);Sca=new $CLJS.r(null,"with-open","with-open",172119667,null);
rca=new $CLJS.r("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);Tca=new $CLJS.M(null,"lines","lines",-700165781);dw=new $CLJS.M(null,"indent-t","indent-t",528318969);nx=new $CLJS.M(null,"right-params","right-params",-1790676237);tw=new $CLJS.M(null,"colon","colon",-965200945);Uca=new $CLJS.r(null,"if-not","if-not",-265415609,null);Yw=new $CLJS.M(null,"func","func",-238706040);$CLJS.ey=new $CLJS.M(null,"last","last",1105735132);Px=new $CLJS.M(null,"deref","deref",-145586795);
Vca=new $CLJS.r(null,"dotimes","dotimes",-818708397,null);mx=new $CLJS.M(null,"max-iterations","max-iterations",2021275563);Wca=new $CLJS.r(null,"cond","cond",1606708055,null);Bw=new $CLJS.M(null,"minpad","minpad",323570901);nv=new $CLJS.M(null,"logical-block","logical-block",-581022564);Xca=new $CLJS.r(null,"struct-map","struct-map",-1387540878,null);Nv=new $CLJS.M(null,"end-block-t","end-block-t",1544648735);fy=new $CLJS.M(null,"stream","stream",1534941648);
Ww=new $CLJS.M(null,"params","params",710516235);Yca=new $CLJS.M(null,"circle","circle",1903212362);gv=new $CLJS.M(null,"start-pos","start-pos",668789086);wx=new $CLJS.M(null,"flags","flags",1775418075);Zv=new $CLJS.M(null,"fill","fill",883462889);Cba=new $CLJS.M(null,"buffer-block","buffer-block",-10937307);zx=new $CLJS.M(null,"generator-fn","generator-fn",811851656);lv=new $CLJS.M(null,"start-col","start-col",668080143);cx=new $CLJS.M(null,"w","w",354169001);Cx=new $CLJS.M(null,"def","def",-1043430536);
$CLJS.ex=new $CLJS.M(null,"k","k",-2146297393);hx=new $CLJS.M(null,"e","e",1381269198);dx=new $CLJS.M(null,"d","d",1972142424);fx=new $CLJS.M(null,"overflowchar","overflowchar",-1620088106);qx=new $CLJS.M(null,"min-remaining","min-remaining",962687677);Jw=new $CLJS.M(null,"commachar","commachar",652859327);gy=new $CLJS.M(null,"section","section",-300141526);hy=new $CLJS.M(null,"pretty","pretty",-1916372486);Zca=new $CLJS.r(null,"let","let",358118826,null);Uu=new $CLJS.M(null,"parent","parent",-878878779);
$CLJS.lx=new $CLJS.M(null,"clauses","clauses",1454841241);$ca=new $CLJS.r(null,"defonce","defonce",-1681484013,null);Ru=new $CLJS.M(null,"base","base",185279322);av=new $CLJS.M(null,"type-tag","type-tag",-1873863267);ada=new $CLJS.r(null,"locking","locking",1542862874,null);yv=new $CLJS.M(null,"pos","pos",-864607220);bda=new $CLJS.M(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.Qu=new $CLJS.M(null,"cur","cur",1153190599);var Fw=function Fw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Fw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)},Sv,Ev,jy,ky,ly,zw,yw,Vv,Qv,my;Fw.l=function(a){return $CLJS.ac($CLJS.Hu,$CLJS.Se.g($CLJS.Kw,a))};Fw.A=0;Fw.B=function(a){return this.l($CLJS.y(a))};var Gu=function Gu(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Gu.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};
Gu.l=function(a){return $CLJS.ac($CLJS.Hu,$CLJS.Se.g($CLJS.Mh,a))};Gu.A=0;Gu.B=function(a){return this.l($CLJS.y(a))};$CLJS.g=Su.prototype;$CLJS.g.P=function(a,b){return new Su(this.Ca,this.Nf,this.Yc,b)};$CLJS.g.O=function(){return this.bi};$CLJS.g.Qb=function(){return this.Yc};$CLJS.g.rc=function(){return wu(this.Ca)};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?Pu(this,$CLJS.Qu,$CLJS.Ou(this,$CLJS.Qu)+$CLJS.D(b)):(Pu(this,$CLJS.Qu,$CLJS.D(b)-a-1),Pu(this,$CLJS.nj,$CLJS.Ou(this,$CLJS.nj)+$CLJS.D($CLJS.lf(function(c){return $CLJS.E.g(c,"\n")},b)))),$CLJS.ac($CLJS.Ou(this,Ru),b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return yba(this,b);throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));
};$CLJS.g=Tu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "parent":return this.parent;case "section":return this.Cb;case "start-col":return this.tb;case "indent":return this.sb;case "done-nl":return this.vb;case "intra-block-nl":return this.wb;case "prefix":return this.prefix;case "per-line-prefix":return this.Ab;case "suffix":return this.Db;case "logical-block-callback":return this.xb;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Uu,this.parent],null),new $CLJS.P(null,2,5,$CLJS.Q,[gy,this.Cb],null),new $CLJS.P(null,2,5,$CLJS.Q,[lv,this.tb],null),new $CLJS.P(null,2,5,$CLJS.Q,[pv,this.sb],null),new $CLJS.P(null,2,5,$CLJS.Q,[iv,this.vb],null),new $CLJS.P(null,2,5,$CLJS.Q,[qv,this.wb],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$x,this.prefix],null),new $CLJS.P(null,2,5,$CLJS.Q,[ov,this.Ab],null),new $CLJS.P(null,2,5,$CLJS.Q,[Lv,this.Db],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hv,this.xb],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Jt(this,10,new $CLJS.P(null,10,5,$CLJS.Q,[Uu,gy,lv,pv,iv,qv,$x,ov,Lv,Hv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 10+$CLJS.D(this.G)};
$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1977012399^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.parent,b.parent)&&$CLJS.E.g(this.Cb,b.Cb)&&$CLJS.E.g(this.tb,b.tb)&&$CLJS.E.g(this.sb,b.sb)&&$CLJS.E.g(this.vb,b.vb)&&$CLJS.E.g(this.wb,b.wb)&&$CLJS.E.g(this.prefix,b.prefix)&&$CLJS.E.g(this.Ab,b.Ab)&&$CLJS.E.g(this.Db,b.Db)&&$CLJS.E.g(this.xb,b.xb)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,10,[Lv,null,pv,null,Uu,null,gy,null,iv,null,lv,null,$x,null,ov,null,Hv,null,qv,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Tu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Uu,b):$CLJS.he.call(null,Uu,b))?new Tu(c,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gy,b):$CLJS.he.call(null,gy,b))?new Tu(this.parent,c,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(lv,b):$CLJS.he.call(null,lv,b))?new Tu(this.parent,this.Cb,c,this.sb,this.vb,this.wb,this.prefix,this.Ab,
this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(pv,b):$CLJS.he.call(null,pv,b))?new Tu(this.parent,this.Cb,this.tb,c,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(iv,b):$CLJS.he.call(null,iv,b))?new Tu(this.parent,this.Cb,this.tb,this.sb,c,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(qv,b):$CLJS.he.call(null,qv,b))?new Tu(this.parent,this.Cb,this.tb,this.sb,this.vb,c,this.prefix,
this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($x,b):$CLJS.he.call(null,$x,b))?new Tu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,c,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(ov,b):$CLJS.he.call(null,ov,b))?new Tu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,c,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Lv,b):$CLJS.he.call(null,Lv,b))?new Tu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,
this.prefix,this.Ab,c,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Hv,b):$CLJS.he.call(null,Hv,b))?new Tu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,c,this.S,this.G,null):new Tu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.Yf(Uu,this.parent),new $CLJS.Yf(gy,this.Cb),new $CLJS.Yf(lv,this.tb),new $CLJS.Yf(pv,this.sb),new $CLJS.Yf(iv,this.vb),new $CLJS.Yf(qv,this.wb),new $CLJS.Yf($x,this.prefix),new $CLJS.Yf(ov,this.Ab),new $CLJS.Yf(Lv,this.Db),new $CLJS.Yf(Hv,this.xb)],null),this.G))};$CLJS.g.P=function(a,b){return new Tu(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,b,this.G,this.J)};
$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Wu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "data":return this.data;case "trailing-white-space":return this.ac;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[av,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,this.data],null),new $CLJS.P(null,2,5,$CLJS.Q,[cv,this.ac],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[fv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Jt(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[av,$CLJS.rj,cv,gv,fv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1809113693^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.data,b.data)&&$CLJS.E.g(this.ac,b.ac)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,5,[fv,null,cv,null,av,null,gv,null,$CLJS.rj,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Wu(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(av,b):$CLJS.he.call(null,av,b))?new Wu(c,this.data,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.rj,b):$CLJS.he.call(null,$CLJS.rj,b))?new Wu(this.ca,c,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(cv,b):$CLJS.he.call(null,cv,b))?new Wu(this.ca,this.data,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new Wu(this.ca,this.data,
this.ac,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(fv,b):$CLJS.he.call(null,fv,b))?new Wu(this.ca,this.data,this.ac,this.ba,c,this.S,this.G,null):new Wu(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Yf(av,this.ca),new $CLJS.Yf($CLJS.rj,this.data),new $CLJS.Yf(cv,this.ac),new $CLJS.Yf(gv,this.ba),new $CLJS.Yf(fv,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Wu(this.ca,this.data,this.ac,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Xu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "type":return this.type;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[av,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,this.type],null),new $CLJS.P(null,2,5,$CLJS.Q,[nv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[fv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Jt(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[av,$CLJS.mj,nv,gv,fv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1640656800^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.type,b.type)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,5,[fv,null,$CLJS.mj,null,nv,null,av,null,gv,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Xu(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(av,b):$CLJS.he.call(null,av,b))?new Xu(c,this.type,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.mj,b):$CLJS.he.call(null,$CLJS.mj,b))?new Xu(this.ca,c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(nv,b):$CLJS.he.call(null,nv,b))?new Xu(this.ca,this.type,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new Xu(this.ca,this.type,
this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(fv,b):$CLJS.he.call(null,fv,b))?new Xu(this.ca,this.type,this.ea,this.ba,c,this.S,this.G,null):new Xu(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Yf(av,this.ca),new $CLJS.Yf($CLJS.mj,this.type),new $CLJS.Yf(nv,this.ea),new $CLJS.Yf(gv,this.ba),new $CLJS.Yf(fv,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Xu(this.ca,this.type,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Yu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[av,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[nv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[fv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Jt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[av,nv,gv,fv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-414877272^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[fv,null,nv,null,av,null,gv,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Yu(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(av,b):$CLJS.he.call(null,av,b))?new Yu(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(nv,b):$CLJS.he.call(null,nv,b))?new Yu(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new Yu(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(fv,b):$CLJS.he.call(null,fv,b))?new Yu(this.ca,this.ea,this.ba,c,this.S,this.G,null):new Yu(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(av,this.ca),new $CLJS.Yf(nv,this.ea),new $CLJS.Yf(gv,this.ba),new $CLJS.Yf(fv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Yu(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Zu.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[av,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[nv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[fv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Jt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[av,nv,gv,fv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1365867980^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[fv,null,nv,null,av,null,gv,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Zu(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(av,b):$CLJS.he.call(null,av,b))?new Zu(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(nv,b):$CLJS.he.call(null,nv,b))?new Zu(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new Zu(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(fv,b):$CLJS.he.call(null,fv,b))?new Zu(this.ca,this.ea,this.ba,c,this.S,this.G,null):new Zu(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(av,this.ca),new $CLJS.Yf(nv,this.ea),new $CLJS.Yf(gv,this.ba),new $CLJS.Yf(fv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Zu(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=$u.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "relative-to":return this.Wb;case "offset":return this.offset;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[av,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[nv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Xx,this.Wb],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,this.offset],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[fv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Jt(this,6,new $CLJS.P(null,6,5,$CLJS.Q,[av,nv,Xx,$CLJS.Qt,gv,fv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 6+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1602780238^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.Wb,b.Wb)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,6,[$CLJS.Qt,null,fv,null,Xx,null,nv,null,av,null,gv,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new $u(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(av,b):$CLJS.he.call(null,av,b))?new $u(c,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(nv,b):$CLJS.he.call(null,nv,b))?new $u(this.ca,c,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Xx,b):$CLJS.he.call(null,Xx,b))?new $u(this.ca,this.ea,c,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Qt,b):$CLJS.he.call(null,$CLJS.Qt,
b))?new $u(this.ca,this.ea,this.Wb,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new $u(this.ca,this.ea,this.Wb,this.offset,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(fv,b):$CLJS.he.call(null,fv,b))?new $u(this.ca,this.ea,this.Wb,this.offset,this.ba,c,this.S,this.G,null):new $u(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.Yf(av,this.ca),new $CLJS.Yf(nv,this.ea),new $CLJS.Yf(Xx,this.Wb),new $CLJS.Yf($CLJS.Qt,this.offset),new $CLJS.Yf(gv,this.ba),new $CLJS.Yf(fv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new $u(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};
var dv=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("cljs.pprint","write-token"),function(f,k){return av.h(k)},e,a,b,c,d)}();
dv.m(null,Jv,function(a,b){var c=Hv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h($CLJS.Iv):c.call(null,$CLJS.Iv));b=nv.h(b);c=$x.h(b);$CLJS.n(c)&&$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),c);a=$CLJS.Ou(Ru.h($CLJS.q($CLJS.q(a))),$CLJS.Qu);$CLJS.Ye(lv.h(b),a);return $CLJS.Ye(pv.h(b),a)});dv.m(null,Nv,function(a,b){var c=Hv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h(Mv):c.call(null,Mv));b=Lv.h(nv.h(b));return $CLJS.n(b)?$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),b):null});
dv.m(null,dw,function(a,b){var c=nv.h(b),d=pv.h(c),e=$CLJS.Qt.h(b);b=Xx.h(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(bw,b):$CLJS.E.call(null,bw,b)))a=$CLJS.q(lv.h(c));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.cw,b):$CLJS.E.call(null,$CLJS.cw,b)))a=$CLJS.Ou(Ru.h($CLJS.q($CLJS.q(a))),$CLJS.Qu);else throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));return $CLJS.Ye(d,e+a)});dv.m(null,zv,function(a,b){return $CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),$CLJS.rj.h(b))});
dv.m(null,bv,function(a,b){var c=$CLJS.E.g($CLJS.mj.h(b),Xv);c||(c=(c=!$CLJS.E.g($CLJS.mj.h(b),Zv))?$CLJS.q(iv.h(nv.h(b))):c);$CLJS.n(c)?rv.g?rv.g(a,b):rv.call(null,a,b):(b=cv.h($CLJS.q($CLJS.q(a))),$CLJS.n(b)&&$CLJS.ac(Ru.h($CLJS.q($CLJS.q(a))),b));return $CLJS.Oh.v($CLJS.q(a),$CLJS.R,cv,null)});
var iy=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("cljs.pprint","emit-nl?"),function(f){return $CLJS.mj.h(f)},e,a,b,c,d)}();iy.m(null,$v,function(a,b,c){a=nv.h(a);return jv(b,a,c)});iy.m(null,Yv,function(a,b,c){a=nv.h(a);return mv(b,a,c)});
iy.m(null,Zv,function(a,b,c,d){a=nv.h(a);var e=$CLJS.q(qv.h(a));return $CLJS.n(e)?e:(d=!hv(b,d))?d:mv(b,a,c)});iy.m(null,Xv,function(){return!0});
var sv=function sv(a,b){var d=Bba(b);b=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);$CLJS.n(b)&&ev(a,b,!1);if($CLJS.n(e)){d=zba(e);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=$CLJS.A(e),k=iy.v(f,a,b,Aba(e));$CLJS.n(k)&&(rv(a,f),e=$CLJS.B(e));hv(a,e)?a=e:(e=sv.g?sv.g(a,b):sv.call(null,a,b),$CLJS.E.g(e,b)?(ev(a,b,!1),a=d):a=$CLJS.eg.g($CLJS.Cf,$CLJS.gf.g(e,d)));return a}return null};$CLJS.g=Cv.prototype;$CLJS.g.P=function(a,b){return new Cv(this.Ca,this.Nf,this.li,this.mh,this.Yc,b)};$CLJS.g.O=function(){return this.ci};
$CLJS.g.Qb=function(){return this.Yc};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a))){var c=Bv(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.yi.h($CLJS.q($CLJS.q(this)));if($CLJS.E.g(d,Av))return vv(this),$CLJS.ac(Ru.h($CLJS.q($CLJS.q(this))),b),$CLJS.Oh.v($CLJS.q(this),$CLJS.R,cv,a);d=yv.h($CLJS.q($CLJS.q(this)));c=d+$CLJS.D(c);$CLJS.Oh.v($CLJS.q(this),$CLJS.R,yv,c);return uv(this,new Wu(zv,b,a,d,c,null,null,null))}if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,
a):$CLJS.E.call(null,Number,a)))return $CLJS.E.g($CLJS.yi.h($CLJS.q($CLJS.q(this))),Av)?(vv(this),b=$CLJS.ac(Ru.h($CLJS.q($CLJS.q(this))),b)):$CLJS.E.g(b,"\n")?b=Bv(this,"\n"):(a=yv.h($CLJS.q($CLJS.q(this))),c=a+1,$CLJS.Oh.v($CLJS.q(this),$CLJS.R,yv,c),b=zu(b),b=uv(this,new Wu(zv,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};$CLJS.g.rc=function(){this.uf(null);return wu(Ru.h($CLJS.q($CLJS.q(this))))};
$CLJS.g.uf=function(){return $CLJS.E.g($CLJS.yi.h($CLJS.q($CLJS.q(this))),xv)?(ev(this,$CLJS.Ot.h($CLJS.q($CLJS.q(this))),!0),$CLJS.Oh.v($CLJS.q(this),$CLJS.R,$CLJS.Ot,$CLJS.Cf)):vv(this)};$CLJS.Rv=!0;Sv=null;$CLJS.Dv=72;Ev=40;jy=null;ky=null;ly=null;zw=null;yw=10;Vv=0;Qv=null;my=function my(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return my.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
my.l=function(a,b){var c=$CLJS.fl.l($CLJS.H([new $CLJS.h(null,1,[fy,!0],null),$CLJS.Se.g(Wx,b)]));b=yw;var d=ky,e=$CLJS.Na,f=$CLJS.qh,k=jy,l=Ev,m=Sv,t=$CLJS.Rv,u=zw,v=$CLJS.Ha,x=$CLJS.Dv,z=ly,C=Ru.g(c,yw),G=Yca.g(c,ky),K=$CLJS.Zx.g(c,$CLJS.Na),S=$CLJS.St.g(c,$CLJS.qh),V=Tca.g(c,jy),Z=kv.g(c,Ev),ha=$CLJS.Oi.g(c,Sv),ra=hy.g(c,$CLJS.Rv),Ma=Jca.g(c,zw),zb=$CLJS.Ga.g(c,$CLJS.Ha),Oa=Rca.g(c,$CLJS.Dv),Ya=bda.g(c,ly);yw=C;ky=G;$CLJS.Na=K;$CLJS.qh=S;jy=V;Ev=Z;Sv=ha;$CLJS.Rv=ra;zw=Ma;$CLJS.Ha=zb;$CLJS.Dv=Oa;
ly=Ya;try{var eb=new $CLJS.Ea,Sa=$CLJS.Hd(c,fy)?fy.h(c):!0,mb=!0===Sa||null==Sa?new $CLJS.sc(eb):Sa;if($CLJS.n($CLJS.Rv)){var Ia=$CLJS.Va($CLJS.Pv(mb));c=$CLJS.Hu;$CLJS.Hu=Ia?$CLJS.Gv(mb):mb;try{$CLJS.Tv(a),$CLJS.Nu()}finally{$CLJS.Hu=c}}else{Ia=$CLJS.Hu;$CLJS.Hu=mb;try{Gu.call(null,a)}finally{$CLJS.Hu=Ia}}!0===Sa&&$CLJS.uh($CLJS.p.h(eb));return null==Sa?$CLJS.p.h(eb):null}finally{ly=z,$CLJS.Dv=x,$CLJS.Ha=v,zw=u,$CLJS.Rv=t,Sv=m,Ev=l,jy=k,$CLJS.qh=f,$CLJS.Na=e,ky=d,yw=b}};my.A=1;
my.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};var kw=null;$CLJS.g=mw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "seq":return this.uc;case "rest":return this.pb;case "pos":return this.$b;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[nw,this.uc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,this.pb],null),new $CLJS.P(null,2,5,$CLJS.Q,[yv,this.$b],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Jt(this,3,new $CLJS.P(null,3,5,$CLJS.Q,[nw,$CLJS.Pi,yv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};
$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 3+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-402038447^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.uc,b.uc)&&$CLJS.E.g(this.pb,b.pb)&&$CLJS.E.g(this.$b,b.$b)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,3,[yv,null,nw,null,$CLJS.Pi,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new mw(this.uc,this.pb,this.$b,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(nw,b):$CLJS.he.call(null,nw,b))?new mw(c,this.pb,this.$b,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Pi,b):$CLJS.he.call(null,$CLJS.Pi,b))?new mw(this.uc,c,this.$b,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(yv,b):$CLJS.he.call(null,yv,b))?new mw(this.uc,this.pb,c,this.S,this.G,null):new mw(this.uc,this.pb,this.$b,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.Yf(nw,this.uc),new $CLJS.Yf($CLJS.Pi,this.pb),new $CLJS.Yf(yv,this.$b)],null),this.G))};$CLJS.g.P=function(a,b){return new mw(this.uc,this.pb,this.$b,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=sw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "func":return this.dc;case "def":return this.cc;case "params":return this.kc;case "offset":return this.offset;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Yw,this.dc],null),new $CLJS.P(null,2,5,$CLJS.Q,[Cx,this.cc],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ww,this.kc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qt,this.offset],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Jt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Yw,Cx,Ww,$CLJS.Qt],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-829256337^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.dc,b.dc)&&$CLJS.E.g(this.cc,b.cc)&&$CLJS.E.g(this.kc,b.kc)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.Qt,null,Yw,null,Ww,null,Cx,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new sw(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Yw,b):$CLJS.he.call(null,Yw,b))?new sw(c,this.cc,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Cx,b):$CLJS.he.call(null,Cx,b))?new sw(this.dc,c,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Ww,b):$CLJS.he.call(null,Ww,b))?new sw(this.dc,this.cc,c,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Qt,b):$CLJS.he.call(null,$CLJS.Qt,b))?new sw(this.dc,this.cc,this.kc,c,this.S,
this.G,null):new sw(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(Yw,this.dc),new $CLJS.Yf(Cx,this.cc),new $CLJS.Yf(Ww,this.kc),new $CLJS.Yf($CLJS.Qt,this.offset)],null),this.G))};$CLJS.g.P=function(a,b){return new sw(this.dc,this.cc,this.kc,this.offset,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};
var Gba=new $CLJS.h(null,3,[2,"#b",8,"#o",16,"#x"],null),Mw=new $CLJS.P(null,20,5,$CLJS.Q,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),ny=new $CLJS.P(null,20,5,$CLJS.Q,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),Nw=new $CLJS.P(null,10,5,$CLJS.Q,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),cda=new $CLJS.P(null,10,5,$CLJS.Q,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),Pw=new $CLJS.P(null,22,5,$CLJS.Q," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),dda=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),eda=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),Iba=new $CLJS.h(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);sx.prototype.P=function(a,b){return new sx(this.Ca,b)};sx.prototype.O=function(){return this.di};sx.prototype.rc=function(){return wu(this.Ca)};
sx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,zu(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};tx.prototype.P=function(a,b){return new tx(this.Ca,b)};tx.prototype.O=function(){return this.ei};tx.prototype.rc=function(){return wu(this.Ca)};
tx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toUpperCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,zu(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};ux.prototype.P=function(a,b){return new ux(this.Ca,this.Jd,b)};ux.prototype.O=function(){return this.fi};ux.prototype.rc=function(){return wu(this.Ca)};
ux.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,$ba(b.toLowerCase(),$CLJS.q(this.Jd))),0<b.length?$CLJS.Ye(this.Jd,$CLJS.Aa($CLJS.F(b,$CLJS.D(b)-1))):null;if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=zu(b),a=$CLJS.n($CLJS.q(this.Jd))?b.toUpperCase():b,$CLJS.ac(this.Ca,a),$CLJS.Ye(this.Jd,$CLJS.Aa(b));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
vx.prototype.P=function(a,b){return new vx(this.Ca,this.vd,b)};vx.prototype.O=function(){return this.gi};vx.prototype.rc=function(){return wu(this.Ca)};
vx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Va($CLJS.q(this.vd))?(a=RegExp("\\S","g").exec(b),a=$CLJS.n(a)?a.index:a,$CLJS.n(a)?($CLJS.ac(this.Ca,[b.substring(0,a),$CLJS.F(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.Ye(this.vd,!0)):$CLJS.ac(this.Ca,b)):$CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=zu(b),a=$CLJS.Va($CLJS.q(this.vd)),
$CLJS.n(a?uu(b):a)?($CLJS.Ye(this.vd,!0),$CLJS.ac(this.Ca,b.toUpperCase())):$CLJS.ac(this.Ca,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
var mca=$CLJS.Ig("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.h(null,5,[xx,"A",Ww,new $CLJS.h(null,4,[Cw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Dw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Bw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,yx,null],null),null),Bx,$CLJS.N,zx,function(){return function(a,b){return Gw($CLJS.Kw,a,b)}}],null),new $CLJS.h(null,
5,[xx,"S",Ww,new $CLJS.h(null,4,[Cw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Dw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Bw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,yx,null],null),null),Bx,$CLJS.N,zx,function(){return function(a,b){return Gw($CLJS.Mh,a,b)}}],null),new $CLJS.h(null,5,[xx,"D",Ww,new $CLJS.h(null,4,[Cw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,
2,5,$CLJS.Q,[" ",String],null),Jw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Iw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,yx,null],null),null),Bx,$CLJS.N,zx,function(){return function(a,b){return Lw(10,a,b)}}],null),new $CLJS.h(null,5,[xx,"B",Ww,new $CLJS.h(null,4,[Cw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Jw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Iw,new $CLJS.P(null,
2,5,$CLJS.Q,[3,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,yx,null],null),null),Bx,$CLJS.N,zx,function(){return function(a,b){return Lw(2,a,b)}}],null),new $CLJS.h(null,5,[xx,"O",Ww,new $CLJS.h(null,4,[Cw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Jw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Iw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,yx,
null],null),null),Bx,$CLJS.N,zx,function(){return function(a,b){return Lw(8,a,b)}}],null),new $CLJS.h(null,5,[xx,"X",Ww,new $CLJS.h(null,4,[Cw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Jw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Iw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,yx,null],null),null),Bx,$CLJS.N,zx,function(){return function(a,b){return Lw(16,a,b)}}],null),new $CLJS.h(null,
5,[xx,"R",Ww,new $CLJS.h(null,5,[Ru,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Cw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Jw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Iw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,yx,null],null),null),Bx,$CLJS.N,zx,function(a){return $CLJS.n($CLJS.A(Ru.h(a)))?function(b,c){return Lw(Ru.h(b),b,c)}:$CLJS.n(function(){var b=uw.h(a);return $CLJS.n(b)?
tw.h(a):b}())?function(b,c){return Rw(dda,c)}:$CLJS.n(uw.h(a))?function(b,c){return Rw(eda,c)}:$CLJS.n(tw.h(a))?function(b,c){b=ow(c);c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if($CLJS.E.g(0,c))Fw.l($CLJS.H(["zeroth"]));else{var d=Hw(1E3,0>c?-c:c);if($CLJS.D(d)<=$CLJS.D(Pw)){var e=$CLJS.hf.g(Ow,$CLJS.Fu(1,d));e=Qw(e,1);var f=$CLJS.id(d);d=$CLJS.Vd(f,100);f=Au(f,100);var k=0<d?[$CLJS.p.h($CLJS.F(Mw,d))," hundred"].join(""):null,l=$CLJS.p,m=l.h;if(0<f)if(20>f)var t=$CLJS.F(ny,f);else{t=$CLJS.Vd(f,10);
var u=Au(f,10);t=0<t&&!(0<u)?$CLJS.F(cda,t):[$CLJS.p.h(0<t?$CLJS.F(Nw,t):null),0<t&&0<u?"-":null,$CLJS.p.h(0<u?$CLJS.F(ny,u):null)].join("")}else t=0<d?"th":null;d=[k,0<d&&0<f?" ":null,m.call(l,t)].join("");Fw.l($CLJS.H([[0>c?"minus ":null,$CLJS.td(e)||$CLJS.td(d)?$CLJS.td(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else Lw(10,new $CLJS.h(null,5,[Cw,0,Ew," ",Jw,",",Iw,3,tw,!0],null),gw(new $CLJS.P(null,1,5,$CLJS.Q,[c],null))),e=Au(c,100),c=11<e||19>e,e=Au(e,10),Fw.l($CLJS.H([1===e&&c?
"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=ow(c);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.E.g(0,b))Fw.l($CLJS.H(["zero"]));else{var d=Hw(1E3,0>b?-b:b);$CLJS.D(d)<=$CLJS.D(Pw)?(d=$CLJS.hf.g(Ow,d),d=Qw(d,0),Fw.l($CLJS.H([[0>b?"minus ":null,d].join("")]))):Lw(10,new $CLJS.h(null,5,[Cw,0,Ew," ",Jw,",",Iw,3,tw,!0],null),gw(new $CLJS.P(null,1,5,$CLJS.Q,[b],null)))}return c}}],null),new $CLJS.h(null,5,[xx,"P",Ww,$CLJS.N,wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,
null,yx,null],null),null),Bx,$CLJS.N,zx,function(){return function(a,b){b=$CLJS.n(tw.h(a))?qw(b,-1):b;a=$CLJS.n(uw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,["y","ies"],null):new $CLJS.P(null,2,5,$CLJS.Q,["","s"],null);var c=ow(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);Fw.l($CLJS.H([$CLJS.E.g(b,1)?$CLJS.A(a):$CLJS.hd(a)]));return c}}],null),new $CLJS.h(null,5,[xx,"C",Ww,new $CLJS.h(null,1,[Sw,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,
yx,null],null),null),Bx,$CLJS.N,zx,function(a){return $CLJS.n(tw.h(a))?Jba:$CLJS.n(uw.h(a))?Kba:Lba}],null),new $CLJS.h(null,5,[xx,"F",Ww,new $CLJS.h(null,5,[cx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.ex,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),fx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,1,[uw,null],null),null),Bx,$CLJS.N,zx,
function(){return gx}],null),new $CLJS.h(null,5,[xx,"E",Ww,new $CLJS.h(null,7,[cx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),hx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.ex,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),fx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),ix,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,1,[uw,null],
null),null),Bx,$CLJS.N,zx,function(){return jx}],null),new $CLJS.h(null,5,[xx,"G",Ww,new $CLJS.h(null,7,[cx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),hx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.ex,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),fx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),ix,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,
1,[uw,null],null),null),Bx,$CLJS.N,zx,function(){return Nba}],null),new $CLJS.h(null,5,[xx,"$",Ww,new $CLJS.h(null,4,[dx,new $CLJS.P(null,2,5,$CLJS.Q,[2,Number],null),$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),cx,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,yx,null],null),null),Bx,$CLJS.N,zx,function(){return Oba}],null),new $CLJS.h(null,5,[xx,"%",Ww,new $CLJS.h(null,1,[$CLJS.ay,
new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),wx,$CLJS.bh,Bx,$CLJS.N,zx,function(){return function(a,b){a=$CLJS.ay.h(a);for(var c=0;;)if(c<a)Iu(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[xx,"\x26",Ww,new $CLJS.h(null,1,[$CLJS.ay,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,1,[hy,null],null),null),Bx,$CLJS.N,zx,function(){return function(a,b){a=$CLJS.ay.h(a);0<a&&((null!=$CLJS.Hu?$CLJS.Hu.C&32768||$CLJS.Bc===$CLJS.Hu.ag||($CLJS.Hu.C?
0:$CLJS.$a($CLJS.vu,$CLJS.Hu)):$CLJS.$a($CLJS.vu,$CLJS.Hu))?$CLJS.E.g(0,$CLJS.Ou(Ru.h($CLJS.q($CLJS.q($CLJS.Hu))),$CLJS.Qu))||Iu():Iu());--a;for(var c=0;;)if(c<a)Iu(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[xx,"|",Ww,new $CLJS.h(null,1,[$CLJS.ay,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),wx,$CLJS.bh,Bx,$CLJS.N,zx,function(){return function(a,b){a=$CLJS.ay.h(a);for(var c=0;;)if(c<a)Fw.l($CLJS.H(["\f"])),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[xx,"~",Ww,new $CLJS.h(null,
1,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),wx,$CLJS.bh,Bx,$CLJS.N,zx,function(){return function(a,b){a=$CLJS.qk.h(a);Fw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(a,"~"))]));return b}}],null),new $CLJS.h(null,5,[xx,"\n",Ww,$CLJS.N,wx,new $CLJS.ah(null,new $CLJS.h(null,2,[tw,null,uw,null],null),null),Bx,$CLJS.N,zx,function(){return function(a,b){$CLJS.n(uw.h(a))&&Iu();return b}}],null),new $CLJS.h(null,5,[xx,"T",Ww,new $CLJS.h(null,2,[Yx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),
Dw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,2,[uw,null,hy,null],null),null),Bx,$CLJS.N,zx,function(a){return $CLJS.n(uw.h(a))?function(b,c){var d=Yx.h(b);b=Dw.h(b);var e=d+$CLJS.Ou(Ru.h($CLJS.q($CLJS.q($CLJS.Hu))),$CLJS.Qu);e=0<b?Au(e,b):0;d+=$CLJS.E.g(0,e)?0:b-e;Fw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(d," "))]));return c}:function(b,c){var d=Yx.h(b);b=Dw.h(b);var e=$CLJS.Ou(Ru.h($CLJS.q($CLJS.q($CLJS.Hu))),$CLJS.Qu);d=e<d?d-e:$CLJS.E.g(b,0)?0:b-
Au(e-d,b);Fw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(d," "))]));return c}}],null),new $CLJS.h(null,5,[xx,"*",Ww,new $CLJS.h(null,1,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,2,[tw,null,uw,null],null),null),Bx,$CLJS.N,zx,function(){return function(a,b){var c=$CLJS.qk.h(a);return $CLJS.n(uw.h(a))?rw(b,c):qw(b,$CLJS.n(tw.h(a))?-c:c)}}],null),new $CLJS.h(null,5,[xx,"?",Ww,$CLJS.N,wx,new $CLJS.ah(null,new $CLJS.h(null,1,[uw,null],null),null),Bx,
$CLJS.N,zx,function(a){return $CLJS.n(uw.h(a))?function(b,c){var d=pw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return Zw(c,d,Xw.h(b))}:function(b,c){var d=pw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=ow(d);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);d=gw(d);Zw(c,d,Xw.h(b));return e}}],null),new $CLJS.h(null,5,[xx,"(",Ww,$CLJS.N,wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,yx,null],null),null),Bx,new $CLJS.h(null,3,[$CLJS.Ex,")",Ix,null,$CLJS.Pt,null],null),zx,function(a){var b=$CLJS.n(function(){var c=
uw.h(a);return $CLJS.n(c)?tw.h(a):c}())?Zba:$CLJS.n(tw.h(a))?aca:$CLJS.n(uw.h(a))?bca:Yba;return function(c,d){a:{var e=$CLJS.A($CLJS.lx.h(c)),f=$CLJS.Hu;$CLJS.Hu=b.h?b.h($CLJS.Hu):b.call(null,$CLJS.Hu);try{var k=Zw(e,d,Xw.h(c));break a}finally{$CLJS.Hu=f}k=void 0}return k}}],null),new $CLJS.h(null,5,[xx,")",Ww,$CLJS.N,wx,$CLJS.bh,Bx,$CLJS.N,zx,function(){return null}],null),new $CLJS.h(null,5,[xx,"[",Ww,new $CLJS.h(null,1,[kx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),wx,new $CLJS.ah(null,
new $CLJS.h(null,2,[tw,null,uw,null],null),null),Bx,new $CLJS.h(null,3,[$CLJS.Ex,"]",Ix,!0,$CLJS.Pt,$CLJS.ey],null),zx,function(a){return $CLJS.n(tw.h(a))?Qba:$CLJS.n(uw.h(a))?Rba:Pba}],null),new $CLJS.h(null,5,[xx,";",Ww,new $CLJS.h(null,2,[qx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),rx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,1,[tw,null],null),null),Bx,new $CLJS.h(null,1,[$CLJS.Rt,!0],null),zx,function(){return null}],null),new $CLJS.h(null,
5,[xx,"]",Ww,$CLJS.N,wx,$CLJS.bh,Bx,$CLJS.N,zx,function(){return null}],null),new $CLJS.h(null,5,[xx,"{",Ww,new $CLJS.h(null,1,[mx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,yx,null],null),null),Bx,new $CLJS.h(null,2,[$CLJS.Ex,"}",Ix,!1],null),zx,function(a){var b=uw.h(a);b=$CLJS.n(b)?tw.h(a):b;return $CLJS.n(b)?Vba:$CLJS.n(tw.h(a))?Tba:$CLJS.n(uw.h(a))?Uba:Sba}],null),new $CLJS.h(null,5,[xx,"}",Ww,$CLJS.N,wx,new $CLJS.ah(null,
new $CLJS.h(null,1,[tw,null],null),null),Bx,$CLJS.N,zx,function(){return null}],null),new $CLJS.h(null,5,[xx,"\x3c",Ww,new $CLJS.h(null,4,[Cw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Dw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Bw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,4,[tw,null,uw,null,yx,null,hy,null],null),null),Bx,new $CLJS.h(null,3,[$CLJS.Ex,"\x3e",Ix,!0,$CLJS.Pt,Hx],null),zx,function(){return Xba}],
null),new $CLJS.h(null,5,[xx,"\x3e",Ww,$CLJS.N,wx,new $CLJS.ah(null,new $CLJS.h(null,1,[tw,null],null),null),Bx,$CLJS.N,zx,function(){return null}],null),new $CLJS.h(null,5,[xx,"^",Ww,new $CLJS.h(null,3,[by,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),dy,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),cy,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,1,[tw,null],null),null),Bx,$CLJS.N,zx,function(){return function(a,b){var c=by.h(a),d=dy.h(a),e=
cy.h(a),f=$CLJS.n(tw.h(a))?Uw:Tw;return $CLJS.n($CLJS.n(c)?$CLJS.n(d)?e:d:c)?c<=d&&d<=e?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n($CLJS.n(c)?d:c)?$CLJS.E.g(c,d)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n(c)?$CLJS.E.g(c,0)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:($CLJS.n(tw.h(a))?$CLJS.td($CLJS.Pi.h(Xw.h(a))):$CLJS.td($CLJS.Pi.h(b)))?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b}}],null),new $CLJS.h(null,5,[xx,"W",Ww,$CLJS.N,wx,new $CLJS.ah(null,new $CLJS.h(null,4,[tw,null,uw,null,
yx,null,hy,null],null),null),Bx,$CLJS.N,zx,function(a){if($CLJS.n(function(){var c=uw.h(a);return $CLJS.n(c)?c:tw.h(a)}())){var b=$CLJS.gf.g($CLJS.n(uw.h(a))?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.St,null,$CLJS.Zx,null],null):$CLJS.Cf,$CLJS.n(tw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[hy,!0],null):$CLJS.Cf);return function(c,d){d=ow(d);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.n($CLJS.Se.j(my,c,b))?new $CLJS.P(null,2,5,$CLJS.Q,[Tw,d],null):d}}return function(c,d){d=ow(d);c=$CLJS.I(d,0,null);d=
$CLJS.I(d,1,null);return $CLJS.n($CLJS.Tv(c))?new $CLJS.P(null,2,5,$CLJS.Q,[Tw,d],null):d}}],null),new $CLJS.h(null,5,[xx,"_",Ww,$CLJS.N,wx,new $CLJS.ah(null,new $CLJS.h(null,3,[tw,null,uw,null,yx,null],null),null),Bx,$CLJS.N,zx,function(){return dca}],null),new $CLJS.h(null,5,[xx,"I",Ww,new $CLJS.h(null,1,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null)],null),wx,new $CLJS.ah(null,new $CLJS.h(null,1,[tw,null],null),null),Bx,$CLJS.N,zx,function(){return cca}],null)]),eca=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,
kca=new $CLJS.ah(null,new $CLJS.h(null,2,[ww,null,vw,null],null),null),hca=new $CLJS.h(null,2,[":",tw,"@",uw],null),hw=function hw(a){for(;;){if($CLJS.td(a))return!1;var c=hy.h(wx.h(Cx.h($CLJS.A(a))));$CLJS.n(c)||(c=$CLJS.Re(hw,$CLJS.A($CLJS.lx.h(Ww.h($CLJS.A(a))))),c=$CLJS.n(c)?c:$CLJS.Re(hw,$CLJS.A($CLJS.Pt.h(Ww.h($CLJS.A(a))))));if($CLJS.n(c))return!0;a=$CLJS.B(a)}},Sx=$CLJS.Ph(fw),Jx=new $CLJS.h(null,6,[$CLJS.Pj,"'",$CLJS.Tj,"#'",$CLJS.kba,"@",$CLJS.lba,"~",Mca,"@",Kca,"~"],null);
(function(){var a=Sx("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=gw(d);return iw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()})();
var oy=function(){var a=Sx("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=gw(d);return iw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),py=new $CLJS.h(null,2,["core$future_call","Future","core$promise","Promise"],null),fda=function(){var a=Sx("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=gw(d);return iw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),qy,gda=$CLJS.Xe($CLJS.N),hda=$CLJS.Xe($CLJS.N),ida=$CLJS.Xe($CLJS.N),jda=$CLJS.Xe($CLJS.N),kda=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
qy=new $CLJS.ei($CLJS.Fh.g("cljs.pprint","simple-dispatch"),Qx,kda,gda,hda,ida,jda);Bu(qy,$CLJS.Cj,function(a){if($CLJS.Va(Kx(a)))if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{var b=Vv,c=Qv;Vv+=1;Qv=0;try{Kv("(",")");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if(e&&($CLJS.Tv($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Hu," ");aw($v);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Hu,"...");break}Ov()}finally{Qv=c,Vv=b}}return null});Bu(qy,$CLJS.Jj,Lx);Bu(qy,$CLJS.hk,Mx);
Bu(qy,$CLJS.$i,oy);Bu(qy,null,function(){return $CLJS.ac($CLJS.Hu,$CLJS.Mh.l($CLJS.H([null])))});Bu(qy,$CLJS.di,Nx);Sv=qy;
var ry=function(){var a=Sx("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=gw(d);return iw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),sy=function(){var a=Sx("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=gw(d);return iw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),ty=$CLJS.N,lda=function(a){return $CLJS.eg.g($CLJS.N,$CLJS.jf($CLJS.Td,$CLJS.H([function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=new $CLJS.P(null,
2,5,$CLJS.Q,[t,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.h($CLJS.gh($CLJS.A(t))),$CLJS.hd(t)],null)],null);l.add(t);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.h($CLJS.gh($CLJS.A(l))),$CLJS.hd(l)],null)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.eg.g($CLJS.N,$CLJS.hf.g(function(b){var c=$CLJS.I(b,0,null),d=$CLJS.I(b,
1,null);var e=$CLJS.ie(c);e=$CLJS.n(e)?e:$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,24,[$CLJS.aj,"null",$CLJS.qj,"null",$CLJS.rk,"null",$CLJS.tk,"null",$CLJS.lj,"null",$CLJS.Lj,"null",$CLJS.Hj,"null",$CLJS.Mj,"null",$CLJS.ri,"null",$CLJS.Wj,"null",$CLJS.Bj,"null",$CLJS.tj,"null",$CLJS.dk,"null",$CLJS.sk,"null",$CLJS.Si,"null",$CLJS.Ni,"null",$CLJS.jj,"null",$CLJS.vj,"null",$CLJS.bj,"null",$CLJS.Tj,"null",$CLJS.Pj,"null",$CLJS.kk,"null",$CLJS.Ji,"null",$CLJS.gk,"null"],null),null),c);return $CLJS.Va(e)?
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.g("clojure.core",$CLJS.gh(c)),d],null):b},a))}($CLJS.Ig([$CLJS.bj,$CLJS.Ni,Cca,$CLJS.Wj,Hca,zca,Bca,Ica,Fca,Eca,Gca,Uca,Oca,$CLJS.gk,Nca,Sca,Qca,$ca,xca,$CLJS.tj,Vca,Wca,Zca,$CLJS.Qj,Lca,ada,yca,Aca,Dca,Xca],[ry,function(a){var b=$CLJS.hd(a),c=$CLJS.A($CLJS.Lc($CLJS.Lc(a)));if($CLJS.zd(b)){a=ty;ty=$CLJS.E.g(1,$CLJS.D(b))?$CLJS.Ee([$CLJS.A(b),"%"]):$CLJS.eg.g($CLJS.N,$CLJS.hf.j(function(d,e){return new $CLJS.P(null,2,5,$CLJS.Q,[d,["%",$CLJS.p.h(e)].join("")],
null)},b,$CLJS.Du(1,$CLJS.D(b)+1)));try{return function(){var d=Sx("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.w(m,0,null)}return f.call(this,l)}function f(k){k=gw(k);return iw(d,k)}e.A=0;e.B=function(k){k=$CLJS.y(k);return f(k)};e.l=f;return e}()}()(c)}finally{ty=a}}else return Tx(a)},Vx,sy,function(a){if(3<$CLJS.D(a)){if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,
"#");else{var b=Vv,c=Qv;Vv+=1;Qv=0;try{Kv("(",")");ew(bw,1);$CLJS.Se.g(function(){var l=Sx("~w ~@_~w ~@_~w ~_");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=gw(u);return iw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}(),a);for(var d=0,e=$CLJS.y($CLJS.bf(3,a));;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if(e){if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,
"#");else{a=Vv;var f=Qv;Vv+=1;Qv=0;try{Kv(null,null),$CLJS.Tv($CLJS.A(e)),$CLJS.B(e)&&($CLJS.ac($CLJS.Hu," "),aw(Yv),$CLJS.Tv($CLJS.hd(e))),Ov()}finally{Qv=f,Vv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.Hu," ");aw($v);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.Hu,"...");break}Ov()}finally{Qv=c,Vv=b}}return null}return Tx(a)},ry,Ux,Ux,Vx,ry,Vx,sy,sy,ry,sy,Vx,Vx,ry,Vx,function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=
"string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=$CLJS.xd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{d=Vv;var k=Qv;Vv+=1;Qv=0;try{Kv("(",")");(function(){var m=Sx("~w ~1I~@_~w");return function(){function t(v){var x=
null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=gw(v);return iw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()})()(a,b);$CLJS.n($CLJS.n(c)?c:$CLJS.n(e)?e:$CLJS.y(f))&&function(){var m=Sx("~@:_");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,
x)}function u(v){v=gw(v);return iw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()}()();$CLJS.n(c)&&jw(!0,'"~a"~:[~;~:@_~]',$CLJS.H([c,$CLJS.n(e)?e:$CLJS.y(f)]));$CLJS.n(e)&&function(){var m=Sx("~w~:[~;~:@_~]");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=gw(v);return iw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};
t.l=u;return t}()}()(e,$CLJS.y(f));for(a=f;;){tca($CLJS.A(a));var l=$CLJS.B(a);if(l)b=l,aw($v),a=b;else break}Ov()}finally{Qv=k,Vv=d}}return null}return $CLJS.Tv(a)},Vx,function(a){if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{var b=Vv,c=Qv;Vv+=1;Qv=0;try{Kv("(",")");ew(bw,1);$CLJS.Tv($CLJS.A(a));if($CLJS.B(a)){$CLJS.ac($CLJS.Hu," ");aw($v);for(var d=0,e=$CLJS.B(a);;){if($CLJS.Va($CLJS.Na)||d<$CLJS.Na){if(e){if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{a=Vv;var f=Qv;Vv+=1;Qv=0;try{Kv(null,null),$CLJS.Tv($CLJS.A(e)),
$CLJS.B(e)&&($CLJS.ac($CLJS.Hu," "),aw(Yv),$CLJS.Tv($CLJS.hd(e))),Ov()}finally{Qv=f,Vv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.Hu," ");aw($v);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.Hu,"...");break}}Ov()}finally{Qv=c,Vv=b}}return null},Vx,Ux,Ux,ry,ry,Vx,Vx,ry]))),uy,mda=$CLJS.Xe($CLJS.N),nda=$CLJS.Xe($CLJS.N),oda=$CLJS.Xe($CLJS.N),pda=$CLJS.Xe($CLJS.N),qda=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
uy=new $CLJS.ei($CLJS.Fh.g("cljs.pprint","code-dispatch"),Qx,qda,mda,nda,oda,pda);Bu(uy,$CLJS.Cj,function(a){if($CLJS.Va(Kx(a))){var b=lda.call(null,$CLJS.A(a));return $CLJS.n(b)?b.h?b.h(a):b.call(null,a):Tx(a)}return null});Bu(uy,$CLJS.Hi,function(a){var b=a.h?a.h(ty):a.call(null,ty);return $CLJS.n(b)?Fw.l($CLJS.H([b])):$CLJS.n(ly)?Fw.l($CLJS.H([$CLJS.gh(a)])):Gu.call(null,a)});Bu(uy,$CLJS.Jj,Lx);Bu(uy,$CLJS.hk,Mx);Bu(uy,$CLJS.$i,oy);Bu(uy,Ox,fda);
Bu(uy,Px,function(a){var b=$CLJS.p,c=b.h,d=$CLJS.ab(a).name;var e=$CLJS.oh(/^[^$]+\$[^$]+/,d);e=$CLJS.n(e)?py.h?py.h(e):py.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.n(e)?e:d),"@",$CLJS.p.h($CLJS.ya(a)),": "].join("");if($CLJS.n(Wv()))$CLJS.ac($CLJS.Hu,"#");else{c=Vv;d=Qv;Vv+=1;Qv=0;try{Kv(b,"\x3e");ew(bw,-(b.length-2));aw($v);var f=null!=a?a.I&1||$CLJS.Bc===a.jj?!0:a.I?!1:$CLJS.$a(xu,a):$CLJS.$a(xu,a);var k=f?!yu(a):f;$CLJS.Tv(k?Pca:$CLJS.q(a));Ov()}finally{Qv=d,Vv=c}}return null});Bu(uy,null,Gu);
Bu(uy,$CLJS.di,Nx);Sv=qy;