var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Qsa,Rsa,Tsa,Usa,Vsa,Wsa;Qsa=function(a,b){return $CLJS.PA(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};Rsa=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){return $CLJS.hg.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.ec($CLJS.N),b))};Tsa=function(a){return $CLJS.eg.j($CLJS.bh,$CLJS.Ok.g($CLJS.tE(function(b){return $CLJS.aX(b,a)}),$CLJS.hf.h($CLJS.hd)),Ssa)};
Usa=function(a){return new $CLJS.h(null,3,[$CLJS.mj,$CLJS.FB,$CLJS.T,a,$CLJS.Zi,$CLJS.p.h($CLJS.WE())],null)};
Vsa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.T);return $CLJS.fl.l($CLJS.H([a,function(){var c=$CLJS.nh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.BW(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.mj,$CLJS.DN,$CLJS.kR,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.mj,$CLJS.OM,$CLJS.AM,c],null):null}(),$CLJS.n($CLJS.zE.h(a))?null:new $CLJS.h(null,
1,[$CLJS.zE,$CLJS.K1.g($CLJS.J1,b)],null)]))};$CLJS.I8=function(a){a=$CLJS.C1($CLJS.b0(a));return $CLJS.n($CLJS.KA(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pA,Wsa],null)))?$CLJS.be.g($CLJS.bh,$CLJS.tO):$CLJS.bh};$CLJS.J8=function(a,b){var c=$CLJS.I8(a);return(0,$CLJS.YW)(a,0,function(d){var e=$CLJS.pu.g(Xsa,c);d=$CLJS.Se.j($CLJS.Gk,d,e);d=$CLJS.fl.l($CLJS.H([d,$CLJS.dm(b,c)]));$CLJS.pu.g(c,$CLJS.fh($CLJS.Xg(b)));$CLJS.NW(a,0);return d})};$CLJS.K8=function(a){return $CLJS.Fe($CLJS.dm($CLJS.NW(a,0),$CLJS.I8(a)))};
$CLJS.L8=function(a){return $CLJS.rQ.h($CLJS.NW(a,0))};Wsa=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.Ysa=new $CLJS.M(null,"write","write",-1857649168);$CLJS.Zsa=new $CLJS.M(null,"native-permissions","native-permissions",1277672164);var Ssa,Xsa;Ssa=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.M8=function(){function a(d,e){d=$CLJS.Fe(Tsa(d));var f=$CLJS.Fe($CLJS.fh($CLJS.Xg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.pu.g(d,f);d=$CLJS.pu.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.A(d);k=$CLJS.A(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.zE.h(f),$CLJS.K1.g($CLJS.J1,d))?$CLJS.K1.g($CLJS.J1,k):$CLJS.zE.h(f);f=$CLJS.R.l($CLJS.Gk.l(f,$CLJS.AM,$CLJS.H([$CLJS.kR,$CLJS.aQ])),$CLJS.zE,l,$CLJS.H([$CLJS.T,k]));e=$CLJS.R.j($CLJS.Gk.g(e,d),k,f)}else e=$CLJS.fl.l($CLJS.H([Qsa($CLJS.Te(d),
e),Rsa($CLJS.T,$CLJS.hf.g(Usa,k))]));e=$CLJS.Kt(e,Vsa)}else e=$CLJS.N;return e}function b(d){return $CLJS.M8.g?$CLJS.M8.g(d,null):$CLJS.M8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();Xsa=new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.tO,null],null),null);
$CLJS.X(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tO,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.EE],null)],null));
$CLJS.N8=function(){function a(d,e,f,k){var l=$CLJS.M8.h(e);return $CLJS.J8($CLJS.G1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.hu,$CLJS.jW,$CLJS.vV,f,$CLJS.OQ,l,$CLJS.rQ,e],null)],null)),k)}function b(d,e){return $CLJS.N8.v?$CLJS.N8.v(d,e,null,null):$CLJS.N8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.O8=function(){function a(d,e,f){$CLJS.NW(d,0);return $CLJS.J8($CLJS.G1.g(e,$CLJS.xL.h(d)),f)}function b(d,e){return $CLJS.O8.j?$CLJS.O8.j(d,e,null):$CLJS.O8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.F1.m(null,$CLJS.jW,function(a){return $CLJS.z1($CLJS.I8(a),$CLJS.fh($CLJS.Xg($CLJS.K8(a))))&&!$CLJS.OA($CLJS.L8(a))});