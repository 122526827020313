var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var HH,IH,Ffa,KH,Gfa,Hfa,Ifa,LH,JH;$CLJS.FH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.GH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
HH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.BC(b,$CLJS.bD)?$CLJS.qH:$CLJS.BC(b,$CLJS.aE)?$CLJS.pE:$CLJS.BC(b,$CLJS.BD)?$CLJS.qE:null;return $CLJS.n(b)?$CLJS.CF(b,a):!0};
IH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.FH(function(d){return $CLJS.BC($CLJS.$E(d),$CLJS.mD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Mk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.$E($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(HH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Ffa=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.At,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Dj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(IH(b))].join("")}],null),$CLJS.Te(IH)],null)],null)};
KH=function(a){var b=$CLJS.Q,c=Ffa(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.At,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.As,c,a],null)};Gfa=function(a){return $CLJS.Qd($CLJS.FF,$CLJS.hf.g(function(b){var c=$CLJS.$E(b),d=$CLJS.BC(c,$CLJS.lF);b=d?$CLJS.CF($CLJS.zG,b):d;return $CLJS.n(b)?$CLJS.Oj:c},a))};Hfa=function(a){a=$CLJS.GH(function(b){return!$CLJS.BC(b,$CLJS.mD)},$CLJS.hf.g($CLJS.$E,a));return $CLJS.BC(a,$CLJS.lF)?$CLJS.Vj:a};Ifa=function(a){return $CLJS.n($CLJS.Re(function(b){return $CLJS.BC($CLJS.$E(b),$CLJS.mD)},a))?Hfa(a):Gfa(a)};
LH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.MH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);JH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,JH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.At,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.$E(a);return $CLJS.Qe(function(d){return HH(d,c)},b)}],null)],null));
$CLJS.X(LH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)],null));$CLJS.WG.g($CLJS.es,KH($CLJS.es));$CLJS.WG.g($CLJS.tt,KH($CLJS.tt));$CLJS.MF($CLJS.fs,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ft,LH],null)]));$CLJS.MF($CLJS.RG,$CLJS.H([$CLJS.tt,$CLJS.YD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ft,LH],null)]));
for(var NH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.es,$CLJS.tt,$CLJS.fs],null)),OH=null,PH=0,QH=0;;)if(QH<PH){var Jfa=OH.X(null,QH);$CLJS.DF(Jfa,$CLJS.MH);QH+=1}else{var RH=$CLJS.y(NH);if(RH){var SH=RH;if($CLJS.Ad(SH)){var TH=$CLJS.lc(SH),Kfa=$CLJS.mc(SH),Lfa=TH,Mfa=$CLJS.D(TH);NH=Kfa;OH=Lfa;PH=Mfa}else{var Nfa=$CLJS.A(SH);$CLJS.DF(Nfa,$CLJS.MH);NH=$CLJS.B(SH);OH=null;PH=0}QH=0}else break}$CLJS.ZE.m(null,$CLJS.MH,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);return Ifa(a)});
$CLJS.KF($CLJS.WF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));$CLJS.DF($CLJS.WF,$CLJS.pF);
for(var UH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TG,$CLJS.NF,$CLJS.iG],null)),VH=null,WH=0,XH=0;;)if(XH<WH){var Ofa=VH.X(null,XH);$CLJS.KF(Ofa,$CLJS.H([$CLJS.tt,$CLJS.YD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));XH+=1}else{var YH=$CLJS.y(UH);if(YH){var ZH=YH;if($CLJS.Ad(ZH)){var $H=$CLJS.lc(ZH),Pfa=$CLJS.mc(ZH),Qfa=$H,Rfa=$CLJS.D($H);UH=Pfa;VH=Qfa;WH=Rfa}else{var Sfa=$CLJS.A(ZH);$CLJS.KF(Sfa,$CLJS.H([$CLJS.tt,$CLJS.YD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));UH=$CLJS.B(ZH);VH=null;WH=0}XH=0}else break}
for(var aI=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,$CLJS.tG,$CLJS.AG],null)),bI=null,cI=0,dI=0;;)if(dI<cI){var Tfa=bI.X(null,dI);$CLJS.KF(Tfa,$CLJS.H([$CLJS.tt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));dI+=1}else{var eI=$CLJS.y(aI);if(eI){var fI=eI;if($CLJS.Ad(fI)){var gI=$CLJS.lc(fI),Ufa=$CLJS.mc(fI),Vfa=gI,Wfa=$CLJS.D(gI);aI=Ufa;bI=Vfa;cI=Wfa}else{var Xfa=$CLJS.A(fI);$CLJS.KF(Xfa,$CLJS.H([$CLJS.tt,$CLJS.wj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));aI=$CLJS.B(fI);bI=null;cI=0}dI=0}else break}$CLJS.KF($CLJS.TF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));
$CLJS.ZE.m(null,$CLJS.TF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.BC($CLJS.$E(b),$CLJS.wj)&&$CLJS.BC($CLJS.$E(a),$CLJS.wj)?$CLJS.wj:$CLJS.YD});