var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var MY,Sla,OY,Tla,Ula,Vla,QY,Xla,Yla,Zla,$la,TY,UY,VY,WY,YY,bma,ZY,$Y,cma,dma,ema,SY,Wla,aZ,bZ,ama,dZ;MY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Sla=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.j?$CLJS.mj.j(k,l,m):$CLJS.mj.call(null,k,l,m),$CLJS.ll.j?$CLJS.ll.j(k,l,m):$CLJS.ll.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.g?$CLJS.mj.g(k,l):$CLJS.mj.call(null,k,l),$CLJS.ll.g?$CLJS.ll.g(k,l):$CLJS.ll.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.h?$CLJS.mj.h(k):$CLJS.mj.call(null,k),$CLJS.ll.h?$CLJS.ll.h(k):$CLJS.ll.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.o?$CLJS.mj.o():$CLJS.mj.call(null),$CLJS.ll.o?$CLJS.ll.o():$CLJS.ll.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.mj,m,t,u,v),$CLJS.Se.N($CLJS.ll,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.NY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
OY=function(a){return $CLJS.YL($CLJS.tW)(a,$CLJS.Cf,$CLJS.Cf)};Tla=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.kG,b),$CLJS.qt(2,2,c))};Ula=function(a,b){var c=$CLJS.em(a,$CLJS.OQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.Kt.g?$CLJS.Kt.g(c,b):$CLJS.Kt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.OQ,b)}return a};Vla=function(a){return $CLJS.gf.g($CLJS.UV(a),$CLJS.VV(a))};
$CLJS.PY=function(a,b){return $CLJS.FW(a)&&$CLJS.E.g($CLJS.A(a),b)};QY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ys,null,$CLJS.sl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Fu(2,c):$CLJS.Fu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?MY(2,c):MY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.EX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Pk.h($CLJS.zl),l):$CLJS.xd(k)?$CLJS.QA($CLJS.Wa,l):l}):$CLJS.Gk.g(a,f)};
Xla=function(a){return $CLJS.fb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return QY(b,Wla,d)},a,Vla(a))};
Yla=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.xL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(Sla(),$CLJS.lf($CLJS.Ok.j(RY,$CLJS.A,$CLJS.ll),$CLJS.bt.h(OY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=QY(d,f,k),m=$CLJS.TA($CLJS.Oy);if($CLJS.n($CLJS.SA("metabase.lib.convert",m))){var t=$CLJS.JB.l($CLJS.H([$CLJS.Jz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.JB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.cM(OY(d));$CLJS.RA("metabase.lib.convert",
m,$CLJS.CW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.Jz,z.call(x,$CLJS.H([C]))])),$CLJS.JB.l($CLJS.H([$CLJS.yz,$CLJS.DW($CLJS.A($CLJS.mu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=Xla(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.xL.h(a))-1))return a;b+=1}else a=$CLJS.Rk.N(a,$CLJS.xL,$CLJS.R,b,d)}};
Zla=function(a){var b=$CLJS.QW();return $CLJS.Qk.g(function(c){return $CLJS.E.g($CLJS.iF.h(c),"__join")?$CLJS.Rk.j(c,$CLJS.iF,b):c},a)};$la=function(a){return"string"===typeof $CLJS.mP.h(a)?$CLJS.Gk.g($CLJS.R.j(a,$CLJS.WV,$CLJS.OW($CLJS.mP.h(a))),$CLJS.mP):a};TY=function(a,b){var c=$CLJS.J.j(a,b,SY);if($CLJS.E.g(c,SY))throw $CLJS.ii(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[ama,a,$CLJS.ex,b],null));return c};
UY=function(){return $CLJS.Pk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};VY=function(a){return $CLJS.eg.j($CLJS.N,UY(),a)};WY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Ok.g(UY(),$CLJS.Pk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Fi)})),a))};
YY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.XY),c);a=$CLJS.E.g(a,$CLJS.eG)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.di,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(WY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,a,b],null):a};bma=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Ok.g($CLJS.hf.h(function(b){return $CLJS.LA(b,$CLJS.OB)}),$CLJS.hf.h($CLJS.XY)),$CLJS.KW.h(a))};
ZY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.xL);a=$CLJS.A($CLJS.fb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.XY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.QQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.ZO,bma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.vV.h(c)],null)},null,a));return $CLJS.n($CLJS.rQ.h(a))?$CLJS.HV(a,new $CLJS.h(null,1,[$CLJS.rQ,$CLJS.SF],null)):a};
$Y=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.bX(a,b,$CLJS.Ok.g($CLJS.XY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.bX(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Hs],null),$CLJS.hf.h($CLJS.XY),e)}):d;return $CLJS.HV(a,$CLJS.Ee([b,c]))};cma=function(a){var b=$CLJS.WV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Gk.g(a,$CLJS.WV),$CLJS.mP,["card__",$CLJS.p.h(b)].join("")):a};
dma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);ema=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);SY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Wla=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);aZ=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
bZ=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.cZ=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);ama=new $CLJS.M(null,"m","m",1632677161);dZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var RY,fma,gma,hma,ima,jma,kma,lma,mma,nma,oma;$CLJS.eZ=$CLJS.N;$CLJS.fZ=$CLJS.N;RY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.qW,null,$CLJS.eF,null,$CLJS.LN,null,$CLJS.gQ,null,$CLJS.jG,null,$CLJS.WR,null,$CLJS.kG,null],null),null);fma=$CLJS.Xe($CLJS.N);gma=$CLJS.Xe($CLJS.N);hma=$CLJS.Xe($CLJS.N);ima=$CLJS.Xe($CLJS.N);jma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.pC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.gZ=new $CLJS.ei($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.nC,jma,fma,gma,hma,ima);$CLJS.gZ.m(null,$CLJS.di,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.xW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.gZ),d))}else b=a;return b});$CLJS.gZ.m(null,$CLJS.pW,function(a){return a});
$CLJS.gZ.m(null,$CLJS.cW,function(a){var b=$CLJS.gZ.h($CLJS.kG.h(a)),c=$CLJS.Fe($CLJS.Qk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.HW($CLJS.gZ.h(t),u)},$CLJS.gQ.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.LE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.fZ,k=$CLJS.eZ;$CLJS.fZ=d;$CLJS.eZ=e;try{var l=Tla($la(a),b,$CLJS.H([$CLJS.gQ,c])),m=$CLJS.fb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Rk.j(t,u,$CLJS.gZ)},l,$CLJS.Hk.l(RY,$CLJS.kG,$CLJS.H([$CLJS.gQ])));return $CLJS.n($CLJS.LN.h(m))?$CLJS.Rk.j(m,$CLJS.LN,Zla):m}finally{$CLJS.eZ=k,$CLJS.fZ=f}});$CLJS.gZ.m(null,$CLJS.jW,function(a){return Ula(a,function(b){return $CLJS.bX(b,$CLJS.PF,$CLJS.gZ)})});
$CLJS.gZ.m(null,$CLJS.sL,function(a){a=$CLJS.Rk.j($CLJS.Rk.j(a,$CLJS.AL,$CLJS.gZ),$CLJS.xL,$CLJS.gZ);var b=$CLJS.n($CLJS.eF.h(a))?$CLJS.Rk.j(a,$CLJS.eF,function(c){return $CLJS.Ll(c)?$CLJS.Qk.g($CLJS.gZ,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.iF.h(a))?$CLJS.R.j(b,$CLJS.iF,"__join"):b});$CLJS.gZ.m(null,$CLJS.vB,function(a){return $CLJS.Qk.g($CLJS.gZ,a)});
$CLJS.gZ.m(null,$CLJS.xB,function(a){return $CLJS.n($CLJS.mj.h(a))?Yla($CLJS.R.j($CLJS.Rk.j($CLJS.LW(a),$CLJS.xL,function(b){return $CLJS.Qk.g($CLJS.gZ,b)}),$CLJS.cZ,!0)):$CLJS.Kt(a,$CLJS.gZ)});$CLJS.gZ.m(null,$CLJS.vG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.xW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,a,b],null))});
$CLJS.gZ.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.HV(a,new $CLJS.h(null,3,[$CLJS.oD,$CLJS.BB,$CLJS.LO,$CLJS.Uj,$CLJS.eS,$CLJS.GE],null));var c=$CLJS.R.j;var d=$CLJS.Fi.h(a);$CLJS.n(d)||(d=$CLJS.BB.h(a),d=$CLJS.n(d)?d:$CLJS.$E(b));a=c.call($CLJS.R,a,$CLJS.Fi,d);return $CLJS.xW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null))});
$CLJS.gZ.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.di.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,$CLJS.Gk.g(c,$CLJS.di),$CLJS.Qk.g($CLJS.gZ,b)],null);b=$CLJS.xW(b);return null!=a?$CLJS.be.g(b,$CLJS.gZ.h(a)):b});$CLJS.gZ.m(null,$CLJS.yB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.xW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.gZ.m(null,$CLJS.kG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.xW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,TY($CLJS.fZ,c)],null))});$CLJS.gZ.m(null,$CLJS.CM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.gZ.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.fl.l($CLJS.H([c,a]))],null),d)});kma=$CLJS.Xe($CLJS.N);lma=$CLJS.Xe($CLJS.N);
mma=$CLJS.Xe($CLJS.N);nma=$CLJS.Xe($CLJS.N);oma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.pC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.XY=new $CLJS.ei($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.nC,oma,kma,lma,mma,nma);
$CLJS.XY.m(null,$CLJS.di,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.XY,d);a=WY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.XY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.Kt(VY(a),$CLJS.XY):
a;return a});for(var hZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[dZ,bZ],null)),iZ=null,jZ=0,kZ=0;;)if(kZ<jZ){var pma=iZ.X(null,kZ);$CLJS.DF(pma,aZ);kZ+=1}else{var lZ=$CLJS.y(hZ);if(lZ){var mZ=lZ;if($CLJS.Ad(mZ)){var nZ=$CLJS.lc(mZ),qma=$CLJS.mc(mZ),rma=nZ,sma=$CLJS.D(nZ);hZ=qma;iZ=rma;jZ=sma}else{var tma=$CLJS.A(mZ);$CLJS.DF(tma,aZ);hZ=$CLJS.B(mZ);iZ=null;jZ=0}kZ=0}else break}
for(var oZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ay,$CLJS.pG,$CLJS.EG,$CLJS.aG,$CLJS.ik,$CLJS.JG,$CLJS.Yn,$CLJS.gG,$CLJS.CG,$CLJS.LG,$CLJS.IG,$CLJS.QG],null)),pZ=null,qZ=0,rZ=0;;)if(rZ<qZ){var uma=pZ.X(null,rZ);$CLJS.DF(uma,dZ);rZ+=1}else{var sZ=$CLJS.y(oZ);if(sZ){var tZ=sZ;if($CLJS.Ad(tZ)){var uZ=$CLJS.lc(tZ),vma=$CLJS.mc(tZ),wma=uZ,xma=$CLJS.D(uZ);oZ=vma;pZ=wma;qZ=xma}else{var yma=$CLJS.A(tZ);$CLJS.DF(yma,dZ);oZ=$CLJS.B(tZ);pZ=null;qZ=0}rZ=0}else break}
for(var vZ=$CLJS.y($CLJS.yf([$CLJS.es,$CLJS.tt,$CLJS.fs,$CLJS.RG,$CLJS.eG,$CLJS.yG,$CLJS.WF,$CLJS.TG,$CLJS.NF,$CLJS.iG,$CLJS.RF,$CLJS.tG,$CLJS.AG,$CLJS.TF,$CLJS.iJ,$CLJS.kJ,$CLJS.Py,$CLJS.eJ,$CLJS.mJ,$CLJS.ZI,$CLJS.qJ,$CLJS.dJ,$CLJS.XI,$CLJS.pJ,$CLJS.xJ,$CLJS.tJ,$CLJS.cJ,$CLJS.AJ,$CLJS.sJ,$CLJS.yJ,$CLJS.VF,$CLJS.OF,$CLJS.UG,$CLJS.jI,$CLJS.xG,$CLJS.Zx,$CLJS.MG,$CLJS.OG,$CLJS.VG,$CLJS.Nt,$CLJS.Tt],!0)),wZ=null,xZ=0,yZ=0;;)if(yZ<xZ){var zma=wZ.X(null,yZ);$CLJS.DF(zma,bZ);yZ+=1}else{var zZ=$CLJS.y(vZ);
if(zZ){var AZ=zZ;if($CLJS.Ad(AZ)){var BZ=$CLJS.lc(AZ),Ama=$CLJS.mc(AZ),Bma=BZ,Cma=$CLJS.D(BZ);vZ=Ama;wZ=Bma;xZ=Cma}else{var Dma=$CLJS.A(AZ);$CLJS.DF(Dma,bZ);vZ=$CLJS.B(AZ);wZ=null;xZ=0}yZ=0}else break}$CLJS.XY.m(null,aZ,function(a){return YY(a)});$CLJS.XY.m(null,$CLJS.xB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Ok.g(UY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.XY.h(b)],null)})),a)});
$CLJS.XY.m(null,$CLJS.kG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=WY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kG,TY($CLJS.eZ,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.AV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.hi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.XY.m(null,$CLJS.vB,function(a){return $CLJS.Qk.g($CLJS.XY,a)});$CLJS.XY.m(null,$CLJS.vG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,$CLJS.XY.h(a),WY(b)],null)});
$CLJS.XY.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=WY($CLJS.HV(b,new $CLJS.h(null,3,[$CLJS.BB,$CLJS.oD,$CLJS.Uj,$CLJS.LO,$CLJS.GE,$CLJS.eS],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null)});
$CLJS.XY.m(null,$CLJS.sL,function(a){var b=VY(a);a=0==$CLJS.iF.h(a).lastIndexOf("__join",0)?$CLJS.Gk.g(b,$CLJS.iF):b;return $CLJS.fl.l($CLJS.H([$CLJS.Kt($CLJS.Gk.l(a,$CLJS.xL,$CLJS.H([$CLJS.AL])),$CLJS.XY),$Y($CLJS.dm(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.AL],null)),$CLJS.AL,$CLJS.ZR),ZY(a)]))});
$CLJS.XY.m(null,$CLJS.cW,function(a){var b=$CLJS.kG.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.fZ,e=$CLJS.eZ;$CLJS.fZ=c;$CLJS.eZ=b;try{return $CLJS.fb(function(f,k){return $CLJS.bX(f,
k,$CLJS.XY)},$Y($CLJS.bX($CLJS.bX(cma(VY(a)),$CLJS.kG,function(f){return $CLJS.Qk.g(YY,f)}),$CLJS.gQ,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.XY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GW(C),$CLJS.E.g($CLJS.Dj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.XY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GW(x),$CLJS.E.g($CLJS.Dj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.qW,$CLJS.$F),$CLJS.Hk.l(RY,$CLJS.kG,$CLJS.H([$CLJS.qW,$CLJS.gQ])))}finally{$CLJS.eZ=e,$CLJS.fZ=d}});$CLJS.XY.m(null,$CLJS.jW,function(a){return $CLJS.Kt(VY(a),$CLJS.XY)});
$CLJS.XY.m(null,$CLJS.pW,function(a){try{var b=VY(a),c=$CLJS.CN.h(b),d=ZY(b),e=$CLJS.E.g($CLJS.hu.h($CLJS.id($CLJS.xL.h(a))),$CLJS.jW)?$CLJS.rQ:$CLJS.SF;return $CLJS.fl.l($CLJS.H([$CLJS.Kt($CLJS.Gk.l(b,$CLJS.xL,$CLJS.H([$CLJS.CN,$CLJS.cZ])),$CLJS.XY),function(){var k=$CLJS.Ee([$CLJS.mj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.CN,c):k}()]))}catch(k){var f=k;throw $CLJS.hi(function(){var l=$CLJS.AV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.SF,a],null),f);}});
$CLJS.CZ=function(){function a(d,e,f){f=$CLJS.LY(null,$CLJS.Wz(f,$CLJS.H([$CLJS.zi,!0])));var k=$CLJS.NW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.kG);k=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.fm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.LE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.fZ,t=$CLJS.eZ;$CLJS.fZ=k;$CLJS.eZ=l;try{try{return $CLJS.gZ.h(f)}catch(v){var u=v;throw $CLJS.hi(function(){var x=$CLJS.AV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.SF,d,$CLJS.LL,e,ema,f,dma,$CLJS.fZ],null),u);}}finally{$CLJS.eZ=t,$CLJS.fZ=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();