var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var rfa,sfa,tfa,ufa,vfa,wfa,xfa,yfa,zfa;rfa=function(a,b){a.sort(b||$CLJS.Da)};sfa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;rfa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.gH=function(a){return $CLJS.E.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.hH=function(a,b){if($CLJS.y(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);sfa(c,$CLJS.gH(a));return $CLJS.od($CLJS.y(c),$CLJS.pd(b))}return $CLJS.Kc};$CLJS.iH=new $CLJS.M(null,"second","second",-444702010);$CLJS.jH=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.kH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.lH=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.mH=new $CLJS.M(null,"year-of-era","year-of-era",682445876);tfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
ufa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.nH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.oH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);
vfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);wfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);xfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);
yfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.pH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.qH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
zfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var rH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Gi,$CLJS.Fj,$CLJS.Ej,$CLJS.qi,$CLJS.lk,$CLJS.Ai,$CLJS.ki,$CLJS.mH],null),sH=$CLJS.fh(rH),Afa,Bfa,Cfa;$CLJS.X(xfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid date extraction unit"],null)],null),sH));var tH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.iu,$CLJS.oj,$CLJS.xj,$CLJS.pi,$CLJS.ki],null),uH=$CLJS.fh(tH);
$CLJS.X(wfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid date truncation unit"],null)],null),uH));$CLJS.vH=$CLJS.eg.j($CLJS.Cf,$CLJS.hl.o(),$CLJS.gf.g(tH,rH));Afa=$CLJS.fh($CLJS.vH);$CLJS.X($CLJS.oH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid date bucketing unit"],null)],null),Afa));var wH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jH,$CLJS.ij,$CLJS.wi],null),xH=$CLJS.fh(wH);
$CLJS.X(vfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid time extraction unit"],null)],null),xH));var yH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lH,$CLJS.iH,$CLJS.Ui,$CLJS.pk],null),zH=$CLJS.fh(yH);$CLJS.X(zfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid time truncation unit"],null)],null),zH));$CLJS.AH=$CLJS.eg.j($CLJS.Cf,$CLJS.hl.o(),$CLJS.gf.g(yH,wH));Bfa=$CLJS.fh($CLJS.AH);
$CLJS.X(ufa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid time bucketing unit"],null)],null),Bfa));$CLJS.BH=$CLJS.eg.j($CLJS.Cf,$CLJS.hl.o(),$CLJS.gf.l(yH,tH,$CLJS.H([wH,rH])));Cfa=$CLJS.fh($CLJS.BH);$CLJS.X($CLJS.kH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid datetime bucketing unit"],null)],null),$CLJS.BH));var Dfa=$CLJS.be.g(Cfa,$CLJS.di);
$CLJS.X($CLJS.rE,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid temporal bucketing unit"],null)],null),Dfa));$CLJS.CH=$CLJS.ju.g(uH,zH);$CLJS.X($CLJS.nH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid datetime truncation unit"],null)],null),$CLJS.CH));$CLJS.DH=$CLJS.ju.g(sH,xH);$CLJS.X(tfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid datetime extraction unit"],null)],null),$CLJS.DH));
var EH=$CLJS.be.g(uH,$CLJS.ki);$CLJS.X($CLJS.qH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid date interval unit"],null)],null),EH));$CLJS.X($CLJS.pE,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid time interval unit"],null)],null),zH));var Efa=$CLJS.ju.g(EH,zH);$CLJS.X($CLJS.qE,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid datetime interval unit"],null)],null),Efa));
$CLJS.X(yfa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.pH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.Qs],null)],null));