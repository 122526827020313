var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var YE,cF,Uea,Vea,Wea,Xea,Yea,Zea;
$CLJS.WE=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.qA([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.XE=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.uE(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};YE=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M};
$CLJS.$E=function(a){var b=function(){var c=YE(a);return c?(c=$CLJS.xd($CLJS.hd(a)))?(c=$CLJS.Fi.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.BB.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.ZE.h(a)};
cF=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,2,[$CLJS.At,"valid MBQL clause",$CLJS.Ct,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.Dj);return["invalid MBQL clause: ",$CLJS.Mh.l($CLJS.H([c]))].join("")}],null),$CLJS.Te(YE)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.At,b],null),function(c){c=$CLJS.$E(c);
return bF(c,a)}],null)],null)};$CLJS.dF=new $CLJS.M(null,"right-join","right-join",-56349359);Uea=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Vea=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Wea=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.eF=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.fF=new $CLJS.M(null,"expr","expr",745722291);$CLJS.gF=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.hF=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.iF=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.jF=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.kF=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.lF=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.mF=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);Xea=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.nF=new $CLJS.M(null,"left-join","left-join",-672831855);
$CLJS.oF=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.pF=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.qF=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Yea=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);
Zea=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.rF=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.sF=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.aF=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.tF=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.uF=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.vF=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.wF=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.ZE=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.pC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.nC(f);return $CLJS.E.g(k,$CLJS.AB)?$CLJS.ab(f):k},e,a,b,c,d)}();
$CLJS.X(Vea,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.lF],null),$CLJS.OE],null));$CLJS.ZE.m(null,$CLJS.di,function(a){throw $CLJS.ii($CLJS.uE("{0}: Don''t know how to determine the type of {1}",$CLJS.H([Yea,$CLJS.Mh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.fF,a],null));});$CLJS.ZE.m(null,$CLJS.pF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.$E(a)});
var bF=function bF(a,b){return $CLJS.vd(a)?$CLJS.Re(function(d){return bF.g?bF.g(d,b):bF.call(null,d,b)},a):$CLJS.vd(b)?$CLJS.Re(function(d){return bF.g?bF.g(a,d):bF.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.lF)?!0:$CLJS.BC(a,b)};$CLJS.X($CLJS.jF,cF($CLJS.Kj,"expression returning a boolean"));$CLJS.X($CLJS.tF,cF($CLJS.ck,"expression returning a string"));$CLJS.X($CLJS.wF,cF($CLJS.wj,"expression returning an integer"));$CLJS.X(Wea,cF($CLJS.YD,"expression returning a non-integer real number"));
$CLJS.X($CLJS.vF,cF($CLJS.Oj,"expression returning a number"));$CLJS.X(Xea,cF($CLJS.bD,"expression returning a date"));$CLJS.X(Zea,cF($CLJS.aE,"expression returning a time"));$CLJS.X(Uea,cF($CLJS.BD,"expression returning a date time"));$CLJS.X($CLJS.hF,cF($CLJS.Vj,"expression returning a date, time, or date time"));$CLJS.xF=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.Oj,null,$CLJS.ck,null,$CLJS.Vj,null,$CLJS.Kj,null],null),null);$CLJS.X($CLJS.qF,cF($CLJS.xF,"an expression that can be compared with :\x3e or :\x3c"));
var $ea=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.LC,null,$CLJS.Oj,null,$CLJS.ck,null,$CLJS.rD,null,$CLJS.Vj,null,$CLJS.Kj,null,$CLJS.WD,null],null),null);$CLJS.X($CLJS.sF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),cF($CLJS.rD,"expression returning a BSONID")],null));$CLJS.X($CLJS.oF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,cF($ea,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.X($CLJS.kF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,cF($CLJS.Aj,"any type of expression")],null));
$CLJS.X($CLJS.gF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Yn,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.os,$CLJS.Rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DE,$CLJS.Yj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$CLJS.Rs],null)],null)],null)],null));