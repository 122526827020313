var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var Fra,Gra,T7,U7,Hra;Fra=function(a){if("string"===typeof a)return $CLJS.nh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.nh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.im(a));};$CLJS.R7=function(a){return $CLJS.Xa(a)?$CLJS.Wz(a,$CLJS.H([$CLJS.zi,!0])):a};
Gra=function(a,b,c){var d=Fra(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.IA(d))/Math.log(10)));return $CLJS.Re(function(f){f*=e;return f>=d?f:null},$CLJS.aqa)};
$CLJS.S7=function(a,b){var c=$CLJS.n7(a);if($CLJS.n(c)){var d=$CLJS.QE.h(c);d=d instanceof $CLJS.M?d.T:null;switch(d){case "num-bins":return a=$CLJS.KA(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$N,$CLJS.mj,$CLJS.Oj],null)),$CLJS.n(a)?(d=$CLJS.O(a),a=$CLJS.J.g(d,$CLJS.Yn),d=$CLJS.J.g(d,$CLJS.ik),c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.xE),c=Gra(a,d,c),new $CLJS.h(null,3,[$CLJS.PE,c,$CLJS.NL,b,$CLJS.SL,b+c],null)):null;case "bin-width":return c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.PE),new $CLJS.h(null,3,[$CLJS.PE,c,
$CLJS.NL,b,$CLJS.SL,b+c],null);case "default":return null;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}else return null};T7=function(a){var b=$CLJS.pA.h($CLJS.C1($CLJS.b0(a)));b=b.h?b.h($CLJS.Q7):b.call(null,$CLJS.Q7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.td($CLJS.I4.g(b,-1))&&$CLJS.td($CLJS.W5.g(b,-1))){if(b=$CLJS.Rk.j(b,$CLJS.xL,$CLJS.sd),!$CLJS.y($CLJS.xL.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
U7=function(a,b){var c=T7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.Gk.l(b,$CLJS.RW,$CLJS.H([$CLJS.XW]));var d=$CLJS.R0,e=d.j,f=a;var k=a;var l=$CLJS.j2(k,-1);k=$CLJS.n(l)?$CLJS.NW(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.u2.v(a,-2,$CLJS.yW(b),d);if($CLJS.n(b))a=$CLJS.Rk.j(a,$CLJS.xL,$CLJS.sd);else return null}};
Hra=function(a,b,c){var d=function(){if($CLJS.n($CLJS.n7(b))){var e=$CLJS.S7(b,c);if($CLJS.n(e)){var f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.NL);f=$CLJS.J.g(f,$CLJS.SL);var k=$CLJS.w5(b,null);e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.i2($CLJS.Yr,new $CLJS.P(null,2,5,$CLJS.Q,[k,e],null)),$CLJS.i2($CLJS.$r,new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null))],null)}else e=null}else e=null;return $CLJS.n(e)?e:new $CLJS.P(null,1,5,$CLJS.Q,[(0,$CLJS.B3)(b,c)],null)}();return $CLJS.fb(function(e,f){return $CLJS.C3.j(e,-1,f)},
a,d)};$CLJS.c6.m(null,$CLJS.W6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.R6);b=$CLJS.J.g(b,$CLJS.VZ);return new $CLJS.h(null,3,[$CLJS.mj,$CLJS.W6,$CLJS.R6,a,$CLJS.VZ,b],null)});
$CLJS.b6.m(null,$CLJS.W6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){var f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.i6),l=$CLJS.J.g(f,$CLJS.OL),m=T7(c),t=$CLJS.YW.l(m,-1,$CLJS.Gk,$CLJS.H([$CLJS.kG,$CLJS.jG,$CLJS.WR,$CLJS.tS,$CLJS.eF]));d=$CLJS.fb(function(v,x){var z=$CLJS.O(x);x=$CLJS.J.g(z,$CLJS.Ti);z=$CLJS.J.g(z,$CLJS.Dj);return Hra(v,x,z)},
t,function(){return function z(x){return new $CLJS.ne(null,function(){for(var C=x;;){var G=$CLJS.y(C);if(G){var K=G;if($CLJS.Ad(K)){var S=$CLJS.lc(K),V=$CLJS.D(S),Z=$CLJS.qe(V);return function(){for(var ra=0;;)if(ra<V){var Ma=$CLJS.kd(S,ra);Ma=$CLJS.Rk.j(Ma,$CLJS.Ti,function(){return function(zb){return U7(c,zb)}}(ra,C,Ma,S,V,Z,K,G,m,t,e,f,k,l));$CLJS.Mk.g($CLJS.YK.h($CLJS.Ti.h(Ma)),$CLJS.qL)&&Z.add(Ma);ra+=1}else return!0}()?$CLJS.te($CLJS.ve(Z),z($CLJS.mc(K))):$CLJS.te($CLJS.ve(Z),null)}var ha=
$CLJS.A(K);ha=$CLJS.Rk.j(ha,$CLJS.Ti,function(){return function(ra){return U7(c,ra)}}(C,ha,K,G,m,t,e,f,k,l));if($CLJS.Mk.g($CLJS.YK.h($CLJS.Ti.h(ha)),$CLJS.qL))return $CLJS.ee(ha,z($CLJS.Lc(K)));C=$CLJS.Lc(K)}else return null}},null,null)}(l)}());var u=function(){var v=$CLJS.id(k);return $CLJS.n(v)?$CLJS.GH(function(x){return $CLJS.E.g($CLJS.F5(x),v)},$CLJS.I4.g(m,-1)):null}();return $CLJS.fb(function(v,x){return $CLJS.C3.j(v,-1,x)},d,$CLJS.n(u)?function(){var v=$CLJS.A(u);switch(v instanceof $CLJS.M?
v.T:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.id(u)],null);case "metric":return $CLJS.D3.g($CLJS.R.j($CLJS.gZ.h((0,$CLJS.KY)($CLJS.h2($CLJS.R7(new $CLJS.h(null,2,[$CLJS.mj,$CLJS.SF,$CLJS.SF,$CLJS.R7($CLJS.OZ.h($CLJS.f0(c,$CLJS.id(u))))],null)),$CLJS.mj,$CLJS.SF))),$CLJS.bQ,$CLJS.bQ.h(c)),-1);default:return null}}():null)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,
e,f,c)};a.l=b;return a}());