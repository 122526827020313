var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var kV,lV,mV,oV,pV,qV,rV,sV,tV,uV;kV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);lV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);mV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.nV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);oV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);pV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);qV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
rV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);sV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);tV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);uV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(kV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.At,"Valid template tag :widget-type"],null),$CLJS.oA],null),$CLJS.Xg($CLJS.fV)));$CLJS.X(oV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,$CLJS.OM,$CLJS.DN,$CLJS.PF,$CLJS.bu,$CLJS.FB,$CLJS.JP],null));
$CLJS.X(rV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.EE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.EE,$CLJS.Ps],null)],null)],null));
$CLJS.X(sV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.Cl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eO,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.Qs],null)],null)],null));
$CLJS.X(pV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.PF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,new $CLJS.h(null,1,[$CLJS.Vs,!0],
null),$CLJS.hk],null)],null)],null));
$CLJS.X(qV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.OM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AM,$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aQ,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.oL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bQ,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.gL],null)],null)],null));
$CLJS.X(uV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.DN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kR,$CLJS.fL],null)],null)],null));$CLJS.X(tV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xs],null),$CLJS.UU));
$CLJS.X(lV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null)],null)],null)],null));
$CLJS.X(mV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.mj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null)],null)],null)],null));
$CLJS.X($CLJS.nV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.EE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.At,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));