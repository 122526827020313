var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var IL,KL,ML,PL,UL;$CLJS.FL=function(a){return $CLJS.Wh($CLJS.q($CLJS.pC),a,$CLJS.zG)};$CLJS.GL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.HL=new $CLJS.M(null,"object-id","object-id",-754527291);IL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.JL=new $CLJS.M(null,"operators","operators",-2064102509);
KL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.LL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);ML=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.NL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.OL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
PL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.QL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.RL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.SL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.TL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
UL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.VL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(KL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zt,$CLJS.JE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.rE],null)],null)],null));$CLJS.X(UL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zt,KL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BB,$CLJS.OE],null)],null)],null));$CLJS.X(ML,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.vG],null),UL,$CLJS.EE],null));
$CLJS.X(PL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.vG],null),KL,$CLJS.NE],null));
$CLJS.WG.g($CLJS.vG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.vG],null),KL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.NE,$CLJS.EE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.h(null,2,[$CLJS.Oi,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.nC(a)):null},$CLJS.At,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.tB,PL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sB,ML],null)],null)],null));$CLJS.DF($CLJS.vG,$CLJS.zG);$CLJS.ZE.m(null,$CLJS.vG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.JA($CLJS.Fi,$CLJS.BB)(b);return $CLJS.n(a)?a:$CLJS.lF});$CLJS.KF($CLJS.yB,$CLJS.H([$CLJS.EE]));$CLJS.ZE.m(null,$CLJS.yB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.JA($CLJS.Fi,$CLJS.BB)(b);return $CLJS.n(a)?a:$CLJS.lF});
$CLJS.DF($CLJS.yB,$CLJS.zG);$CLJS.X(IL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zt,$CLJS.JE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.EE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),$CLJS.EE],null)],null)],null));$CLJS.WG.g($CLJS.kG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.kG],null),IL,$CLJS.Yj],null));
$CLJS.ZE.m(null,$CLJS.kG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.JA($CLJS.Fi,$CLJS.BB)(b);return $CLJS.n(a)?a:$CLJS.lF});$CLJS.DF($CLJS.kG,$CLJS.zG);$CLJS.KF($CLJS.UF,$CLJS.H([$CLJS.tt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZK],null)],null)]));$CLJS.DF($CLJS.UF,$CLJS.zG);
$CLJS.KF($CLJS.qG,$CLJS.H([$CLJS.tt,$CLJS.lF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null)],null)]));$CLJS.DF($CLJS.qG,$CLJS.zG);
$CLJS.X($CLJS.zG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.aF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Ct,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Lt(", ",$CLJS.hH($CLJS.Jd,$CLJS.jl.g($CLJS.q($CLJS.pC),$CLJS.zG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.FL(a)}],null)],null));