var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var bfa,cfa,dfa,efa,ffa,gfa,hfa,bH,ifa,jfa,kfa,lfa,mfa,nfa,ofa,pfa,dH,qfa;$CLJS.XG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);bfa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.YG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);cfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
dfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.ZG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.$G=new $CLJS.M(null,"column-name","column-name",551523580);efa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.aH=new $CLJS.M(null,"display-info","display-info",-816930907);ffa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
gfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);hfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);bH=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);ifa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);jfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);kfa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
lfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);mfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);nfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);ofa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.cH=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);pfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
dH=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.eH=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);qfa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.MF($CLJS.ay,$CLJS.H([$CLJS.tt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)],null)],null)]));$CLJS.MF($CLJS.wG,$CLJS.H([$CLJS.tt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)],null)],null)]));
$CLJS.KF($CLJS.pG,$CLJS.H([$CLJS.tt,$CLJS.YD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));$CLJS.KF($CLJS.aG,$CLJS.H([$CLJS.tt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)]));$CLJS.KF($CLJS.EG,$CLJS.H([$CLJS.tt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));
$CLJS.KF($CLJS.ik,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.DF($CLJS.ik,$CLJS.pF);$CLJS.KF($CLJS.JG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));$CLJS.DF($CLJS.JG,$CLJS.pF);$CLJS.KF($CLJS.Yn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.DF($CLJS.Yn,$CLJS.pF);
$CLJS.X(bH,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.At,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.At,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.KF($CLJS.gG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bH],null)]));$CLJS.DF($CLJS.gG,$CLJS.pF);
$CLJS.KF($CLJS.CG,$CLJS.H([$CLJS.tt,$CLJS.YD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));$CLJS.KF($CLJS.LG,$CLJS.H([$CLJS.tt,$CLJS.YD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));$CLJS.KF($CLJS.IG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));
$CLJS.KF($CLJS.SG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));$CLJS.DF($CLJS.IG,$CLJS.pF);$CLJS.DF($CLJS.SG,$CLJS.pF);$CLJS.KF($CLJS.QG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));$CLJS.DF($CLJS.QG,$CLJS.pF);
$CLJS.KF($CLJS.bG,$CLJS.H([$CLJS.tt,$CLJS.YD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)],null)]));$CLJS.X(dH,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.As,pfa,nfa,gfa,cfa,bfa,ffa,hfa,ifa,jfa,mfa,kfa,qfa,ofa,dfa,lfa,$CLJS.Cl],null));$CLJS.X($CLJS.YG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Yn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dH],null)],null));
$CLJS.fH=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.oG,$CLJS.ay,$CLJS.ZG,!1,$CLJS.cH,$CLJS.QF,$CLJS.aH,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.XE("Count of rows"),$CLJS.$G,$CLJS.XE("Count"),$CLJS.Ut,$CLJS.XE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.oG,$CLJS.IG,$CLJS.XG,$CLJS.ek,$CLJS.ZG,!0,$CLJS.cH,$CLJS.QF,$CLJS.aH,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.XE("Sum of ..."),$CLJS.$G,$CLJS.XE("Sum"),$CLJS.Ut,$CLJS.XE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.oG,$CLJS.pG,$CLJS.XG,$CLJS.ek,$CLJS.ZG,!0,$CLJS.cH,$CLJS.QF,$CLJS.aH,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.XE("Average of ..."),$CLJS.$G,$CLJS.XE("Average"),$CLJS.Ut,$CLJS.XE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.oG,$CLJS.JG,$CLJS.XG,$CLJS.ek,$CLJS.ZG,!0,$CLJS.cH,$CLJS.rG,$CLJS.aH,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.XE("Median of ..."),$CLJS.$G,$CLJS.XE("Median"),$CLJS.Ut,$CLJS.XE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.oG,$CLJS.aG,$CLJS.XG,$CLJS.Rs,$CLJS.ZG,!0,$CLJS.cH,$CLJS.QF,$CLJS.aH,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.XE("Number of distinct values of ..."),$CLJS.$G,$CLJS.XE("Distinct values"),$CLJS.Ut,$CLJS.XE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.oG,$CLJS.SG,$CLJS.XG,$CLJS.ek,$CLJS.ZG,!0,$CLJS.cH,$CLJS.QF,$CLJS.aH,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.XE("Cumulative sum of ..."),
$CLJS.$G,$CLJS.XE("Sum"),$CLJS.Ut,$CLJS.XE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.oG,$CLJS.wG,$CLJS.ZG,!1,$CLJS.cH,$CLJS.QF,$CLJS.aH,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.XE("Cumulative count of rows"),$CLJS.$G,$CLJS.XE("Count"),$CLJS.Ut,$CLJS.XE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.oG,$CLJS.LG,$CLJS.XG,$CLJS.ek,$CLJS.ZG,
!0,$CLJS.cH,$CLJS.FG,$CLJS.aH,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.XE("Standard deviation of ..."),$CLJS.$G,$CLJS.XE("SD"),$CLJS.Ut,$CLJS.XE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.oG,$CLJS.Yn,$CLJS.XG,$CLJS.Yi,$CLJS.ZG,!0,$CLJS.cH,$CLJS.QF,$CLJS.aH,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.XE("Minimum of ..."),$CLJS.$G,$CLJS.XE("Min"),$CLJS.Ut,$CLJS.XE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.oG,$CLJS.ik,$CLJS.XG,$CLJS.Yi,$CLJS.ZG,!0,$CLJS.cH,$CLJS.QF,$CLJS.aH,function(){return new $CLJS.h(null,3,[$CLJS.zE,$CLJS.XE("Maximum of ..."),$CLJS.$G,$CLJS.XE("Max"),$CLJS.Ut,$CLJS.XE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(efa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,$CLJS.eH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xs],null),$CLJS.hf.h($CLJS.oG),$CLJS.fH)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XG,new $CLJS.h(null,1,[$CLJS.Vs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zs,$CLJS.Xi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZG,$CLJS.Qs],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.cH,$CLJS.Xi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aH,$CLJS.md],null)],null));