var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var Nz=function(){},Oz=function(a){return $CLJS.J.g($CLJS.Cy,a).value},wda=function(a){var b=Oz(a);return $CLJS.n(function(){var c=Oz($CLJS.Ty);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.yz:$CLJS.n(function(){var c=Oz($CLJS.Sy);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.Iz:$CLJS.n(function(){var c=Oz($CLJS.Qy);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.Bz:$CLJS.n(function(){var c=Oz($CLJS.Ky);return $CLJS.Jk.g?
$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.Hz:$CLJS.n(function(){var c=Oz($CLJS.Ry);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.Jz:$CLJS.n(function(){var c=Oz($CLJS.Ny);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.Ez:$CLJS.n(function(){var c=Oz($CLJS.Hy);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.Fz:$CLJS.Gz},Pz=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},Qz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Mz,c))].join(""),$CLJS.H(["color:black"]))],null)},Rz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Mz,$CLJS.Kz)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.Mz,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},xda=function(a){function b(c,d){return d>=c}a=Oz(a);if(b(Oz($CLJS.Ty),a))return"error";if(b(Oz($CLJS.Sy),a))return"warn";if(b(Oz($CLJS.Qy),a))return"info";b(Oz($CLJS.Ky),a);return"log"},Sz=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.Iy),e=$CLJS.J.g(c,$CLJS.St);b=$CLJS.J.g(c,$CLJS.Uy);e=xda(e);e=$CLJS.Ca(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Se.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Tz=function Tz(a,b){for(;;){if($CLJS.E.g($CLJS.xz,b))return Qz(a,", ",$CLJS.Gz);if($CLJS.E.g($CLJS.zz,b))return Pz(a);if(b instanceof $CLJS.M)return Qz(a,b,$CLJS.Bz);if(b instanceof $CLJS.r)return Qz(a,b,$CLJS.Hz);if("string"===typeof b)return Qz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.Az);if($CLJS.zf(b)){var d=Pz(function(){var f=a,k=$CLJS.Eb(b);return Tz.g?Tz.g(f,k):Tz.call(null,f,k)}()),e=$CLJS.Fb(b);
return Tz.g?Tz.g(d,e):Tz.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Hg)return d=a,d=Qz(d,"{",$CLJS.Lz),d=$CLJS.fb(Tz,d,$CLJS.ff($CLJS.xz,b)),Qz(d,"}",$CLJS.Lz);if($CLJS.xd(b))return d=a,d=Qz(d,["#",$CLJS.p.h(function(){var f=$CLJS.ab(b),k=f.name;return $CLJS.td(k)?$CLJS.Mh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.Cz),d=Qz(d,"{",$CLJS.Lz),d=$CLJS.fb(Tz,d,$CLJS.ff($CLJS.xz,b)),Qz(d,"}",$CLJS.Lz);if($CLJS.vd(b))return d=a,d=Qz(d,"#{",$CLJS.Lz),d=$CLJS.fb(Tz,d,$CLJS.ff($CLJS.zz,b)),Qz(d,
"}",$CLJS.Lz);if($CLJS.zd(b))return d=a,d=Qz(d,"[",$CLJS.Lz),d=$CLJS.fb(Tz,d,$CLJS.ff($CLJS.zz,b)),Qz(d,"]",$CLJS.Lz);if(b instanceof $CLJS.Rf)d=Qz(a,"#queue ",$CLJS.Cz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else{if($CLJS.Dd(b))return d=a,d=Qz(d,"(",$CLJS.Cz),d=$CLJS.fb(Tz,d,$CLJS.ff($CLJS.zz,b)),Qz(d,")",$CLJS.Cz);if(null!=b?b.I&16384||$CLJS.Bc===b.$i||(b.I?0:$CLJS.$a(Nz,b)):$CLJS.$a(Nz,b))d=Qz(a,"#atom ",$CLJS.Cz),e=$CLJS.q(b),a=d,b=e;else if($CLJS.fi(b))d=Qz(a,"#uuid ",$CLJS.Cz),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Xa(b))d=Qz(a,"#js ",$CLJS.Cz),e=$CLJS.fb(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.zh.h(m),$CLJS.Ca(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ua(b))d=Qz(a,"#js ",$CLJS.Cz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else return Qz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.Dz)}}};$CLJS.yda=Sz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.St);var b=$CLJS.J.g(a,$CLJS.Iy),c=$CLJS.J.g(a,$CLJS.Qi);$CLJS.J.g(a,$CLJS.Uy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.zda=Sz(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.St);a=$CLJS.J.g(b,$CLJS.Iy);var d=$CLJS.J.g(b,$CLJS.Qi);$CLJS.J.g(b,$CLJS.Uy);b=wda(c);d=Tz(Pz(Rz(Rz(Rz(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Cf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.ee(a,d)});
$CLJS.Ada=Sz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.St);var b=$CLJS.J.g(a,$CLJS.Iy),c=$CLJS.J.g(a,$CLJS.Qi);$CLJS.J.g(a,$CLJS.Uy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Mh.l($CLJS.H([c]))],null)});